import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { apiGeolocationRegions } from "controllers/geolocation-route/api/regions/regions";

export const slicerGeonamesRegions = createSlice({
    name: "geonames-regions",
    initialState: {
        list: [] as Array<any>,
        condition: {
            inProgress: false,
            index: 0,
            response: {
                result: false,
                data: null,
                errors: null,
            },
        },
    },
    reducers: {
        onRequest: (state, action) => {
            state.list = [];
            state.condition.inProgress = true;
            state.condition.index = action.payload;
        },
        onResponseFail: state => {
            state.condition.inProgress = false;
            state.list = [];
        },
        onResponseSuccess: (state, action: PayloadAction<any>) => {
            state.condition.inProgress = false;
            state.list = Array.isArray(action.payload)
                ? action.payload.map((item: any) => {
                      return {
                          name: item.title,
                          value: item.id,
                      };
                  })
                : [];
        },
    },
});

export const { onRequest, onResponseSuccess, onResponseFail } = slicerGeonamesRegions.actions;

export const asyncGeolocationRegions = (callback?: (result: any) => void) => async (
    dispatch: any,
    getState: () => RootState,
) => {
    let index = Date.now();
    dispatch(onRequest(index));
    const state = getState();
    const result = await apiGeolocationRegions(
        state.registration.view.region,
        state.registration.middleware.country?.value || "0",
    ).catch(e => console.error(e));
    if (result) {
        if (result && result.result && result.message === "responses.controllers.geo.regions") {
            dispatch(onResponseSuccess(result.data));
        } else {
            dispatch(onResponseFail());
        }
    } else {
        dispatch(onResponseFail());
    }
    if (callback) {
        callback(result);
    }
};

export const reselectGeonamesRegionsList = (state: RootState) => {
    return state.geonames.regions.list;
};

export const reselectGeonamesRegionsCondition = (state: RootState) => {
    return state.geonames.regions.condition;
};

export default slicerGeonamesRegions.reducer;
