import { kyRequest, BACKEND_HOST, responseCallback } from "api/api";

export type TypeRequestResetPassword = {
    email: string;
};

/**
 * @route api/token-revoke
 * @post
 */
export const apiTokenRevoke = async () => {
    const response = await kyRequest.get(`${BACKEND_HOST}/token/revoke`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return responseCallback(response);
};
