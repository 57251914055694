import { kyRequest, BACKEND_HOST, responseCallback } from "api/api";

/**
 * @route api/user/event/:eventIndex
 * @post
 */
export const apiUserEvent = async (eventIndex: number) => {
    const response = await kyRequest.post(`${BACKEND_HOST}/user/event/${eventIndex}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return responseCallback(response);
};
