import { kyRequest, BACKEND_HOST, responseCallback } from "api/api";

/**
 * @route api/user/event/:eventIndex
 * @post
 */
export const api_UserEvent_DELETE = async (eventIndex: number) => {
    const response = await kyRequest.delete(`${BACKEND_HOST}/user/event/${eventIndex}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return responseCallback(response);
};
