import { kyRequest, BACKEND_HOST, responseCallback } from "api/api";

/**
 * @route api/promo/promo_id
 * @delete
 * @post
 */
export const apiPromoDelete = async (data: any) => {
    const response = await kyRequest.delete(`${BACKEND_HOST}/promo/${data.id}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return responseCallback(response);
};
