import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { Color } from "@material-ui/lab/Alert";
import { fetchRedirectUrl, sendUserSocialCode } from "./api";
import { onResponseFail as registrationFail } from "../registration-route/redux-store/registration-slicer/registration-slicer";
import { onResponseSuccess as tokenSuccess, onResponseFail as tokenFail } from "../token/redux-store/token/token";

type Response = {
    inProgress: boolean;
    response: {
        result: boolean;
        data: any;
        errors: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};
export const slicerSocialAuth = createSlice({
    name: "social-auth",
    initialState: {
        condition: {
            inProgress: false,
            notice: {
                visibility: false,
                severity: "error",
            },
            response: {
                result: false,
                data: null,
                errors: null,
                message: "",
            },
        } as Response,
    },
    reducers: {
        onConditionClear: state => {
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onRequest: state => {
            state.condition.inProgress = true;
        },
        onNoResponse: state => {
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "Ошибка авторизации",
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        onResponseFail: (state, action: PayloadAction<string | any>) => {
            state.condition.inProgress = false;
        },
        onResponseSuccess: (
            state,
            action: PayloadAction<{
                data: boolean;
                message: string;
                result: boolean;
            }>,
        ) => {
            state.condition = {
                inProgress: false,
                response: {
                    result: action.payload.result,
                    data: null,
                    message: action.payload.message,
                    errors: null,
                },
                notice: {
                    severity: "success",
                    visibility: true,
                },
            };
        },
        onNoticeHide: state => {
            state.condition.notice.visibility = false;
        },
    },
});

export const {
    onConditionClear,
    onRequest,
    onNoResponse,
    onResponseSuccess,
    onResponseFail,
    onNoticeHide,
} = slicerSocialAuth.actions;

export const openSocial = (social: string, accept: boolean, callback?: (result: any) => void) => async (
    dispatch: any,
) => {
    localStorage.setItem("social", social);
    dispatch(onRequest());
    const result = await fetchRedirectUrl(social, accept).catch(e => {
        console.log(e);
    });
    if (result) {
        if (result.link) await dispatch(onResponseSuccess(result));
        else {
            await dispatch(onResponseFail(result));
            await dispatch(registrationFail(result));
        }
    } else {
        dispatch(onNoResponse());
    }
    if (callback) {
        callback(result);
    }
};
export const authBySocialCode = (
    social: string,
    code: string,
    state?: string,
    callback?: (result: any) => void,
) => async (dispatch: any) => {
    dispatch(onRequest());
    const result = await sendUserSocialCode(social, code, state).catch(e => {
        console.log(e);
    });
    if (result) {
        if (result.result) await dispatch(tokenSuccess(result));
        else await dispatch(tokenFail(result));
    } else {
        dispatch(onNoResponse());
    }
    if (callback) {
        callback(result);
    }
};
export const reselectSocialCondition = (state: RootState): Response => {
    return state.social.condition;
};

export default slicerSocialAuth.reducer;
