import React from "react";
import __locale from "../../../../../../../helper/location/location";
import clsx from "clsx";
import classes from "./PartnerCard.module.css";
import __translate from "../../../../../../../helper/locale/locale";
import ExponentCardImgWrapper from "../../../../../Exponents/ExponentsList/ExponentCard/ExponentCardImgWrapper/ExponentCardImgWrapper";
import { selectByLang } from "../../../../../../FormEvent/FormEvent";
import { LangEnum } from "../../../../../../../controllers/admin-speaker/enums";
import { IPartner } from "../../../../../../types/partners";

interface PartnerCardProps {
    partner: IPartner;
    disabled?: boolean;
}

const Partner = ({ partner, disabled }: PartnerCardProps) => {
    const lang = __locale();
    return (
        <div
            className={clsx(classes.partnerCard, disabled && classes.partnerCardDisabled)}
            style={{
                background: partner.background || "none",
            }}>
            <span>{__translate(partner.key, partner.heading)}:</span>
            <div>
                {partner?.cards?.map(card => (
                    <ExponentCardImgWrapper
                        key={card.id}
                        link={selectByLang(card.site, lang as LangEnum, "")}
                        imgAlt={selectByLang(card.logo, LangEnum.RU, {})?.name || "image"}
                        imgSrc={selectByLang(card.logo, LangEnum.RU, {})?.file || "image"}
                        name={selectByLang(card.name, lang as LangEnum, "")}
                    />
                ))}
            </div>
        </div>
    );
};

export default Partner;
