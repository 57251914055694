export function downloadBlob(blob: Blob, filename: string, target?: string) {
    const url = (window.URL || window.webkitURL).createObjectURL(blob);
    downloadUrl(url, filename, target);
    window.URL.revokeObjectURL(url);
}

export const downloadUrl = (url: any, filename = 'KDW Бейдж.png', target = '_blank') => {
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    if (target) a.target = target;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};


export const fileValueChanger = (file: any, fieldName: string, callback = (e: any) => {}) => {
    const file_url = typeof file?.name == "string" ? URL.createObjectURL(file) : file;
    const file_name = (typeof file?.name == "string" ? file.name : file)?.substr(0, 10);
    callback({target:{
        name: fieldName,
        value: {
            file: file_url,
            name: file_name,
        },
    }})
}