import createSvgIcon from "@material-ui/core/utils/createSvgIcon";
export default createSvgIcon(<g>
    <path
        d="M6.8388 -6.04879e-07L6.96563 -5.93792e-07C11.2973 -2.15106e-07 13.3851 1.70399 13.746 5.52093C13.7851 5.92015 13.4924 6.28042 13.0826 6.31937C12.6924 6.35832 12.3217 6.05647 12.2826 5.65725C11.9997 2.5998 10.5558 1.46056 6.95587 1.46056L6.82905 1.46056C2.85836 1.46056 1.4535 2.8627 1.4535 6.8257L1.45349 13.1743C1.45349 17.1373 2.85836 18.5394 6.82904 18.5394L6.95587 18.5394C10.5753 18.5394 12.0192 17.3807 12.2826 14.2649C12.3314 13.8656 12.6729 13.5638 13.0826 13.6027C13.4924 13.6319 13.7851 13.9922 13.7558 14.3914C13.4241 18.2668 11.3266 20 6.96563 20L6.8388 20C2.04861 20 -0.000147641 17.9552 -0.000147223 13.1743L-0.000146668 6.8257C-0.00014625 2.04479 2.04861 -1.02365e-06 6.8388 -6.04879e-07Z"
        fill="white"/>
    <path
        d="M7.07227 9.2699L18.1746 9.26991C18.5745 9.26991 18.9063 9.60097 18.9063 10.0002C18.9063 10.3994 18.5745 10.7305 18.1746 10.7305L7.07227 10.7305C6.67228 10.7305 6.34057 10.3994 6.34057 10.0002C6.34057 9.60097 6.67228 9.2699 7.07227 9.2699Z"
        fill="white"/>
    <path
        d="M16.0025 6.00739C16.1878 6.00739 16.3732 6.07555 16.5196 6.22161L19.7878 9.48353C20.0707 9.76591 20.0707 10.2333 19.7878 10.5157L16.5196 13.7776C16.2366 14.06 15.7683 14.06 15.4854 13.7776C15.2025 13.4952 15.2025 13.0278 15.4854 12.7455L18.2366 9.9996L15.4854 7.25374C15.2025 6.97136 15.2025 6.50398 15.4854 6.22161C15.622 6.07555 15.8171 6.00739 16.0025 6.00739Z"
        fill="white"/>
</g>, 'Logout');
