import { kyRequest, BACKEND_HOST, responseCallback } from "api/api";

/**
 * @route api/user
 * @post
 */
export const apiAdminExpoById = async (id: number) => {
    let url = `${BACKEND_HOST}/exponent/${id}`;

    const response = await kyRequest.get(url, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return responseCallback(response);
};
