import { kyRequestBase, kyRequest, BACKEND_HOST, responseCallback } from "api/api";
import { Speaker } from "controllers/admin-speaker/interfaces/admin-speaker";

/**
 * @route api/token
 * @post
 * @param {Speaker} formData
 */
export const apiSpeakerCreate = async (formData: Speaker) => {
    const data = await getSpeakerFormData(formData, "ru");
    const response = await kyRequest
        .post(`${BACKEND_HOST}/speaker`, {
            body: data,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
        .catch(e => {
            console.log("speaker", `${e.name}: ${e.message}`);
            return e;
        });
    return responseCallback(response);
};

export const apiSpeakerUpdate = async (speaker: Speaker, lang: string) => {
    const data = await getSpeakerFormData(speaker, lang);
    data.append("_method", "PUT");

    const response = await kyRequestBase
        .post(`${BACKEND_HOST}/speaker/${speaker.id}`, {
            body: data,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                "Accept-Language": lang,
            },
        })
        .catch(e => {
            console.log("speaker", `${e.name}: ${e.message}`);
            return e;
        });

    return responseCallback(response);
};

const selectLangValue = (object: any, lang: string) => {
    if (object) {
        return object[lang];
    }
    return null;
};

const getSpeakerFormData = async (formData: Speaker, lang: string) => {
    const data = new FormData();

    const name = selectLangValue(formData.name, lang);
    name && data.append("name", name);

    const surname = selectLangValue(formData.surname, lang);
    surname && data.append("surname", surname);

    const lastname = selectLangValue(formData.lastname, lang);
    lastname && data.append("lastname", lastname);

    const photo = (formData.photo as any)[lang];
    const photoFile = await getFileFromUrl(photo?.file, photo?.name);
    photoFile && data.append("photo", photoFile);

    const place_of_work = selectLangValue(formData.place_of_work, lang);
    place_of_work && data.append("place_of_work", place_of_work);

    const work_position = selectLangValue(formData.work_position, lang);
    work_position && data.append("work_position", work_position);

    const forumThemes = selectLangValue(formData.forum_themes, lang);
    for (var i = 0; i < forumThemes?.length; i++) {
        data.append("forum_themes[]", forumThemes[i]);
    }

    const academic_degree = selectLangValue(formData.academic_degree, lang);
    academic_degree && data.append("academic_degree", academic_degree);

    const report = selectLangValue(formData.report, lang);
    report && data.append("report", report);

    const country = selectLangValue(formData.country, lang);
    country && data.append("country", country);

    const city = selectLangValue(formData.city, lang);
    city && data.append("city", city);

    const theses = selectLangValue(formData.theses, lang);
    theses && data.append("theses", theses);

    return data;
};

const getFileFromUrl = async (url: string, name: string) => {
    if (url && name) {
        const logoBlob = await fetch(url).then(d => d.blob());
        const logoFile = new File([logoBlob], name);
        return logoFile;
    }
    return "";
};
