import UDFileUploadButton from "components/FIleUploadButton/input";
import React from "react";
import { PhotoForFileInput } from "./styles";
import { fileValueChanger } from "../../../helper/blob/blob";

type Props = {
    value: any;
    onChange: (e: any) => void;
    onImageDelete?: () => void;
    isDisabled?: boolean;
    name?: string;
    hideActions?: boolean;
};

const ImageUploadButton = (props: Props) => {
    const { value, onChange, isDisabled, hideActions = false } = props;
    let src = typeof value == "string" ? value.replaceAll("\\", "/") : value;
    return (
        <>
            {src && <PhotoForFileInput url={src} />}
            <div className="p-d-flex p-ai-center mb-2">
                <UDFileUploadButton
                    disabled={isDisabled}
                    handleFile={(f: any) => fileValueChanger(f, props.name, onChange)}
                    accept="image/*"
                    hideActions={hideActions}
                    name={props.name}
                    value={value}
                />
            </div>
        </>
    );
};

export default ImageUploadButton;
