import {BACKEND_HOST, kyRequest, prepareResponse} from "api/api";
import {TypeExpoRegistration} from "./store";



export const expoLeaderFormChange = async (id:number, formData: any) => {
    const date = new FormData();
    Object.keys(formData).forEach((key: string, index: number, arr: any) => {
        // @ts-ignore
        switch (key) {
            default: {
                date.append(key, (formData as any)[key]);
            }
        }
    });
    const response = await kyRequest.post(`${BACKEND_HOST}/participant/form/it-leader/${id}`, {
        timeout: 30000,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: date,
    });
    return prepareResponse(response);
};
export const expoLeaderRegistration = async (formData: TypeExpoRegistration) => {
    const date = new FormData();
    Object.keys(formData).forEach((key: string, index: number, arr: any) => {
        // @ts-ignore
        switch (key) {
            case 'exponent':
                date.append(key + '_id', (formData as any)[key]?.id || null);
                break;
            default: {
                date.append(key, (formData as any)[key]);
            }
        }
    });
    const response = await kyRequest.post(`${BACKEND_HOST}/participant/form/it-leader`, {
        timeout: 30000,
        body: date,
    });
    return prepareResponse(response);
};
export const expoLeaderRegistrations = async (filter: any) => {

    const response = await kyRequest.post(`${BACKEND_HOST}/participant/form/it-leader`, {
        timeout: 30000,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        json: {...filter, ['_method']: 'GET'},
    });
    return prepareResponse(response);
};
export const expoLeaderToggleVote = async (id:number) => {

    const response = await kyRequest.post(`${BACKEND_HOST}/participant/form/it-leader/${id}/vote`, {
        timeout: 30000,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return prepareResponse(response);
};
export default {
    expoRegistration: expoLeaderRegistration,
    expoFormChange: expoLeaderFormChange
}
