import React from "react";
import { FormControl, FormHelperText, Grid, GridSize, InputLabel, MenuItem, Select } from "@material-ui/core";
import __translate from "../../../helper/locale/locale";

type Props = {
    error?: string;
    disabled?: boolean;
    required?: boolean;
    onChange: (event: any) => void;
    value: any;
    xs?: GridSize;
    name: string;
    label?: string;
    placeholder?: string;
};
const OnlineSelect: React.FC<Props> = ({ error = "", disabled = false, required = false, onChange, xs = 12, name, value,label=null, placeholder=null  }) => {
    const label1 =  label || __translate("signup.online_title", "Тип участия");
    return (
        <>
            <Grid item xs={xs}>
                <FormControl error={!!error} fullWidth variant="outlined">
                    {!label && <InputLabel required={required} id="online-select-label">
                        {label1}
                    </InputLabel>}
                    <Select
                        labelId="online-select-label"
                        id="online-select-1"
                        name={name}
                        disabled={disabled}
                        placeholder={label1}
                        label={label1}
                        renderValue={(value: any) => {
                            switch (value) {
                                case false:
                                case 0:
                                    return __translate("signup.online_no", "Очное");
                                case true:
                                case 1:
                                    return __translate("signup.online_yes", "Онлайн");
                                default:
                                    return __translate("signup.not_selected", "Не выбранo");
                            }
                        }}
                        value={value === null || value === undefined ? value : (value ? 1 : 0)}
                        onChange={onChange}>
                        <MenuItem value={0}>{__translate("signup.online_no", "Очное")}</MenuItem>
                        <MenuItem value={1}>{__translate("signup.online_yes", "Онлайн")}</MenuItem>
                    </Select>
                    {error && <FormHelperText>{error}</FormHelperText>}
                </FormControl>
            </Grid>
        </>
    );
};

export default OnlineSelect;
