import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {useStyles as userStyles} from "../../KdwUserPage"
import {useDispatch, useSelector} from "react-redux";
import {reselect_Menu_Status, setProfileTitle} from "controllers/profile-route/redux-store/profile/profile";
const useStyles = makeStyles(theme => ({
    center: {
        display: "flex",
        alignItems: "center",
    },
    link: {
        width: 200,
    },
    icon: {
        width: "1em",
        height: "1em",
        [theme.breakpoints.down(958)]: {
            width: "20px",
            height: "20px",
        },
    },
    activeIcon: {
        ["-webkit-text-fill-color"]: "#005E4D",
        "color": "#005E4D",
        "& span::after": {
            display: "none",
        }
    },
    disabled: {
        color: "#CDCDCD !important",
        "&:hover div div.MuiGrid-container": {
            textDecoration: "none !important",
        },
    },
    navLink: {
        width: "100%",
        textDecoration: "none",
        fontStyle: "normal",
        fontSize: "18px",
        lineHeight: "20px",
        color: "#6F6F6F",
        padding: "0em 0",
        display: "inline-block",
        "&:hover div div.MuiGrid-container": {
            textDecoration: "underline",
        },
        whiteSpace: "nowrap",
    },
    textLink: {
        display: (props: { hide: boolean }) => (props.hide ? "none" : "block"),
        [theme.breakpoints.down(958)]: {
            display: "block !important",
        },
    },
}));

const KdwNavLink = ({
    text = null,
    to = {},
    includeToActive = null,
    onClick = null,
    split = false,
    disabled = false,
    ...props
}) => {
    const hide = useSelector(reselect_Menu_Status);
    const classes = useStyles({ hide });
    const cl = userStyles({ hide });
    const location = useLocation();
    const dispatch = useDispatch();
    if (!text) return null;
    const active = includeToActive !== true && location.pathname.includes(includeToActive);
    if (active) {
        dispatch(setProfileTitle(text))
    }
    return (
        <React.Fragment>
            <NavLink
                onClick={!disabled && onClick || (() => {})}
                className={
                    (includeToActive === true || active
                        ? classes.navLink + " " + cl.activeLink
                        : classes.navLink) +
                    " " + classes.link +
                    " " +( disabled && classes.disabled)

                }
                to={to}>
                <Grid container className={classes.center}>
                    <Grid item>
                        {props.icon
                            ?
                                <IconButton aria-label="" className="custom"> {props.icon(
                                              active ? "primary" : "disabled",
                                            classes.icon + ' ' + (active ? classes.activeIcon : null),
                                          )}
                                </IconButton>
                        : null}
                    </Grid>
                    <Grid className={classes.textLink} item>
                        <Grid container direction={"column"}>
                            {split ? text.split(" ").map((v: any) => <span>{v}</span>) : <span>{text}</span>}
                        </Grid>
                    </Grid>
                </Grid>
            </NavLink>
        </React.Fragment>
    );
};

export default KdwNavLink;
