import { apiAdminSpeakerIndex } from "controllers/admin-speaker/admin-speaker-get-list/api/admin-speaker-get-list/admin-speaker-get-list";
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { Color } from "@material-ui/lab/Alert";

import { Speaker } from "controllers/admin-speaker/interfaces/admin-speaker";
export type Response = {
    inProgress: boolean;
    response: {
        result: boolean;
        data: any;
        errors: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};

export type Paginator = {
    current_page: number;
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    next_page_url: string;
    path: string;
    per_page: number;
    prev_page_url: null;
    to: number;
    total: number;
};

export type SpeakerFilter = {
    id?: number;
    name?: string;
    name_en?: string;
    surname?: string;
    surname_en?: string;
    lastname?: string | null;
    lastname_en?: string;
    sector?: string | null;
    role?: string;
    place_of_work?: string;
    place_of_work_en?: string | null;
    work_position?: string | null;
    work_position_en?: string | null;
    country?: string;
    region?: string;
    city?: string;
    forum_themes?: [];
    photo?: any | null;
    source?: number;
    accept_policy?: boolean;
    subscribe?: boolean;
    remember_token?: string | null;
    created_at?: number;
    updated_at?: number;
    academic_degree?: string | null;
    academic_degree_other?: string | null;
    pay_order_id?: string | null;
    pay_status?: string | null;
    locale?: string | null;
    is_vip?: boolean;
    covid_state?: boolean;
    covid_admin_action?: boolean;
    online?: boolean;
    cyber?: boolean;
    social?: number | null;
    role_approved?: boolean;
    user_type?: string;
    phone?: string;
    full_name?: string | null;
    min_date?: string;
    max_date?: string;
    per_page?: number | null;
};

const speakerFilterInitial: SpeakerFilter = {
    name: "",
    forum_themes: [],
};

export const slicerAdminSpeakerIndex = createSlice({
    name: "admin-speaker-index",
    initialState: {
        paginator: {
            current_page: 1,
            first_page_url: "",
            from: 1,
            last_page: 1,
            last_page_url: "",
            next_page_url: "",
            path: "",
            per_page: 15,
            prev_page_url: null,
            to: 15,
            total: 0,
        } as Paginator,
        list: [] as Array<Speaker>,
        condition: {
            inProgress: false,
            notice: {
                visibility: false,
                severity: "error",
            },
            response: {
                result: false,
                data: null,
                errors: null,
                message: "",
            },
        } as Response,
        filter: speakerFilterInitial,
    },
    reducers: {
        onConditionClear: state => {
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onRequest: state => {
            state.condition.inProgress = true;
        },
        onNoResponse: state => {
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        onResponseFail: (state, action: PayloadAction<string>) => {
            state.condition.inProgress = false;
            if (typeof action.payload === "string") {
                state.condition.response = {
                    result: false,
                    data: null,
                    errors: null,
                    message: action.payload,
                };
                state.condition.notice = {
                    severity: "error",
                    visibility: true,
                };
            }
        },
        onResponseSuccess: (
            state,
            action: PayloadAction<{
                data: Array<Speaker>;
                current_page: number;
                message: string;
                result: boolean;
                first_page_url: string;
                from: number;
                last_page: number;
                last_page_url: string;
                next_page_url: string;
                path: string;
                per_page: number;
                prev_page_url: null;
                to: number;
                total: number;
            }>,
        ) => {
            state.paginator = {
                current_page: action.payload.current_page,
                first_page_url: action.payload.first_page_url,
                from: action.payload.from,
                last_page: action.payload.last_page,
                last_page_url: action.payload.last_page_url,
                next_page_url: action.payload.next_page_url,
                path: action.payload.path,
                per_page: action.payload.per_page,
                prev_page_url: action.payload.prev_page_url,
                to: action.payload.to,
                total: action.payload.total,
            };
            state.list = action.payload.data;
            state.condition = {
                inProgress: false,
                response: {
                    result: action.payload.result,
                    data: null,
                    message: action.payload.message,
                    errors: null,
                },
                notice: {
                    severity: "success",
                    visibility: true,
                },
            };
        },
        onNoticeHide: state => {
            state.condition.notice.visibility = false;
        },
        onChange: (state, action) => {
            let ref;
            const nameRoute = action.payload.name.split(".");
            const fieldName = nameRoute.pop();
            ref = nameRoute.reduce((acc: any, item: string) => {
                // @ts-ignore
                return acc[item];
            }, state.filter);
            if (action.payload.hasOwnProperty("checked")) {
                ref[fieldName] = action.payload.checked;
            } else {
                ref[fieldName] = action.payload.value;
            }
        },
        resetSpeakerFilter: state => {
            state.filter = speakerFilterInitial;
        },
    },
});

export const {
    onConditionClear,
    onRequest,
    onNoResponse,
    onResponseSuccess,
    onResponseFail,
    onNoticeHide,
    onChange,
    resetSpeakerFilter,
} = slicerAdminSpeakerIndex.actions;

export const asyncAdminSpeakerIndex = (
    page: number,
    filterParams?: SpeakerFilter,
    exportXLS?: boolean,
    callback?: (result: any) => void,
) => async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(onRequest());
    const result = await apiAdminSpeakerIndex(page, filterParams, exportXLS).catch(e => {
        // dispatch(onResponseCatch(`${e.name}: ${e.message}`));
    });
    if (result) {
        dispatch(result.result ? onResponseSuccess(result) : onResponseFail(result));
    } else {
        dispatch(onNoResponse());
    }
    if (callback) {
        callback(result);
    }
};

export const reselectAdminSpeaker = (state: RootState): Array<any> => {
    return state.adminSpeaker.index.list;
};
export const reselectAdminSpeakerPaginator = (state: RootState): Paginator => {
    return state.adminSpeaker.index.paginator;
};
export const reselectAdminSpeakerCondition = (state: RootState): Response => {
    return state.adminSpeaker.index.condition;
};

export const reselectAdminSpeakerFilter = (state: RootState): SpeakerFilter => {
    return state.adminSpeaker.index.filter;
};

export default slicerAdminSpeakerIndex.reducer;
