import { kyRequest, BACKEND_HOST, responseCallback } from "api/api";
import { User } from "controllers/admin-users/api/admin-users/admin-users";

/**
 * @route api/user
 * @get
 * @post
 */
export const apiUserGet = async (userIndex: User["id"] | string) => {
    const response = await kyRequest.get(`${BACKEND_HOST}/user/${userIndex}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return responseCallback(response);
};
