import { ReactComponent as TelegramSVG } from "../../styles/results/icon/social/telegram.svg";
import { ReactComponent as YouTubeSVG } from "../../styles/results/icon/social/youtube.svg";
import { ReactComponent as VkSVG } from "../../styles/results/icon/social/vk.svg";
import { ReactComponent as TenChatSVG } from "../../styles/results/icon/social/tenChat.svg";

export const SOCIAL_VK = "https://vk.com/kazandigitalweek";
export const SOCIAL_TELEGRAM = "https://t.me/kazandigitalweek";
export const SOCIAL_YOUTUBE = "https://www.youtube.com/channel/UC_Lea7q3d-48qDwG2hPx8Fw";
export const SOCIAL_TENCHAT = "https://tenchat.ru/2270763?utm_source=a277dbd5-0523-48cf-8d30-f3341970dcf3";
export const SOCIAL_KDWICK = "https://t.me/kdweekbot";

export const SOCIALS = [
    {
        link: SOCIAL_VK,
        icon: <VkSVG />,
    },
    {
        link: SOCIAL_TELEGRAM,
        icon: <TelegramSVG />,
    },
    {
        link: SOCIAL_YOUTUBE,
        icon: <YouTubeSVG />,
    },
    {
        link: SOCIAL_TENCHAT,
        icon: <TenChatSVG />,
    },
];
