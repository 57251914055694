import {kyRequest, BACKEND_HOST, prepareResponse} from "api/api";
import { downloadBlob } from "helper/blob/blob";
import { serializeObjectToQueryParams } from "helper/query";


/**
 * @route api/cost
 * @get
 * @post
 */
export const apiEmployeeGet = async (data: any) => {
    const urlParams = new URLSearchParams(data).toString()
    const response = await kyRequest.get(`${BACKEND_HOST}/company/${data.company_id}/employee?${urlParams}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        timeout: data.export ? 300000 : 30000
    });
    if (data.export) {
        response.blob().then(blob => downloadBlob(blob, "kdw_users.xls"));
    }
    return prepareResponse(response);
};
export const apiEmployeeDelete = async (data: any) => {
    const response = await kyRequest.delete(`${BACKEND_HOST}/company/${data.company_id}/employee/${data.id}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return prepareResponse(response);
};

export const apiEmployeeAdd = async (data: any,) => {
    let formdata = new FormData()
    Object.keys({ ...data }).forEach(key => {
        let v = undefined;
        switch (key) {
            case "email":
            case "role":
            case "subcompany":
            case "main":
            case "promos[4]":
            case "promos[512]":
            default:
                v = data[key];
        }
        if (v !== undefined) formdata.append(key, v as any);
    });
    const response = await kyRequest.post(`${BACKEND_HOST}/company/${data.id}/employee/add`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body:formdata
    });
    return prepareResponse(response);
};