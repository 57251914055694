import MuiAlert, { Color } from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import React, {useEffect, useState} from "react";

type Props = {
    isOpen: boolean;
    severity: Color;
    handleOpen?: any;
    message: string;
};

export const CondSnackbar = ({cond} : any) => {
    return (<KDWSnackbar isOpen={cond.notice.visibility} severity={cond.notice.severity} message={cond.response?.message||cond.notice?.message}/>)
};

const KDWSnackbar: React.FC<Props> = ({ isOpen, severity, handleOpen, message }) => {
    const [open, setOpen] = useState(isOpen);
    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
        handleOpen && handleOpen(false);
    };
    useEffect(() => {
        if (isOpen) {
            setOpen(true);
            let t = setTimeout(() => {
                setOpen(false);
                handleOpen && handleOpen(false);
                clearTimeout(t);
            }, 4000)
        }
    }, [isOpen])
    return (
        <Snackbar
            tabIndex={10000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={open}
            autoHideDuration={4000}>
            <MuiAlert onClose={handleClose} severity={severity}>
                {message}
            </MuiAlert>
        </Snackbar>
    );
};

export default KDWSnackbar;
