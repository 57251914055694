import {kyRequest, BACKEND_HOST, responseCallback, prepareResponse} from "api/api";
import { helpRemoveMaskPhoneNumber } from "helper/phone-number/phone-number";
import { RegistrationAcademicDegree, RegistrationRole } from "constants/constants";
import { FILE_STORAGE } from "controllers/registration-route/redux-store/registration-slicer/registration-slicer";
import {getFileFromUrl} from "../../../profile-route-post/api/profile-post/profile-post";

export type TypeRequestRegistration = {
    day: string;
    month: any;
    year: string;
    work_position: string;
    place_of_work: string;
    place_of_work_en: string;
    country: string;
    email: string;
    phone: string;
    forum_themes: Array<number | string>;
    region: string;
    role: number;
    city: string;
    birthday: string;
    online: string | number;
    sector: string;
    surname: string;
    name: string;
    lastname: string;
    source: string;
    promo: string;
    accept_policy: boolean;
    subscribe: boolean;
    academic_degree: number | string;
    academic_degree_other: string;
    report_title: string;
    report_forum_theme: string;
    report_annotation_file: string;
    photo: string;
    hackathon_id?: number;
    hackathon_team?: string;
    hackathon_role?: string;
    hackathon_task?: string;
    hackathon_source?: string;
    hackathon_tgconnect?: string;
    social_link?: string;
    team?: [];
    is_vip: boolean;
    smi_days: any[];
    work_type?: string,
    is_curator?: boolean,
    curator_role?: number,
    doc_number?: string,
    org_type?: string,
    doc_number2?: string,
    doc_org?: string,
    doc_photo?: string,
    doc_date?: string,
    order?: string,
    target?: string,
};

// export const DataExampleRegistration: TypeRequestRegistration = {
//     day: "01",
//     month: "1",
//     year: "1987",
//     role: RegistrationRole.SPEAKER,
//     work_position: "qwerty",
//     place_of_work: "qwerty",
//     country: "Россия",
//     email: "eldamartwilight@gmail.com",
//     phone: helpMaskPhoneNumber("79272451731", true),
//     forum_themes: [0, 1, 2, 3],
//     region: "",
//     city: "Казань",
//     online: 0,
//     sector: "0",
//     surname: "qwerty",
//     name: "qwerty",
//     lastname: "qwerty",
//     source: "0",
//     promo: "",
//     accept_policy: true,
//     subscribe: false,
//     academic_degree: RegistrationAcademicDegree.NO_DEGREE,
//     academic_degree_other: "qwerty",
//     birthday: "2021-02-13",
//     report_title: "report_title",
//     report_forum_theme: "",
//     report_annotation_file: "",
//     photo: "",
// };

export const DataExampleRegistration: TypeRequestRegistration = {
    day: "01",
    month: "1",
    year: "1987",
    role: null,
    work_position: "",
    place_of_work: "",
    place_of_work_en: "",
    country: "",
    email: "",
    phone: "",
    forum_themes: [],
    region: "",
    city: "",
    online: null,
    sector: "",
    surname: "",
    name: "",
    lastname: "",
    source: "",
    promo: "",
    accept_policy: false,
    subscribe: false,
    academic_degree: "",
    academic_degree_other: "",
    birthday: "",
    report_title: "report_title",
    report_forum_theme: "",
    report_annotation_file: "",
    photo: "",
    is_vip: false,
    smi_days: null,
};

export const apiCheckCredential = async (val: string, field = 'email') => {

    let formData = new FormData();
    formData.append(field, val);
    const response = await kyRequest.post(`${BACKEND_HOST}/registration/${field}-check`, {
        body: formData,
    });
    return prepareResponse(response);
};
export const apiUniversity= async (countryIndex:string, q:string) => {
    const response = await kyRequest.get(`${BACKEND_HOST}/db/university?country_id=${countryIndex}&q=${q}`);
    return prepareResponse(response);
};
/**
 * @route api/registration
 * @post
 * @param {TypeRequestRegistration} formData
 */
export const apiRouteRegistration = async (formData: TypeRequestRegistration) => {
    const date = new FormData();
    // console.log("formData", formData);
    // return;
    if (formData.role === RegistrationRole.SPEAKER && !formData.photo) date.append("draft", "1");
    // @ts-ignore
    if (formData.online === false || formData.online === true) date.append("online", "" + (formData.online ? 1 : 0));
    if (formData.is_vip === true) date.append("is_vip", "1");
    if (formData.is_curator === true) date.append("is_curator", "1");
    formData.hasOwnProperty("curator_role") && date.append("curator_role", "" + (formData.curator_role));
    formData.birthday && date.append("birthday", formData.birthday?.split(" ")?.[0]);
    formData.doc_date && date.append("doc[doc_date]", formData.birthday?.split(" ")?.[0]);

    const photo = !formData.photo ? null : await getFileFromUrl(FILE_STORAGE?.photo?.file, FILE_STORAGE?.photo?.name);
    date.append("photo", photo);
    const doc_photo = !formData.doc_photo ? null : await getFileFromUrl(FILE_STORAGE?.doc_photo?.file, FILE_STORAGE?.doc_photo?.name);
    date.append("doc[photo]", doc_photo);

    Object.keys(formData).forEach((key: string, index: number, arr: any) => {
        // @ts-ignore
        switch (key) {
            case "report_title": {
                date.append("report[title]", formData[key]);
                break;
            }
            case "report_forum_theme": {
                date.append("report[forum_theme]", formData[key]);
                break;
            }
            case "report_annotation_file": {
                // @ts-ignore
                if (FILE_STORAGE.annotation_file && FILE_STORAGE.annotation_file[0]) {
                    // @ts-ignore
                    date.append("report[annotation_file]", FILE_STORAGE.annotation_file[0], "file.txt");
                } else {
                    date.append("report[annotation_file]", "");
                }
                break;
            }
            case "source":
            case "sector": {
                if (formData[key] !== "") {
                    // @ts-ignore
                    date.append(key, formData[key]);
                }
                break;
            }
            case "phone": {
                if (formData[key]) {
                    // @ts-ignore
                    date.append(key, `+${helpRemoveMaskPhoneNumber(formData[key])}`);
                }
                break;
            }
            case "org_type":
            case "work_position":
            case "country":
            case "email":
            case "region":
            case "city":
            case "surname":
            case "name":
            case "lastname":
            case "role":
            case "promo":
            case "accept_policy":
            case "subscribe":
            case "place_of_work":
            case "order":
            case "target":
            case "work_type":
            case "place_of_work_en": {
                if (formData[key] === 0 || formData[key]) {
                    // @ts-ignore
                    date.append(key, formData[key]);
                }
                break;
            }
            case "doc_number":
            case "doc_number2":
            case "doc_org": {
                if (formData[key]) {
                    // @ts-ignore
                    date.append('doc[' + key+']', formData[key]);
                }
                break;
            }
            case "smi_days":
            case "forum_themes": {
                // @ts-ignore
                const ft = formData[key];
                (Array.isArray(ft) ? ft : [ft]).forEach((theme: any) => {
                    if (Number.parseInt(theme).toString() === ''+theme) {
                        // @ts-ignore
                        date.append(key+`[${theme}]`, theme);
                    }
                });
                break;
            }
            case "academic_degree": {
                // @ts-ignore
                if (formData.role === RegistrationRole.SPEAKER) {
                    // @ts-ignore
                    date.append(key, formData[key]);
                }
                break;
            }
            case "academic_degree_other": {
                if (
                    formData.role === RegistrationRole.SPEAKER &&
                    formData.academic_degree === RegistrationAcademicDegree.OTHER
                ) {
                    date.append(key, formData[key]);
                }
                break;
            }
            case "hackathon_role":
            case "hackathon_tgconnect":
            case "hackathon_task":
            case "hackathon_source":
            case "hackathon_team":
            case "social_link":
            case "hackathon_id": {
                if (formData.role === RegistrationRole.HACKATHON) {
                    // @ts-ignore
                    date.append(key, formData[key]);
                }
                break;
            }
            case "team": {
                if (formData.role === RegistrationRole.HACKATHON) {
                    (formData[key]).forEach((user: any, i) => {
                        Object.keys(user).forEach((field) => {
                            if (user[field]){
                                date.append(key+`[${i}][${field}]`, user[field]);
                            }
                        })
                    });
                }
                break;
            }
            default: {
            }
        }
    });
    date.forEach((key, value) => {
        if (value === "role" || value === "academic_degree" || value === "academic_degree_other") {
            // console.log(`date.forEach: `, key, value);
        }
    });
    if (!formData.promo && localStorage.getItem('cpromo')) {
        date.append('promo', localStorage.getItem('cpromo'))
    }
    const response = await kyRequest.post(`${BACKEND_HOST}/registration`, {
        timeout: date.get("report[annotation_file]")||date.get("doc[photo]")||date.get("photo") ? 120000 : 30000,
        body: date,
    });
    return responseCallback(response);
};
