import React, { useRef, useState } from "react";
import { IPartner } from "../../../types/partners";
import MainPartners from "./MainBlock/MainPartners/MainPartners";
import classes from "./PartnersBlock.module.css";
import { EXPONENTS_STATE, EXPONENTS_TYPE } from "constants/site/common";
import {
    FINANCE_PARTNER,
    GEN_INFO_PARTNER,
    GEN_PARTNER,
    GEN_SECOND_PARTNER,
    RIM,
} from "../../../../constants/site/exponents/companiesOnMainPage";
import Loader from "components/Loader/Loader";
import {
    reselectExpoCondition,
    reselectMainPartnersList,
    reselectPartnersList,
    asyncPartners,
} from "controllers/admin-expo/admin-expo-get-list/redux-store/admin-expo-get-list";
import { useObserver } from "hooks/useObserver";
import { useSelector, useDispatch } from "react-redux";
import OtherPartners from "./MainBlock/OtherPartners/OtherPartners";

// Данная константа помещается в стейт redux. Изменять с осторожностью
export const initStateMain: Record<string, IPartner> = {
    // буквы впереди добавлены для сохранения порядка элементов при преобразовании с помощью Object.values
    [`ab${EXPONENTS_TYPE.gen_partner}`]: {
        case: EXPONENTS_TYPE.gen_partner,
        heading: "Генеральный партнер",
        key: "site.general_partner",
        cards: GEN_PARTNER,
        // background: "#ED6E00",
    },
    [`abc${EXPONENTS_TYPE.gen_partner}`]: {
        case: EXPONENTS_TYPE.gen_partner,
        heading: "Генеральный партнер",
        key: "site.general_partner",
        cards: GEN_SECOND_PARTNER,
        // background: "#ED6E00",
    },

    // [`ab${EXPONENTS_TYPE.gen_hr_partner}`]: {
    //     case: EXPONENTS_TYPE.gen_hr_partner,
    //     heading: "Генеральный HR партнер",
    //     key: "site.hr_partner",
    //     cards: GEN_HR_PARTNER,
    //     background: "#E1011C",
    // },
    [`ab${EXPONENTS_TYPE.gen_info_partners}`]: {
        case: EXPONENTS_TYPE.gen_info_partners,
        heading: "Генеральный информационный партнер",
        key: "site.gen_info_partner",
        cards: GEN_INFO_PARTNER,
        // background: "#2C2A6F",
    },
    [`ab${EXPONENTS_TYPE.finance_partner}`]: {
        case: EXPONENTS_TYPE.finance_partner,
        heading: "Финансовый партнер",
        key: "site.finance_partner",
        cards: FINANCE_PARTNER,
        // background: "#ED6E00",
    },
    [`ab${EXPONENTS_TYPE.official_partners}`]: {
        case: EXPONENTS_TYPE.official_partners,
        heading: "Официальный партнер",
        key: "site.official_organizers",
        cards: [],
        // background: "#07F",
        split: true,
    },
    // [`ab${EXPONENTS_TYPE.official_auto_partner}`]: {
    //     case: EXPONENTS_TYPE.official_auto_partner,
    //     heading: "Официальный автомобильный партнер",
    //     key: "site.official_auto_partner",
    //     cards: OFFICIAL_AUTO_PARTNER,
    //     // background: "linear-gradient(137deg, #3D3D3D 14.85%, #212121 76.7%)",
    // },
    [`ab${EXPONENTS_TYPE.business_program_partner}`]: {
        case: EXPONENTS_TYPE.business_program_partner,
        heading: "Партнер деловой программы",
        key: "site.business_program_partner",
        cards: [],
        split: true,
    },
};
// Данная константа помещается в стейт redux. Изменять с осторожностью
export const initStateOthers: Record<string, IPartner> = {
    [`ab${EXPONENTS_TYPE.industry_partners}`]: {
        case: EXPONENTS_TYPE.industry_partners,
        heading: "Отраслевые партнеры",
        key: "site.industry_partners",
        cards: [],
    },
    [`ab${EXPONENTS_TYPE.partners}`]: {
        case: EXPONENTS_TYPE.partners,
        heading: "Партнеры",
        key: "site.partners",
        cards: [],
    },
    [`ab${EXPONENTS_TYPE.online_partners}`]: {
        case: EXPONENTS_TYPE.online_partners,
        heading: "Онлайн партнеры",
        key: "site.online_partners",
        cards: [],
    },
    [`ab${EXPONENTS_TYPE.official_media_partner}`]: {
        case: EXPONENTS_TYPE.official_media_partner,
        heading: "Официальный медиапартнер",
        key: "site.official_media_partner",
        cards: RIM,
    },
    [`ab${EXPONENTS_TYPE.info_partners}`]: {
        case: EXPONENTS_TYPE.info_partners,
        heading: "Информационные партнеры",
        key: "site.info_partners",
        cards: [],
    },
};

const PartnersBlock = () => {
    const lastElement = useRef();
    const [canLoad, setCanLoad] = useState(true);
    const listCondition = useSelector(reselectExpoCondition);
    const mainPartnersList = useSelector(reselectMainPartnersList);
    const partnersList = useSelector(reselectPartnersList);

    const reduxDispatch = useDispatch();

    const onRequest = () => {
        const filterMain = {
            types: [
                EXPONENTS_TYPE.official_partners,
                EXPONENTS_TYPE.business_program_partner,
                EXPONENTS_TYPE.industry_partners,
                EXPONENTS_TYPE.online_partners,
                EXPONENTS_TYPE.partners,
                EXPONENTS_TYPE.info_partners,
            ],
            state: EXPONENTS_STATE.published,
        };

        reduxDispatch(asyncPartners(filterMain, true));
    };

    useObserver(
        lastElement,
        listCondition.inProgress,
        canLoad && (mainPartnersList?.length === 0 || partnersList?.length === 0),
        // canLoad && partnersList?.length === 0,
        () => {
            onRequest();
            setCanLoad(false);
        },
    );

    return (
        <div className={classes.partners__main} ref={lastElement}>
            {listCondition.inProgress && (mainPartnersList?.length === 0 || partnersList?.length === 0) ? (
                <>
                    <MainPartners />
                    <Loader />
                </>
            ) : (
                <>
                    <MainPartners />
                    <OtherPartners />
                </>
            )}
        </div>
    );
};

export default PartnersBlock;
