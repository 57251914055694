import React, {useEffect} from "react";
import { Grid } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import AdminMenu from "./AdminMenu/AdminMenu";
import { makeStyles } from "@material-ui/core/styles";
import KDWSnackbar from "../components/KDWSnackbar/KDWSnackbar";

export const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
        alignItems: "center",
        background: "#ffffff",
        borderRadius: "21px",
        padding: theme.spacing(8),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
        },
        [theme.breakpoints.down(600 + theme.spacing(3) * 2)]: {
            padding: theme.spacing(4),
            paddingTop: theme.spacing(6),
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },

    titlegreen: {
        color: "#13C4A4",
        marginLeft: "1.5em",
        fontSize: "24px",
    },
    navLink: {
        marginRight: 8,
    },
    logo: {
        [theme.breakpoints.down(1200)]: {
            width: "200px",
            marginLeft: "1em !important",
        },
        [theme.breakpoints.down(958)]: {
            marginTop: "2em !important",
        },
    },
    statblock: {
        background: "#13C4A4",
        color: "#ffffff",
        borderRadius: "12px",
        padding: theme.spacing(6),
    },
    button: {
        marginLeft: theme.spacing(1),
        borderRadius: "12px",
        padding: "0.5em 4em",
    },
    bg: {
        background: "#E5E5E5",
        minHeight: "100vh",
    },
    a: {
        "&:hover": {
            cursor: "pointer",
        },
    },
    input: {
        borderRadius: "12px !important",
    },
    table: {
        minWidth: 650,
        borderRadius: "21px",
    },
    tablehead: {
        background: "#13C4A4",
        color: "#ffffff",
        "& th": {
            color: "#ffffff",
            fontWeight: 400,
        },
    },
    checkbox: {
        color: "#13C4A4 !important",
        ":checked": {
            color: "#13C4A4 !important",
            background: "#13C4A4",
        },
    },
    flexCentered: {
        minHeight: 30,
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const KdwAdminPage = (props: any) => {
    const classes = useStyles();
    const {condition} = props;
    const [isOpen, setOpen] = React.useState(condition?.notice.visibility || false);
    useEffect(() => setOpen(!!condition?.notice.visibility), [!!condition?.notice.visibility])
    const snackbar = {
        isOpen: isOpen,
        handleOpen: () => {setOpen(false);},
        severity: condition?.notice.severity || "info",
        message: condition?.response.message || "",
    }
    return (
        <>
            <KDWSnackbar {...snackbar}/>
            <AdminMenu />

            <Grid container className={classes.bg}>
                <Container maxWidth="xl">{props.children}</Container>
            </Grid>
        </>
    );
};

export default KdwAdminPage;
