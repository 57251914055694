import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { Color } from "@material-ui/lab/Alert";
import { apiPromoDelete } from "controllers/admin-promo-delete/api/admin-promo-delete/admin-promo-delete";

export type Response = {
    inProgress: boolean;
    index: number | string;
    response: {
        result: boolean;
        data: any;
        errors: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};
export const slicerAdminPromoDelete = createSlice({
    name: "promoDelete",
    initialState: {
        condition: {
            index: 0,
            inProgress: false,
            notice: {
                visibility: false,
                severity: "error",
            },
            response: {
                result: false,
                data: null,
                errors: null,
                message: "",
            },
        } as Response,
    },
    reducers: {
        onClear: state => {
            state.condition = {
                index: 0,
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onConditionClear: state => {
            state.condition = {
                index: 0,
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onRequest: (state, action) => {
            state.condition = {
                index: action.payload,
                inProgress: true,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onNoResponse: state => {
            state.condition = {
                index: state.condition.index,
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        onResponseFail: (
            state,
            action: PayloadAction<string> | PayloadAction<{ data: any; errors: any; message: string; result: boolean }>,
        ) => {
            if (typeof action.payload === "string") {
                state.condition = {
                    index: state.condition.index,
                    inProgress: false,
                    response: {
                        result: false,
                        data: null,
                        errors: null,
                        message: action.payload,
                    },
                    notice: {
                        severity: "error",
                        visibility: true,
                    },
                };
            } else {
                state.condition = {
                    index: state.condition.index,
                    inProgress: false,
                    response: {
                        result: false,
                        data: null,
                        errors: null,
                        message: `${action.payload.message}`,
                    },
                    notice: {
                        severity: "error",
                        visibility: true,
                    },
                };
            }
        },
        // message: "responses.controllers.participant.show"
        // result: true
        onResponseSuccess: (
            state,
            action: PayloadAction<{
                data: any;
                message: string;
                result: boolean;
            }>,
        ) => {
            state.condition = {
                index: state.condition.index,
                inProgress: false,
                response: {
                    result: action.payload.result,
                    data: action.payload.data,
                    message: `${action.payload.message}`,
                    errors: null,
                },
                notice: {
                    severity: "success",
                    visibility: true,
                },
            };
        },
        onNoticeHide: state => {
            state.condition.notice.visibility = false;
        },
    },
});

export const {
    onConditionClear,
    onRequest,
    onNoResponse,
    onResponseSuccess,
    onResponseFail,
    onNoticeHide,
    onClear,
} = slicerAdminPromoDelete.actions;

export const asyncPromoDelete = (data: any, callback?: (result: any) => void) => async (dispatch: any) => {
    // dispatch(onRequest());
    const result = await apiPromoDelete(data).catch(e => {
        // dispatch(onResponseCatch(`${e.name}: ${e.message}`));
    });
    if (result) {
        dispatch(result.result ? onResponseSuccess(result) : onResponseFail(result));
    } else {
        dispatch(onNoResponse());
    }
    if (callback) {
        callback(result);
    }
};

export const reselectPromoDeleteCondition = (state: RootState): Response => {
    return state.promoDelete.condition;
};

export default slicerAdminPromoDelete.reducer;
