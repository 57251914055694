import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useStyles } from "pages/KdwAdminPage";

type Props = {
    error?: string;
    disabled?: boolean;
    onChange: (event: any) => void;
    value: any;
    initialFocusedDate?: any;
    minDate?: any;
    maxDate?: any;
    name?: string;
    format?: string;
    label: string;
};
const KdwDateTimePicker: React.FC<Props> = ({
    error = "",
    minDate = null,
    maxDate = null,
    initialFocusedDate = null,
    disabled = false,
    onChange,
    name = "date",
    format = null,
    label,
    value,
}) => {
    const classes = useStyles();

    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                    disabled={disabled}
                    id={name}
                    minDate={minDate}
                    initialFocusedDate={initialFocusedDate}
                    maxDate={maxDate}
                    inputVariant="outlined"
                    ampm={false}
                    label={label}
                    value={value}
                    onChange={e => onChange({ target: { name, value: e } })}
                    onError={() => {}}
                    format={format|| "dd.MM.yyyy HH:mm"}
                    error={!!error}
                    helperText={error}
                    fullWidth
                    required
                    className={classes.input}
                />
            </MuiPickersUtilsProvider>
        </>
    );
};
export default KdwDateTimePicker;
