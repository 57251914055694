import { kyRequest, BACKEND_HOST, responseCallback, kyRequestBase } from "api/api";
import { Expo } from "controllers/admin-expo/interfaces/admin-expo";


export const apiExpoSave = async (expo: Expo, lang: string) => {
    const data = await getExpoFormData({ ...expo }, lang);
    if (expo.id) data.append("_method", "PUT");
    const response = await kyRequestBase
        .post(`${BACKEND_HOST}/exponent/${expo.id || ''}`, {
            body: data,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                "Accept-Language": lang,
            },
        })
        .catch(e => {
            console.log("exponent", `${e.name}: ${e.message}`);
            return e;
        });
    return responseCallback(response);
};

const selectLangValue = (object: any, lang: string) => {
    if (object) {
        return object[`${lang}`] || object;
    }
    return null;
};

const getExpoFormData = async (formData: Expo, lang: string) => {
    const data = new FormData();
    const name = (formData as any).name_catalog || selectLangValue(formData.name, lang);
    name && data.append("name", name);

    const logo = selectLangValue(formData.logo,lang);
    const logoFile = await getFileFromUrl(logo?.file, logo?.name);
    logoFile && data.append("logo", logoFile);

    const info = selectLangValue(formData.info, lang);
    info && data.append("info", info);

    const short = selectLangValue(formData.short, lang);
    short && data.append("short", short);

    const phone = selectLangValue(formData.phone, lang);
    phone && data.append("phone", phone);

    const types = selectLangValue(formData.types, lang);
    for (var i = 0; i < types?.length; i++) {
        data.append("types[]", types[i]);
    }
    let forumThemes = selectLangValue(formData.forum_themes, lang) || formData.forum_themes;
    if (!Array.isArray(forumThemes) && forumThemes !== undefined && forumThemes !== null) forumThemes = [forumThemes];
    for (var i = 0; i < forumThemes?.length; i++) {
        if (forumThemes[i] !== null) data.append("forum_themes[]", forumThemes[i]);
    }

    const presentation = selectLangValue(formData.presentation, lang);
    const pdf = await getFileFromUrl(presentation?.file, presentation?.name);
    pdf && data.append("presentation", pdf);

    const priority = selectLangValue(formData.priority, lang);
    priority && data.append("priority", priority);

    const site = selectLangValue(formData.site, lang);
    site && data.append("site", site);

    const stand = selectLangValue(formData.stand, lang);
    stand && data.append("stand", stand);

    const state = selectLangValue(formData.state, lang);
    state && data.append("state", state);

    const video = selectLangValue(formData.video, lang);
    video && data.append("video", video);

    const email = selectLangValue(formData.email, lang);
    email && data.append("email", email);

    const address = selectLangValue(formData.address, lang);
    address && data.append("address", address);

    const cid = selectLangValue(formData.company_id, lang);
    cid && data.append("company_id", cid);
    return data;
};

const getFileFromUrl = async (url: string, name: string) => {
    if (url && name) {
        const logoBlob = await fetch(url)
            .then(d => d.blob())
            .catch(() => null);
        if (logoBlob) {
            const logoFile = new File([logoBlob], name);
            return logoFile;
        } else {
            return null;
        }
    }
    return null;
};
