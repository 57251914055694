import { kyRequest, BACKEND_HOST, responseCallback } from "api/api";
import {getFileFromUrl} from "../profile-route-post/api/profile-post/profile-post";
import {ChangeReportState} from "../admin-user-put/api/admin-user-put/change-report-state";

/**
 * @route api/cost
 * @get
 * @post
 */
export const getUserArticlesApi = async (article?: number | null, data?: any) => {
    data?.forum_themes?.forEach((v: any, i: any) => {
        if (Number.isInteger(v)) data["forum_themes[" + i + "]"] = v;
    });
    data?.statuses?.forEach((v: any, i: any) => {
        data["statuses[" + i + "]"] = v;
    });
    if (data) delete data["statuses"];
    if (data) delete data["forum_themes"];
    const response = await kyRequest.get(
        `${BACKEND_HOST}/speaker/report/` + (article ? article : "") + "?" + new URLSearchParams(data || {}).toString(),
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        },
    );
    return responseCallback(response, false, false);
};
export const getUserArticle = async (article: any,) => {

    const response = await kyRequest.get(
        `${BACKEND_HOST}/speaker/report/` + (article?.id ? article?.id : ""),
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        },
    );
    return responseCallback(response, false, false);
};
export const postUserArticlesApi = async (article?: any) => {
    const formData = new FormData();

    const id = article.id || article?.report_id;
    if (id) formData.append('_method', 'PUT');

    let file = await getFileFromUrl(
        article?.annotation_file?.file,
        article?.annotation_file?.name,
    );
    file && formData.append("annotation_file", file);
    file = await getFileFromUrl(
        article?.presentation_file?.file,
        article?.presentation_file?.name,
    );
    file && formData.append("presentation_file", file);

    Object.keys({ ...article }).forEach(key => {
        let v = undefined;
        switch (key) {
            case 'annotation_file':
            case 'presentation_file':
            case 'status':
                break;
            case 'accept':
            case 'partner':
            case 'is_unique':
            case 'is_correct_theme':
            case 'accept_presentation_publish':
                v = article[key] ? '1': '0';
                break;
            case 'user_event_id':
                v = article[key]?.id || 0;
                break;
            case 'forum_theme':
                if (Number.isInteger(article[key])) v = article[key];
                break;
            default:
                v = article[key];
        }
        if (v !== undefined) formData.append(key, v as any);
    });
    const response = await kyRequest.post(
        `${BACKEND_HOST}/speaker/report/` + (id || ''),
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            body: formData,
        },
    );
    return responseCallback(response, false, true);
};
