import { apiAdminExpoById } from "controllers/admin-expo/admin-expo-get/api/admin-expo-get";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { Color } from "@material-ui/lab/Alert";
import { Expo } from "controllers/admin-expo/interfaces/admin-expo";

export type Response = {
    inProgress: boolean;
    response: {
        result: boolean;
        data: any;
        errors: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};

export const slicerAdminExpoIndex = createSlice({
    name: "admin-expo-index",
    initialState: {
        expo: null,
        condition: {
            inProgress: false,
            notice: {
                visibility: false,
                severity: "error",
            },
            response: {
                result: false,
                data: null,
                errors: null,
                message: "",
            },
        } as Response,
    },
    reducers: {
        onConditionClear: state => {
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onRequest: state => {
            state.condition.inProgress = true;
        },
        onNoResponse: state => {
            console.log("onNoResponse");
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        onResponseFail: (state, action: PayloadAction<string>) => {
            console.log("onResponseFail", action.payload);
            console.log("onResponseFail", typeof action.payload);
            state.condition.inProgress = false;
            if (typeof action.payload === "string") {
                state.condition.response = {
                    result: false,
                    data: null,
                    errors: null,
                    message: action.payload,
                };
                state.condition.notice = {
                    severity: "error",
                    visibility: true,
                };
            }
        },
        onResponseSuccess: (
            state,
            action: PayloadAction<{
                data: Expo;
                current_page: number;
                message: string;
                result: boolean;
            }>,
        ) => {
            state.expo = action.payload.data;
            state.condition = {
                inProgress: false,
                response: {
                    result: action.payload.result,
                    data: null,
                    message: action.payload.message,
                    errors: null,
                },
                notice: {
                    severity: "success",
                    visibility: true,
                },
            };
        },
    },
});

export const {
    onConditionClear,
    onRequest,
    onNoResponse,
    onResponseSuccess,
    onResponseFail,
} = slicerAdminExpoIndex.actions;

export const asyncAdminExpoById = (id: number, callback?: (result: any) => void) => async (dispatch: any) => {
    dispatch(onRequest());
    const result = await apiAdminExpoById(id).catch((e: any) => {
        // dispatch(onResponseCatch(`${e.name}: ${e.message}`));
    });
    if (result) {
        dispatch(result.result ? onResponseSuccess(result) : onResponseFail(result));
    } else {
        dispatch(onNoResponse());
    }
    if (callback) {
        callback(result);
    }
};

export const reselectAdminExpoById = (state: RootState): Expo => {
    return state.adminExpo.byId.expo;
};

export const reselectAdminExpoByIdCondition = (state: RootState): Response => {
    return state.adminExpo.byId.condition;
};

export default slicerAdminExpoIndex.reducer;
