import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { Response } from "../admin-users/redux-store/admin-users/admin-users";
import api, {
    apiStatByDatesDataAction,
    apiStatDataAction,
    apiStatPaysByRoleAction,
    apiStatPayUsersByRoleAction,
} from "./api";

export const slicerAdminDiagram = createSlice({
    name: "diagram-data",
    initialState: {
        condition: {
            inProgress: false,
            notice: {
                visibility: false,
                severity: "success",
            },
            response: {
                result: false,
                data: null,
                errors: null,
                message: "",
            },
        } as Response,
        data: {} as {
            [apiStatDataAction]: any;
            [apiStatByDatesDataAction]: any;
        },
    },
    reducers: {
        onConditionClear: state => {
            state.condition = {
                ...state.condition,
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onRequest: state => {
            state.condition = {
                ...state.condition,
                inProgress: true,
            };
        },
        onNoResponse: state => {
            state.condition = {
                ...state.condition,
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "Данные отсутствуют",
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        onResponseFail: (
            state,
            action: PayloadAction<{ data: any; errors: any; message: string; result: boolean }>,
        ) => {
            state.condition = {
                ...state.condition,
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: action.payload.message,
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        onResponseSuccess: (
            state,
            action: PayloadAction<{ data: any; errors: any; message: string; result: boolean; apiAction: any }>,
        ) => {
            state.condition = {
                ...state.condition,
                inProgress: false,
                response: {
                    result: action.payload.result,
                    data: action.payload.data,
                    message: action.payload.message,
                    errors: null,
                },
                notice: {
                    severity: "success",
                    visibility: true,
                },
            };
            state.data = {
                ...state.data,
                [action.payload.apiAction]: action.payload.data,
            };
        },
        onNoticeHide: state => {
            state.condition.notice.visibility = false;
        },
    },
});

export const {
    onConditionClear,
    onRequest,
    onNoResponse,
    onResponseSuccess,
    onResponseFail,
    onNoticeHide,
} = slicerAdminDiagram.actions;

export const asyncAdminDiagramData = (apiAction: string, params?: any, callback?: (result: any) => void) => async (
    dispatch: any,
) => {
    dispatch(onRequest());
    let result = await api[apiAction](params).catch((e: any) => {
        console.log(e);
        dispatch(onNoResponse());
    });
    if (result) {
        result.apiAction = apiAction;
        dispatch(result.result ? onResponseSuccess(result) : onResponseFail(result));
    } else {
        dispatch(onNoResponse());
    }
    if (callback) {
        callback(result);
    }
};

export const reselectAdminDiagramCondition = (state: RootState): Response => {
    return state.adminDiagram.condition;
};
export const reselectAdminDiagramData = (state: RootState): any => {
    return state.adminDiagram.data;
};

export default slicerAdminDiagram.reducer;
