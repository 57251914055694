import React from "react";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

const TgButton = ({ ...props }) => {
    return (
        <img
            src="/static/social/tg_icon.svg"
            className={props.classes.socialIcon}
            onClick={() => {
                props.onClick("vk");
            }}
        />
    );
};

export default TgButton;
