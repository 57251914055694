import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { Color } from "@material-ui/lab/Alert";
import { Speaker } from "controllers/admin-speaker/interfaces/admin-speaker";
import {
    apiSpeakerCreate,
    apiSpeakerUpdate,
} from "controllers/admin-speaker/admin-speaker-post/api/admin-speaker-post";
import { LangEnum } from "controllers/admin-speaker/enums";

type Response = {
    inProgress: boolean;
    response: {
        result: boolean;
        errors: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};
export type Condition = {
    ru: Response;
    en: Response;
    cn: Response;
};

export const initialCondition: Response = {
    inProgress: false,
    notice: {
        visibility: false,
        severity: "error",
    },
    response: {
        result: false,
        errors: null,
        message: "",
    },
};

type InitialState = {
    view: Speaker;
    modalIsOpened: boolean;
    mode: "create" | "edit";
    condition: Condition;
};

const initialSpeaker: Speaker = {
    academic_degree: {
        ru: "",
        en: "",
        cn: "",
    },
    photo: {
        ru: null,
        en: null,
        cn: null,
    },
    city: {
        ru: "",
        en: "",
        cn: "",
    },
    forum_themes: {
        ru: [],
        en: [],
        cn: [],
    },
    country: {
        ru: "",
        en: "",
        cn: "",
    },
    lastname: {
        ru: "",
        en: "",
        cn: "",
    },
    name: {
        ru: "",
        en: "",
        cn: "",
    },
    place_of_work: {
        ru: "",
        en: "",
        cn: "",
    },
    report: {
        ru: "",
        en: "",
        cn: "",
    },
    surname: {
        ru: "",
        en: "",
        cn: "",
    },
    theses: {
        ru: "",
        en: "",
        cn: "",
    },
    work_position: {
        ru: "",
        en: "",
        cn: "",
    },
};

const initialState: InitialState = {
    view: initialSpeaker,
    modalIsOpened: false,
    mode: "create",
    condition: {
        ru: initialCondition,
        en: initialCondition,
        cn: initialCondition,
    },
};

export const slicerNewSpeaker = createSlice({
    name: "newSpeaker",
    initialState,
    reducers: {
        onConditionClear: (state, action: PayloadAction<{ lang: LangEnum }>) => {
            (state.condition as Condition)[action.payload.lang] = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    errors: null,
                    message: "",
                },
            };
        },
        onNoticeHide: (state, action: PayloadAction<{ lang: LangEnum }>) => {
            (state.condition as Condition)[action.payload.lang].notice.visibility = false;
        },
        onRequest: (state, action: PayloadAction<{ lang: LangEnum }>) => {
            console.log("onRequest");
            (state.condition as Condition)[action.payload.lang] = {
                inProgress: true,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    errors: null,
                    message: "",
                },
            };
        },
        onResponseFail: (state, action: PayloadAction<{ result: any; lang: LangEnum }>) => {
            const response =
                typeof action.payload.result === "string" ? JSON.parse(action.payload.result) : action.payload.result;
            const lang = action.payload.lang;

            (state.condition as Condition)[lang] = {
                inProgress: false,
                response: {
                    result: false,
                    errors: action.payload.result?.errors,
                    message: (response ? response.message : null) || action?.payload?.result?.error?.message || "error",
                },
                notice: {
                    visibility: true,
                    severity: "error",
                },
            };
        },
        onResponseSuccess: (
            state,
            action: PayloadAction<{
                result: any;
                lang: LangEnum;
            }>,
        ) => {
            const response =
                typeof action.payload.result === "string" ? JSON.parse(action.payload.result) : action.payload.result;
            (state.condition as Condition)[action.payload.lang] = {
                inProgress: false,
                response: {
                    result: action.payload.result,
                    errors: null,
                    message: response ? response.message : "",
                },
                notice: {
                    visibility: true,
                    severity: "success",
                },
            };
            state.view.id = action.payload.result.data.id;
        },
        onChange: (state, action) => {
            console.log("action", action);

            let ref;
            const nameRoute = action.payload.name.split(".");
            const fieldName = nameRoute.pop();
            ref = nameRoute.reduce((acc: any, item: string) => {
                // @ts-ignore
                return acc[item];
            }, state.view);
            if (action.payload.hasOwnProperty("checked")) {
                debugger;
                ref[fieldName] = action.payload.checked;
            } else if (action.payload.value.hasOwnProperty("value") && action.payload.value.hasOwnProperty("name")) {
                ref[fieldName] = action.payload.value.name;
            } else {
                ref[fieldName] = action.payload.value;
            }

            // ref = { ...ref, [fieldName]: action.payload.value };
        },
        onSetInitialSpeaker: (state, action: PayloadAction<Speaker>) => {
            state.view = action.payload;
        },
        onResetSpeakerFormView: state => {
            state.view = initialSpeaker;
        },
        onSetNewSpeakerModalIsOpened: (state, action: PayloadAction<boolean>) => {
            state.modalIsOpened = action.payload;
        },
        onSetSpeakerFormMode: (state, action: PayloadAction<"create" | "edit">) => {
            state.mode = action.payload;
        },
        onFinish: (state, action: PayloadAction<Speaker>) => {
            state.modalIsOpened = false;
            state.view = initialSpeaker;
        },
    },
});

export const {
    onNoticeHide,
    onConditionClear,
    onRequest,
    onResponseSuccess,
    onResponseFail,
    onChange,
    onSetInitialSpeaker,
    onSetNewSpeakerModalIsOpened,
    onSetSpeakerFormMode,
    onFinish,
    onResetSpeakerFormView,
} = slicerNewSpeaker.actions;

export const asyncCreateSpeaker = (callback?: (result: any) => void) => async (
    dispatch: any,
    getState: () => RootState,
) => {
    dispatch(onRequest({ lang: LangEnum.RU }));
    const view = getState().adminSpeaker.new.view;
    let result = await apiSpeakerCreate(view).catch(e => console.error(e));
    if (result) {
        if (result.result) {
            dispatch(onResponseSuccess({ result: result, lang: LangEnum.RU }));
        } else {
            dispatch(onResponseFail({ result: result, lang: LangEnum.RU }));
        }
    } else {
        dispatch(onResponseFail({ result: result, lang: LangEnum.RU }));
    }
    if (callback) {
        callback(result);
    }
};

export const asyncUpdateSpeaker = (lang: LangEnum, callback?: (result: any) => void) => async (
    dispatch: any,
    getState: () => RootState,
) => {
    dispatch(onRequest({ lang: lang }));
    const view = getState().adminSpeaker.new.view;

    let result = await apiSpeakerUpdate(view, lang).catch(e => console.error(e));

    if (result) {
        if (result.result) {
            dispatch(onResponseSuccess({ result: result, lang: lang }));
        } else {
            dispatch(onResponseFail({ result: result, lang: lang }));
        }
    } else {
        dispatch(onResponseFail({ result: result, lang: lang }));
    }
    if (callback) {
        callback(result);
    }
};

export const reselectSpeakerNew = (state: RootState) => {
    return state.adminSpeaker.new.view;
};
export const reselectSpeakerNewCondition = (state: RootState) => {
    return state.adminSpeaker.new.condition;
};
export const reselectSpeakerNewModalIsOpened = (state: RootState) => {
    return state.adminSpeaker.new.modalIsOpened;
};
export const reselectSpeakerNewFormMode = (state: RootState) => {
    return state.adminSpeaker.new.mode;
};

export default slicerNewSpeaker.reducer;
