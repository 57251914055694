import React, { ReactNode } from "react";
import clsx from "clsx";
import PropTypes, { InferProps } from "prop-types";
import classes from "./CommonContainer.module.css";

interface Props {
    children: ReactNode;
    className?: string;
}

export default function CommonContainer({ className, children }: Props) {
    return <div className={clsx(classes.commonContainer, className || "")}>{children}</div>;
}
