import { Color } from "@material-ui/lab/Alert";
import { RootState } from "../redux-store";
export type KdwCondition = {
    inProgress: boolean;
    errors: any;
    response: {
        result: boolean;
        data: any;
        error: {
            code: number;
            message: string;
        };
    };
    nativeResponse: {
        ok: boolean;
        redirected: boolean;
        status: number;
        statusText: string;
        type: string;
        url: string;
        body?: any;
    };
    notice: {
        visibility: boolean;
        severity: Color;
        message: string;
    };
};
export const makeClearCondition = (props: any = {} as any) =>
    ({
        inProgress: props.inProgress || false,
        errors: (props.errors || {}) as any,
        response: {
            paginator: props.paginator || (null as any),
            result: props.result ? props.result : false,
            data: (props.data || null) as any,
            error: {
                code: props.code || 200,
                message: props.message || (null as string),
            },
        },
        nativeResponse: props.nativeResponse || (null as any),
        notice: {
            visibility: props.visibility || false,
            severity: props.severity || "success",
            message: props.notice || props.message || "",
        },
    } as KdwCondition);

export type CallType = (dispatch: any) => void;
export const makeBaseReduxAction = (before: CallType, fail: (d: any, r?: any) => void, after: CallType) => {
    return (api: any, params?: any, callback?: (result: any) => void, then?: (dispatch: any, result: any) => void) =>
        (dispatch: any, getState: () => RootState) => {
            before(dispatch);
            let error = null;
            api(params)
                .then((r: any) => {
                    let response = r?.response || r;
                    if (response.result) then(dispatch, r);
                    else fail(dispatch, r);
                    if (callback) callback(response);
                })
                .catch((e: any) => {
                    console.log(e);
                    fail(dispatch);
                    error = e;
                    if (callback) callback({result:false, error});
                })
                .finally(() => {
                    after(dispatch);
                });
        };
};

export const makeConditionReducers = () => ({
    onNoticeHide: (state: any) => {
        state.condition = {
            ...state.condition,
            notice: { ...state.condition.notice, visibility: false },
        };
    },
    onRequest: (state: any) => {
        state.condition = {
            ...state.condition,
            inProgress: true,
        };
    },
    stopLoading: (state: any) => {
        state.condition = {
            ...state.condition,
            inProgress: false,
        };
    },
    clearCondition: (state: any) => {
        state.condition = makeClearCondition();
    },
    onFail: (state: any, action: any) => {
        const payload = action.payload;
        payload.notice.visibility = true;
        state.condition = {
            ...payload,
        };
    },
});
