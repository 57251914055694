import { combineReducers } from "@reduxjs/toolkit";
import adminSpeakerNewReducer from "./admin-speaker-post/redux-store/admin-speaker-post/admin-speaker-post";
import adminSpeakerIndexReducer from "./admin-speaker-get-list/redux-store/admin-speaker-get-list/admin-speaker-get-list";
import adminSpeakerDeleteReducer from "./admin-speaker-delete/redux-store/admin-speaker-delete/admin-speaker-delete";

export const AdminSpeakerStore = combineReducers({
    new: adminSpeakerNewReducer,
    index: adminSpeakerIndexReducer,
    delete: adminSpeakerDeleteReducer,
});
