import ky from "ky";
import __locale from "helper/location/location";
import { Color } from "@material-ui/lab/Alert";
import {makeClearCondition} from "helper/redux";
export const apiLog = (log:any) => {
    if (localStorage.getItem("showlog") != '1') return;
    console.log(log)
};

export type Type_BodyResponse<T = any> = {
    data?: T;
    result?: boolean;
    message: string;
    error?: string;
    errors?: any[];
};

type Type_BodyResponse_404 = {
    message: string;
    resource: string;
};
export type BaseResponseCondition = {
    inProgress: boolean;
    response: {
        result: boolean;
        data: any;
        errors: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};




export const BACKEND_HOST = process.env.REACT_APP_DEVELOPMENT_API_PATH;
export const STREAM_API_KEY = "mrm9dcjkqy6u";
export const TINYMCE_KEY ='a5832gomod2hu1jrhyrvd3e7j6w1t8tnup3641jxm9rdk43e';
// export const BACKEND_HOST = `https://lkf.kazandigitalweek.com/api`;
// export const BACKEND_GEOLOCATION_HOST = `http://geo.namisoft.ru`;
export const BACKEND_GEOLOCATION_HOST = `${process.env.REACT_APP_DEVELOPMENT_API_PATH}/geo`;

export const API_RouteConstants = true;
export const is_Front_Content = true;

export const kyRequestBase = ky.extend({
    retry: {
        limit: 1,
    },
    timeout: 30000,
    throwHttpErrors: false,
    hooks: {
        beforeRequest: [
            request => {
                // eslint-disable-next-line no-param-reassign
                request.headers.set("Accept", "application/json");
            },
        ],
    },
});

export const kyRequest = kyRequestBase.extend({
    hooks: {
        beforeRequest: [
            request => {
                // eslint-disable-next-line no-param-reassign
                request.headers.set("Accept-Language", __locale());
            },
        ],
    },
});
export const prepareResponse = async (response: any) => {
    if (!response || response instanceof Error)  return await responseCallback(response);
    const body = await response.text();
    let json = {} as any;
    let condParams = {} as any;
    try {
        json = JSON.parse(body);
        condParams.message = json.message || "";
    } catch (e) {
        apiLog(e);
        condParams.message = 'При обработке запроса произошла ошибка, попробуйте еще раз';
    }
    condParams.nativeResponse = {...response, body: json || body};
    condParams.code = response.status;
    condParams.inProgess = false;
    let p = {} as any;
    if (json.hasOwnProperty('last_page')) Object.keys(json)
        .filter(v => v != "data")
        .forEach(v => {
            p[v] = json[v];
        });
    switch (response.status) {
        case 200:
        case 201:
            condParams.paginator = p.hasOwnProperty('last_page') ? p : (null as any);
            condParams.data = json.data || null;
            condParams.result = json.result !== undefined ? json.result : true;
            condParams.severity = "success";
            break;
        case 400:
        case 422:
            condParams.result = json.result || false;
            condParams.data = [];
            condParams.errors = json.errors;
            condParams.severity = "error";
            break;
        default:
            condParams.severity = "error";
            condParams.message = json.error || json.message;
    }
    return  makeClearCondition(condParams);
};









/** @deprecated*/
export const responseCallback = async (response: any, isText = false, is = false) => {
    if (!response) {
        return {
            data: null,
            result: false,
            error: {
                message: "Ошибка запроса",
                code: 0,
            },
        };
    }

    if (response instanceof Error) {
        return {
            data: null,
            message: `${response.name}: ${response.message}`,
            error: {
                code: 0,
                message: "",
            },
            result: false,
        };
    }
    switch (response.status) {
        case 200: {
            if (is === false) {
                return isText ? response.text() : response.json();
            }
            const responseBody: Type_BodyResponse<{
                html: string;
                order: {
                    id: number;
                    amount: string;
                    is_success: boolean;
                    report_id: number;
                    created_at: number;
                    updated_at: number;
                    tries: number;
                };
            }> = await response.json();
            return {
                data: responseBody.data,
                result: responseBody.result,
                error: {
                    message: responseBody.message,
                    code: response.status,
                },
                body: responseBody,
                nativeResponse: {
                    ok: response.ok,
                    redirected: response.ok,
                    status: response.status,
                    statusText: response.statusText,
                    type: response.type,
                    url: response.url,
                },
            };
        }
        case 422:
        case 400: {
            if (is === false) {
                return isText ? response.text() : response.json();
            }
            const responseBody: Type_BodyResponse | any = await response.json();
            return {
                data: responseBody.errors,
                result: false,
                error: {
                    message: responseBody.message,
                    code: response.status,
                },
                nativeResponse: {
                    ok: response.ok,
                    redirected: response.ok,
                    status: response.status,
                    statusText: response.statusText,
                    type: response.type,
                    url: response.url,
                },
            };
        }
        case 401: {
            const responseBody: string = await response.text();
            return {
                data: null,
                result: false,
                error: {
                    message: responseBody,
                    code: 401,
                },
                nativeResponse: {
                    ok: response.ok,
                    redirected: response.ok,
                    status: response.status,
                    statusText: response.statusText,
                    type: response.type,
                    url: response.url,
                },
            };
        }
        case 406: {
            const responseBody: Type_BodyResponse = await response.json();
            return {
                data: null,
                result: false,
                error: {
                    message: responseBody.message || responseBody.error,
                    code: 406,
                },
                nativeResponse: {
                    ok: response.ok,
                    redirected: response.ok,
                    status: response.status,
                    statusText: response.statusText,
                    type: response.type,
                    url: response.url,
                },
            };
        }
        case 429: {
            const responseBody: Type_BodyResponse = await response.json();
            // type Trace = {
            //     class: string;
            //     file: string;
            //     function: string;
            //     line: number;
            //     type: string;
            // };
            return {
                data: null,
                result: false,
                error: {
                    message: responseBody.message,
                    code: response.status,
                },
                nativeResponse: {
                    ok: response.ok,
                    redirected: response.ok,
                    status: response.status,
                    statusText: response.statusText,
                    type: response.type,
                    url: response.url,
                },
            };
            break;
        }
        case 500: {
            let message = null;
            let json = {} as any;
            try {
                json = await response.json();
                message = json.error || json.message;
            } catch (e) {}
            if (response.statusText) {
                return {
                    data: null,
                    result: false,
                    error: {
                        message: message || response.statusText,
                        code: response.status,
                    },
                    nativeResponse: {
                        ok: response.ok,
                        redirected: response.ok,
                        status: response.status,
                        statusText: response.statusText,
                        type: response.type,
                        url: response.url,
                    },
                };
            }
            return isText ? response.text() : response.json();
        }
        case 404: {
            const responseBody: Type_BodyResponse_404 = await response.json();
            if (response.statusText) {
                return {
                    data: null,
                    result: false,
                    message: response.statusText,
                    error: {
                        code: response.status,
                        message: responseBody.message,
                    },
                    nativeResponse: {
                        ok: response.ok,
                        redirected: response.ok,
                        status: response.status,
                        statusText: response.statusText,
                        type: response.type,
                        url: response.url,
                    },
                };
            }
            return isText ? response.text() : response.json();
        }
        default: {
            return isText ? response.text() : response.json();
        }
    }
};
