import { kyRequest, BACKEND_HOST, responseCallback } from "api/api";
import { User } from "controllers/admin-users/api/admin-users/admin-users";

/**
 * @route api/user
 * @delete
 * @post
 */
export const apiUserDelete = async (userIndex: User["id"]) => {
    const response = await kyRequest.delete(`${BACKEND_HOST}/user/${userIndex}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return responseCallback(response);
};
