import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { apiPayInvoice } from "controllers/invoice-route/api/invoice/invoice";
import { BaseResponseCondition } from "../../../../api/api";

export const slicerPayInvoice = createSlice({
    name: "reset-password",
    initialState: {
        condition: {
            inProgress: false,
            response: {
                result: false,
                data: null as any,
                message: null as any,
                errors: null as any,
            },
            notice: {
                visibility: false,
                severity: "error",
            },
        } as BaseResponseCondition,
    },
    reducers: {
        onClear: state => {
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    message: null,
                    errors: null,
                },
                notice: {
                    visibility: false,
                    severity: "error",
                },
            };
        },
        onRequest: state => {
            state.condition.inProgress = true;
        },
        onResponse: (state, action) => {
            state.condition = {
                inProgress: false,
                response: {
                    result: true,
                    data: null,
                    message: action.payload.message,
                    errors: null,
                },
                notice: {
                    visibility: false,
                    severity: "error",
                },
            };
        },
        onResponseFail: (state, action) => {
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: action.payload
                    ? {
                          result: false,
                          data: null,
                          message: null,
                          errors: action.payload.errors,
                      }
                    : {
                          result: false,
                          data: null,
                          message: null,
                          errors: null,
                      },
            };
        },
        onNoResponse: state => {
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    errors: null,
                    message: null,
                    result: false,
                    data: null,
                },
            };
        },
        onResponseSuccess: (state, action: PayloadAction<any>) => {
            state.condition = {
                notice: {
                    visibility: true,
                    severity: "success",
                },
                inProgress: false,
                response: {
                    result: true,
                    data: null,
                    message: action.payload.message,
                    errors: null,
                },
            };
        },
    },
});

export const {
    onClear,
    onRequest,
    onResponse,
    onResponseSuccess,
    onResponseFail,
    onNoResponse,
} = slicerPayInvoice.actions;

export const asyncPayInvoice = (
    invoiceIndex: string | number,
    report_id?: any,
    data?: any,
    callback?: (result: any) => void,
) => async (dispatch: any) => {
    dispatch(onRequest());
    const result = await apiPayInvoice(invoiceIndex, report_id, data).catch(e => console.error(e));
    if (result) {
        if (result.result) {
            dispatch(onResponseSuccess(result));
        } else {
            dispatch(onResponseFail(result));
        }
    } else {
        dispatch(onNoResponse());
    }
    if (callback) {
        callback(result);
    }
};

export const reselectPayInvoiceCondition = (state: RootState) => {
    return state.payInvoice.condition;
};

export default slicerPayInvoice.reducer;
