import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { Color } from "@material-ui/lab/Alert";
import {getUserArticlesApi, postUserArticlesApi} from "./api";

export type Response = {
    inProgress: boolean;
    response: {
        result: boolean;
        data: any;
        errors: any;
        error: {
            message: string;
            code: number;
        };
        message: string;
        status?: number;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};


export const ARTICLE_ARTICLE_TYPE = 0;
export const ARTICLE_REPORT_TYPE = 1;

export const slicer_User_Speaker_Report = createSlice({
    name: "speaker-report",
    initialState: {
        view: {
            order: null as any,
            html: "",
        },
        condition: {
            inProgress: false,
            notice: {
                visibility: false,
                severity: "error",
            },
            response: {
                result: false,
                data: null,
                errors: null,
                error: {
                    code: 0,
                    message: "empty",
                },
                message: "",
            },
        } as Response,
    },
    reducers: {
        on_Clean_Condition: state => {
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    error: {
                        code: 0,
                        message: "",
                    },
                    errors: null,
                    message: "",
                },
            };
        },
        on_Request: state => {
            state.condition = {
                inProgress: true,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    error: {
                        code: 0,
                        message: "",
                    },
                    message: "",
                },
                notice: {
                    severity: "error",
                    visibility: false,
                },
            };
        },
        on_No_Response: state => {
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    error: {
                        code: 0,
                        message: "",
                    },
                    message: "",
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        on_Response_status_200: (
            state,
            action: PayloadAction<{
                data: {
                    html: string;
                    order: {
                        id: number;
                        amount: string;
                        is_success: boolean;
                        report_id: number;
                        created_at: number;
                        updated_at: number;
                        tries: number;
                    };
                };
                result: boolean;
                error: {
                    message: string;
                    code: number;
                };
                nativeResponse: {
                    ok: boolean;
                    redirected: boolean;
                    status: number;
                    statusText: string;
                    type: string;
                    url: string;
                };
            }>,
        ) => {
            state.condition.inProgress = false;
            state.condition.notice = {
                visibility: false,
                severity: "success",
            };
            state.condition.response = {
                result: action.payload.result,
                data: action.payload.data,
                errors: null,
                error: {
                    code: action.payload?.error?.code || 200,
                    message: action.payload?.error?.message,
                },
                message: action.payload?.error?.message,
            };
        },
        on_Response_status_400: (
            state,
            action: PayloadAction<{
                data: null;
                result: boolean;
                error: {
                    message: string;
                    code: 400;
                };
                nativeResponse: {
                    ok: boolean;
                    redirected: boolean;
                    status: number;
                    statusText: string;
                    type: string;
                    url: string;
                };
            }>,
        ) => {
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: action.payload.result,
                    data: action.payload.data,
                    errors: null,
                    error: {
                        code: action.payload.error.code,
                        message: action.payload.error.message,
                    },
                    message: action.payload.error.message,
                },
            };
        },
        on_Response_status_401: (
            state,
            action: PayloadAction<{
                data: null;
                result: boolean;
                error: {
                    message: string;
                    code: number;
                };
                nativeResponse: {
                    ok: boolean;
                    redirected: boolean;
                    status: number;
                    statusText: string;
                    type: string;
                    url: string;
                };
            }>,
        ) => {
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: action.payload.result,
                    data: action.payload.data,
                    errors: null,
                    error: {
                        code: action.payload.error.code,
                        message: action.payload.error.message,
                    },
                    message: action.payload.error.message,
                },
            };
        },
        on_Response_status_429: (
            state,
            action: PayloadAction<{
                data: null;
                result: boolean;
                error: {
                    message: string;
                    code: number;
                };
                nativeResponse: {
                    ok: boolean;
                    redirected: boolean;
                    status: number;
                    statusText: string;
                    type: string;
                    url: string;
                };
            }>,
        ) => {
            state.condition.inProgress = false;
            state.condition.notice = {
                visibility: false,
                severity: "error",
            };
            state.condition.response = {
                result: action.payload.result,
                data: action.payload.data,
                errors: null,
                error: {
                    code: action.payload.error.code,
                    message: action.payload.error.message,
                },
                message: action.payload.error.message,
            };
        },
        on_Response_status_406: (
            state,
            action: PayloadAction<{
                data: null;
                result: boolean;
                error: {
                    message: string;
                    code: number;
                };
                nativeResponse: {
                    ok: boolean;
                    redirected: boolean;
                    status: number;
                    statusText: string;
                    type: string;
                    url: string;
                };
            }>,
        ) => {
            state.condition.inProgress = false;
            state.condition.notice = {
                visibility: false,
                severity: "error",
            };
            state.condition.response = {
                result: false,
                data: null,
                errors: null,
                error: {
                    message: action.payload.error.message,
                    code: action.payload.error.code,
                },
                message: action.payload.error.message,
            };
        },
        on_Response_status_404: (
            state,
            action: PayloadAction<{
                data: null;
                result: boolean;
                error: {
                    message: string;
                    code: number;
                };
                message: string;
                nativeResponse: {
                    ok: boolean;
                    redirected: boolean;
                    status: number;
                    statusText: string;
                    type: string;
                    url: string;
                };
            }>,
        ) => {
            state.condition.inProgress = false;
            state.condition.notice = {
                visibility: false,
                severity: "warning",
            };
            state.condition.response = {
                result: false,
                data: null,
                errors: null,
                error: {
                    message: action.payload.error.message,
                    code: action.payload.error.code,
                },
                message: action.payload.error.message,
            };
        },
        on_Response_status_500: (
            state,
            action: PayloadAction<{
                data: null;
                result: boolean;
                error: {
                    message: string;
                    code: number;
                };
                nativeResponse: {
                    ok: boolean;
                    redirected: boolean;
                    status: number;
                    statusText: string;
                    type: string;
                    url: string;
                };
            }>,
        ) => {
            state.condition.inProgress = false;
            state.condition.response = {
                result: false,
                data: null,
                errors: null,
                error: {
                    message: action.payload.error.message,
                    code: action.payload.error.code,
                },
                message: action.payload.error.message,
            };
            state.condition.notice = {
                severity: "error",
                visibility: false,
            };
        },
        on_Response_Fail: (state, action: PayloadAction<string>) => {
            state.condition.inProgress = false;
            if (typeof action.payload === "string") {
                state.condition.response = {
                    result: false,
                    data: null,
                    errors: null,
                    error: {
                        code: 0,
                        message: "",
                    },
                    message: action.payload,
                };
                state.condition.notice = {
                    severity: "error",
                    visibility: false,
                };
            }
        },
        on_Report_Success: (state, action: PayloadAction<Type_Speaker_GET_Report_success>) => {
            state.view = action.payload.data;
            state.condition = {
                inProgress: false,
                response: {
                    result: action.payload.result,
                    data: null,
                    message: action.payload.message,
                    errors: null,
                    error: {
                        code: 200,
                        message: "success",
                    },
                },
                notice: {
                    severity: "success",
                    visibility: true,
                },
            };
        },
        on_Notice_hide: state => {
            state.condition.notice.visibility = false;
        },
        onNoticeHide: state => {
            state.condition.notice.visibility = false;
        },
    },
});

export const {
    on_Request,
    on_No_Response,
    on_Report_Success,
    on_Response_Fail,
    on_Response_status_200,
    on_Response_status_400,
    on_Response_status_401,
    on_Response_status_404,
    on_Response_status_406,
    on_Response_status_429,
    on_Response_status_500,
    onNoticeHide,
    on_Notice_hide,
} = slicer_User_Speaker_Report.actions;

type Type_Speaker_GET_Report_success = {
    message: "string";
    result: boolean;
    data: {
        order: {
            id: number;
            amount: string;
            is_success: boolean;
            report_id: number;
            created_at: number;
            updated_at: number;
            tries: 2;
        };
        html: string;
    };
};
type Type__Speaker_GET_Report_failure = {
    data: null;
    error: {
        code: 500;
        message: string;
    };
    success: false;
};
export const async_Speaker_GET_Articles = (report: number, filter?: any, callback?: (result: any) => void) => async (
    dispatch: any,
) => {
    dispatch(on_Request());
    const result = await getUserArticlesApi(report, filter).catch(e => {
        console.log(e);
    });
    if (result) {
        if (!result.result) {
            switch (result.error?.code) {
                case 400: {
                    dispatch(on_Response_status_400(result));
                    break;
                }
                case 401: {
                    dispatch(on_Response_status_401(result));
                    break;
                }
                case 404: {
                    dispatch(on_Response_status_404(result));
                    break;
                }
                case 406: {
                    dispatch(on_Response_status_406(result));
                    break;
                }
                case 429: {
                    dispatch(on_Response_status_429(result));
                    break;
                }
                case 500: {
                    dispatch(on_Response_status_500(result));
                    break;
                }
            }
        } else {
            if (result.result) {
                dispatch(on_Response_status_200(result));
            } else {
                dispatch(result.result ? on_Report_Success(result) : on_Response_Fail(result));
            }
        }
    } else {
        dispatch(on_No_Response());
    }
    if (callback) {
        callback(result);
    }
};
export const asyncSaveSpeakerArticle = (report: any, callback?: (result: any) => void) => async (
    dispatch: any,
) => {
    dispatch(on_Request());
    const result = await postUserArticlesApi(report).catch(e => {
        console.log(e);
    });
    if (result) {
        if (result.result) {
            dispatch(on_Report_Success(result));
        } else {
            dispatch(on_Response_Fail(result));
        }
    } else {
        dispatch(on_No_Response());
    }
    if (callback) {
        callback(result);
    }
};

export const reselect_Speaker_GET_Report_entity = (state: RootState) => {
    return state.speaker.GET_report.view;
};

export const reselect_Speaker_GET_Report_condition = (state: RootState) => {
    return state.speaker.GET_report.condition;
};

export default slicer_User_Speaker_Report.reducer;
