import { kyRequest, BACKEND_GEOLOCATION_HOST, responseCallback } from "api/api";
export type GeoNamesAdmin1 = {
    name: string;
    code: string;
};

export const apiGeolocationCities = async (cityName: string, countryIndex: string, regionIndex?: string) => {
    const response = await kyRequest.get(`${BACKEND_GEOLOCATION_HOST}/cities?q=${cityName}&country_id=${countryIndex}`);
    return responseCallback(response);
};
