import React from "react";
import { InferProps } from "prop-types";
import __locale from "../../../helper/location/location";
import { NavLink } from "react-router-dom";

type Props = {
    className: string;
    link: string;
    children: any;
    blank: boolean;
    linkRef?: any;
};

export default function LinkWrapper({ className, link, blank, children, linkRef }: InferProps<Props>) {
    // const history = useHistory();
    let full = link && (link.indexOf("http") === 0 || link.indexOf("upload") !== -1 || link.indexOf("mailto") === 0);
    if (link && !full) {
        link = ("/" + __locale() + "/" + link).replace("//", "/");
    }
    // const onClick = () => {
    //     if (link.indexOf('http') === 0) window.open(link, '_blank');
    //     else history.push(link);
    //     console.log(history)
    // }
    return link ? (
        full ? (
            <a className={className} href={link} target="_blank" rel="noreferrer" ref={linkRef}>
                {children}
            </a>
        ) : (
            <NavLink
                to={link}
                className={className}
                target={blank && "_blank"}
                ref={linkRef}
                // onClick={onClick}
            >
                {children}
            </NavLink>
        )
    ) : (
        <span className={className}>{children}</span>
    );
}
