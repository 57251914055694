import {createSlice, Dispatch, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "redux-store/index";
import {Color} from "@material-ui/lab/Alert";
import {isFunction} from "lodash";
import {api_UserEvent_DELETE} from "controllers/event-controller/api/user-event-delete/user-event-delete";

export type Condition = {
    inProgress: boolean;
    errors: any;
    response: {
        result: boolean;
        data: any;
        error: {
            code: number;
            message: string;
        };
    };
    nativeResponse: {
        ok: boolean;
        redirected: boolean;
        status: number;
        statusText: string;
        type: string;
        url: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};

export const slicer_UserEvent_DELETE = createSlice({
    name: "user-event-delete",
    initialState: {
        condition: {
            inProgress: false,
            notice: {
                visibility: false,
                severity: "error",
            },
            response: {
                result: false,
                data: null,
                error: {
                    code: 0,
                    message: "",
                },
            },
            nativeResponse: null,
        } as Condition,
    },
    reducers: {
        on_Clean_PostUserEvent_DELETE: state => {
            state.condition = {
                inProgress: false,
                errors: {},
                notice: {
                    visibility: false,
                    severity: "success",
                },
                response: {
                    result: false,
                    data: null,
                    error: {
                        code: 0,
                        message: "",
                    },
                },
                nativeResponse: null,
            };
        },
        on_CleanCondition_PostUserEvent_DELETE: (state, action: PayloadAction<{ eventIndex: number }>) => {
            state.condition = ({
                inProgress: false,
                errors: {},
                notice: {
                    visibility: false,
                    severity: "success",
                },
                response: {
                    result: false,
                    data: null,
                    error: {
                        code: 0,
                        message: "",
                    },
                },
                nativeResponse: null,
            });
        },
        on_Request_PostUserEvent_DELETE: (state, action: PayloadAction<{ eventIndex: number }>) => {
                state.condition = {
                    inProgress: true,
                    errors: {},
                    notice: {
                        visibility: false,
                        severity: "success",
                    },
                    response: {
                        result: false,
                        data: null,
                        error: {
                            code: 0,
                            message: "",
                        },
                    },
                    nativeResponse: null,
                };
        },
        on_NoResponse_PostUserEvent_DELETE: (state, action: PayloadAction<{ eventIndex: number }>) => {
                state.condition = {
                    inProgress: false,
                    errors: {},
                    response: {
                        result: false,
                        data: null,
                        error: {
                            code: 0,
                            message: "",
                        },
                    },
                    notice: {
                        severity: "error",
                        visibility: true,
                    },
                    nativeResponse: null,
                };
        },
        on_Response_Fail_PostUserEvent_DELETE: (
            state,
            action: PayloadAction<{ eventIndex: number; response: any }>,
        ) => {
                state.condition.inProgress = false;
        },
        on_Response_Success_PostUserEvent_DELETE: (
            state,
            action: PayloadAction<{ eventIndex: number; response: any }>,
        ) => {
                state.condition = {
                    inProgress: false,
                    nativeResponse: null,
                    errors: {},
                    response: {
                        result: action.payload.response,
                        data: null,
                        error: {
                            code: 0,
                            message: "",
                        },
                    },
                    notice: {
                        severity: "success",
                        visibility: true,
                    },
                };
        },
        on_Notice_Hide_PostUserEvent_DELETE: (state, action: PayloadAction<{ eventIndex: number }>) => {
            state.condition.notice.visibility = false;
        },
    },
});

export const {on_Clean_PostUserEvent_DELETE, on_Notice_Hide_PostUserEvent_DELETE} = slicer_UserEvent_DELETE.actions;

export const async_UserEvents_DELETE = (eventIndex: number, callback?: (result: any) => void) => async (
    reduxDispanch: Dispatch,
    getState: () => RootState,
) => {
    const {
        on_Request_PostUserEvent_DELETE,
        on_Response_Success_PostUserEvent_DELETE,
        on_Response_Fail_PostUserEvent_DELETE,
        on_NoResponse_PostUserEvent_DELETE,
    } = slicer_UserEvent_DELETE.actions;
    //
    reduxDispanch(on_Request_PostUserEvent_DELETE({eventIndex}));
    const result = await api_UserEvent_DELETE(eventIndex).catch(e => {
        reduxDispanch(on_NoResponse_PostUserEvent_DELETE({eventIndex}));
    });
    if (result) {
        reduxDispanch(
            result.result
                ? on_Response_Success_PostUserEvent_DELETE({eventIndex, response: result})
                : on_Response_Fail_PostUserEvent_DELETE({eventIndex, response: result}),
        );
    } else {
        reduxDispanch(on_NoResponse_PostUserEvent_DELETE({eventIndex}));
    }
    if (callback && isFunction(callback)) {
        callback(result);
    }
};

export const reselect_UserEvents_DELETE_condition = (state: RootState) => {
    return state.UserEvents_DELETE.condition;
};


export default slicer_UserEvent_DELETE.reducer;
