import { Shadows } from "@material-ui/core/styles/shadows";

function createShadow(px: number) {
    return `0 0 ${px}px 0 rgba(53,64,82,.05)`;
}

const shadows: Shadows = [
    "none",
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
];

export default shadows;
