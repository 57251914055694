import React from "react";
import {Button, makeStyles} from "@material-ui/core";
import Loader from "../Loader/Loader";

type Props = {
    onClick: () => void;
    label: string;
    variant?:  'text' | 'outlined' | 'contained';
    className?: string;
    disabled?: boolean;
    loading?: boolean;
    buttonProps?:any;
    loaderProps?:any;
    containerProps?:any;
};
export const btnStyles = makeStyles(t => ({
    contained: {
        background: "linear-gradient(82.35deg, rgba(0, 138, 113, 0.9) 0%, rgba(0, 147, 156, 0.9) 33.21%, rgba(11, 165, 157, 0.9) 66.43%, rgba(17, 189, 158, 0.9) 99.64%)",
        padding: "6px 18px",
        color: "white",
        textDecoration: "none !important",
        borderRadius: "10px",
        fontWeight: 400,
        fontSize: "20px",
        lineHeight: "22px",
        height: "45px",
    },
    outlineddiv: {
        background:"white",
        borderRadius: "10px",
        width: "max-content",
        display:"inline-block"
    },
    outlined: {
        background: "linear-gradient(82.35deg, #008A71 0%, #00939C 33.21%, #0BA59D 66.43%, #11BD9E 99.64%)",
        ["-webkit-background-clip"]: "text",
        ["-webkit-text-fill-color"]: "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
        borderRadius: "10px",
        fontWeight: 400,
        fontSize: "20px",
        lineHeight: "22px",
        height: "45px",
    }}));
const KdwGreenButton: React.FC<Props> = ({
    label,
    className = " ",
    variant = "contained",
    loading= false,
    disabled = false,
    onClick = () => {},
    ...props
}) => {
    const classes = btnStyles();
    return (
        <div className={(classes as any)[variant + 'div']}
             style={props.containerProps || {marginLeft: "10px"}}>
            <Button
                className={(classes as any)[variant] + " " + className}
                onClick={onClick}
                variant={variant}
                disabled={disabled || loading}
                color="primary"
                {...(props.buttonProps || {})}
            >{loading && <Loader size={30} {...(props.loaderProps || {style:{padding:5}})}/>}{props?.children || label}</Button>
        </div>
    );
};
export default KdwGreenButton;
