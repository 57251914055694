import React from "react";

import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    loader: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        minHeight: "100%",
    },
}));

const Loader: React.FC<any> = ({ size = 48 , ...props } ) => {
    const classes = useStyles();
    return (
        <div className={classes.loader} {...props}>
            <CircularProgress size={size} color="secondary" />
        </div>
    );
};

export default Loader;
