import React from "react";
import VkButton from "./VkButton";
// import FbButton from "./FbButton";
import { useDispatch, useSelector } from "react-redux";
import { openSocial, reselectSocialCondition } from "../../../social-auth";
import { reselectRegistrationAcceptPolicy } from "../../redux-store/registration-slicer/registration-slicer";
import MailButton from "./MailButton";
import GoogleButton from "./GoogleButton";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import __translate from "../../../../helper/locale/locale";
import KDWSnackbar from "../../../../components/KDWSnackbar/KDWSnackbar";

export const styles = makeStyles(() => ({
    socialIcon: {
        cursor: "pointer",
        width: "24px",
        height: "24px",
        margin: "2px",
    },
    centered: {
        display: "flex",
        justifyContent: "center",
    },
    left: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
    },
    end: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
    },
}));

export const OAUTH_OPENED = false;
const SocialButtons = ({ ...props }) => {
    const classes = styles();
    const dispatch = useDispatch();
    // const condition = useSelector(reselectSocialCondition);
    const accept_policy = useSelector(reselectRegistrationAcceptPolicy);
    const [socialError, setError] = React.useState(false);
    const handleOnClick = async (social: any) => {
        dispatch(
            openSocial(social, props.accept ? !!props.accept : accept_policy, response => {
                if (response?.link) window.location.href = response.link;
                else setError(true);
            }),
        );
    };
    const children = (
        <>
            {props.children ? (
                props.children
            ) : (
                <>
                    <VkButton onClick={handleOnClick} classes={classes} />
                    {/*<FbButton onClick={handleOnClick} classes={classes}/>*/}
                    <MailButton onClick={handleOnClick} classes={classes} />
                    <GoogleButton onClick={handleOnClick} classes={classes} />
                </>
            )}
        </>
    );
    if (!OAUTH_OPENED) return <></>;
    return (
        <Grid container style={props?.rootStyles || { alignItems: "center" }}>
            <KDWSnackbar
                isOpen={socialError}
                severity="error"
                handleOpen={() => setError(false)}
                message={__translate("site.social_error", "Ошибка авторизации через другой аккаунт")}
            />
            <Grid
                item
                xs={6}
                className={!props.left && classes.end}
                style={{ display: "flex", marginLeft: "5px", minWidth: "max-content" }}>
                <Typography
                    variant="subtitle1"
                    gutterBottom
                    style={{
                        display: "inline-block",
                        whiteSpace: "nowrap",
                        lineHeight: "28px",
                    }}>
                    <span>{props.title || __translate("login.with", "Войти с помощью")}: </span>
                </Typography>
                {props.left && children}
            </Grid>
            {!props.left && (
                <Grid item xs={6} className={classes.left}>
                    {children}
                </Grid>
            )}
        </Grid>
    );
};

export default SocialButtons;
