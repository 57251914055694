import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { Color } from "@material-ui/lab/Alert";
import { apiRouteChangeReportState } from "../../api/admin-user-put/change-report-state";

type Response = {
    inProgress: boolean;
    response: {
        result: boolean;
        data: any;
        errors: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};

export const slicerChangeReportState = createSlice({
    name: "change-report-state",
    initialState: {
        view: {
            state: null as number,
            amount: null as number,
        },
        condition: {
            inProgress: false,
            response: {
                result: false,
                data: null,
                message: "",
                errors: null,
            },
            notice: {
                visibility: false,
                severity: "error",
            },
        } as Response,
    },
    reducers: {
        onConditionClear: state => {
            state.condition = {
                notice: {
                    visibility: false,
                    severity: "error",
                },
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onNoResponse: state => {
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        onRequest: state => {
            state.condition = {
                inProgress: true,
                response: {
                    result: false,
                    data: null,
                    message: "",
                    errors: null,
                },
                notice: {
                    visibility: false,
                    severity: "error",
                },
            };
        },
        onResponseFail: (state, action: PayloadAction<{ message: string; errors: Record<string, Array<string>> }>) => {
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    message: action.payload.message,
                    errors: action.payload.errors,
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        onResponseSuccess: (state, action: PayloadAction<{ data: boolean; message: string; result: boolean }>) => {
            state.condition = {
                inProgress: false,
                response: {
                    result: action.payload.result,
                    data: null,
                    message: action.payload.message,
                    errors: null,
                },
                notice: {
                    severity: "success",
                    visibility: true,
                },
            };
        },
        onChangeLastReport: (state, action: PayloadAction<{ name: string; value: any }>) => {
            const { name, value } = action.payload;

            switch (name) {
                case "state":
                case "amount": {
                    state.view[name] = value;
                    break;
                }
            }
            if (state.condition.response?.errors?.[name]) {
                delete state.condition.response?.errors?.[name];
            }
        },
    },
});

export const {
    onRequest,
    onNoResponse,
    onResponseSuccess,
    onResponseFail,
    onChangeLastReport,
    onConditionClear,
} = slicerChangeReportState.actions;
/**
 * @deprecated
 * @param report
 * @param callback
 */
export const asyncReportStateChange = (report: any, callback?: (result: any) => void) => async (
    dispatch: any,
    getState: () => RootState,
) => {
    dispatch(onRequest());
    const view = getState().changeReportState.view;
    const data = { ...view, report_id: Number(report.id) };
    const result = await apiRouteChangeReportState(data).catch(e => console.error(e));
    if (result) {
        if (result.result) {
            dispatch(onResponseSuccess(result));
        } else {
            dispatch(onResponseFail(result));
        }
    } else {
        dispatch(onNoResponse());
    }
    if (callback) {
        callback(result);
    }
};

export const reselectReportState = (state: RootState) => {
    return state.changeReportState.view;
};
export const reselectReportStateCondition = (state: RootState) => {
    return state.changeReportState.condition;
};

export default slicerChangeReportState.reducer;
