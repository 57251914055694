import {TextField, Typography} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {useDispatch, useSelector} from "react-redux";
import {
    onChange as reduxOnChange,
    onNoticeHide,
    reselectEventNew,
    reselectEventNewFormMode,
    Response,
} from "controllers/admin-event/admin-event-post/redux-store/admin-event-post/admin-event-post";
import {reselectConstants} from "controllers/constants-route/redux-store/constants/constants";
import KDWSnackbar from "components/KDWSnackbar/KDWSnackbar";
import {CustomDispatch} from "redux-store";
import {LangEnum} from "controllers/admin-event/enums";
import {useStyles} from "pages/KdwAdminPage";
import EventReportsForm from "components/FormEvent/EventReportsForm";
import KdwDateTimePicker from "components/Form/Inputs/KdwDateTimePicker";
import EnumSelect from "../Form/SelectFields/EnumSelect";
import TinyMCEHtmlField from "components/Form/Inputs/TinyMCEHtmlField";
import BoxError from "../BoxError/BoxError";
import TranslatesByKeySelect from "../Form/SelectFields/TranslatesByKeySelect";
import {reselect_Profile_entity} from "../../controllers/profile-route/redux-store/profile/profile";
import {KDW_D, KDW_M} from "../../constants/constants";
import Paper from "@material-ui/core/Paper";
import UDImageUploadButton from "../FIleUploadButton/ImageUploadButton/ImageUploadButton";
import {KdwCondition} from "../../helper/redux";

type Props = {
    selectedLang: LangEnum;
    condition?: Response;
    onChange?: Function;
    disabled?: boolean;
};
export const selectByLang = (object: any, selectedLang: LangEnum, def?: any) =>
    object?.hasOwnProperty(selectedLang) ? object?.[`${selectedLang}`] : (def || null);

const FormEvent: React.FC<Props> = (props: Props) => {
    const { selectedLang, condition, onChange } = props;
    const isRu = selectedLang === LangEnum.RU;
    const classes = useStyles();
    const viewEvent = useSelector(reselectEventNew);

    const [isLoading, setIsLoading] = useState(false);
    const [photos, setPhotos] = useState((viewEvent.photos || []).concat([{file: null}]) as any)
    const reduxDispatch = useDispatch<CustomDispatch>();
    const formMode = useSelector(reselectEventNewFormMode);
    const isFormDisabled = isLoading || !!props.disabled;
    const profile = useSelector(reselect_Profile_entity);
    const isAdmin = profile?.user_type === 'admin';
    const lists = useSelector(reselectConstants);
    const handleOnChange = (e: any, lang: LangEnum = selectedLang) => {
        let tmp = { ...viewEvent, photos : viewEvent.photos || photos } as any;
        if (e.target.name.search("photos_to_delete") === 0) {
            tmp.photos_to_delete = (viewEvent.photos_to_delete||[]).concat(e.target.value);
            let photoIndex = tmp.photos.findIndex((v: any) => v.id === e.target.value);
            if (photoIndex !== -1) tmp.photos[photoIndex] = null;
            e.target.name = "photos_to_delete";
            e.target.value = tmp.photos_to_delete;
        } else if (e.target.name.search("photos") === 0) {
            tmp.photos = tmp.photos.concat({ photo: {} });
            if (e.target.value === null) {
                tmp.photos[e.target.index] = null;
                delete tmp.photos[tmp.photos.length - 1];
            } else {
                tmp.photos[e.target.index] = e.target.value
            }
            e.target.name = "photos";
            e.target.value = tmp.photos;
            setPhotos(tmp.photos)
        } else tmp[e.target.name] = e.target.value;
        onChange(e, lang);
        reduxDispatch(reduxOnChange({ name: e.target.name, value: e.target.value }));
    };

    const byLang = (object: any, def?: any) => selectByLang(object, selectedLang, def);
    const textError = (name: string) => condition?.response?.errors?.[`${name}`]?.join(", ") || "";
    React.useEffect(() => {
        if (viewEvent?.id > 0) {
            props.onChange({ target: { name: "id", value: viewEvent.id } }, selectedLang);
        }
    }, []);
    const getDateTime = (date: any) => {
        return Number.isInteger(date) ? new Date(date * 1000) : new Date(date);
    };
    const [countReports, setCountReports] = React.useState(Math.min(viewEvent.reports?.length || 1, 1));
    const timePerReport =
        viewEvent.date && viewEvent.end_date
            ? Math.floor(
                  (getDateTime(viewEvent.end_date).getTime() - getDateTime(viewEvent.date).getTime()) /
                      1000 /
                      60 /
                      countReports,
              )
            : null;
    return (
        <>
            <KDWSnackbar
                isOpen={condition.notice.visibility}
                severity={condition.notice.severity}
                handleOpen={() => reduxDispatch(onNoticeHide({ lang: LangEnum.RU }))}
                message={condition.response.message}
            />
            <Grid container alignItems="center" justify="center">
                <Grid item xs={12}>
                    <EnumSelect
                        onChange={e => {
                            e.target.value = [e.target.value];
                            handleOnChange(e, selectedLang);
                        }}
                        value={[...((viewEvent.forum_themes?.[selectedLang] as any) || [])]?.reverse()?.pop()}
                        name={`forum_themes.${selectedLang}`}
                        error={!!textError("forum_themes")}
                        label="Направление"
                        multiple={false}
                        required={false}
                        disabled={isFormDisabled}
                        errorText={textError("forum_themes")}
                        enums={lists.user.forum_themes}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        disabled={isFormDisabled}
                        onChange={handleOnChange}
                        value={byLang(viewEvent.name) || ""}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Название секции"
                        name={`name.${selectedLang}`}
                        autoComplete="off"
                        error={!!textError("name")}
                        helperText={textError("name")}
                        className={classes.input}
                    />
                </Grid>
                <Grid item xs={12} style={{ marginTop: 10 }}>
                    <EnumSelect
                        onChange={e => handleOnChange(e, selectedLang)}
                        value={Number.parseInt(viewEvent.type?.[selectedLang])}
                        name={`type.${selectedLang}`}
                        label="Тип"
                        multiple={false}
                        required={false}
                        disabled={isFormDisabled}
                        errorText={textError("type")}
                        error={!!textError("type")}
                        enums={lists.user.event_types}
                    />
                </Grid>
                <Grid item xs={12}>
                    {isRu && (
                        <Grid container spacing={4} style={{ marginTop: 10 }}>
                            {["date", "end_date"].map((field: string, i) => {
                                let def = new Date((new Date()).getFullYear() + "/"+KDW_M+"/"+KDW_D+" 09:00");
                                let val = (viewEvent as any)?.[field];
                                if (!val) {
                                    if (field === "date") val = def;
                                    else {
                                        val = viewEvent.date || def;
                                        val = (new Date(val)).getTime()/1000 + 105 *60
                                    }
                                }
                                val = getDateTime(val);
                                return (
                                    <Grid item xs={6}>
                                        <KdwDateTimePicker
                                            key={i}
                                            disabled={isFormDisabled}
                                            minDate={
                                                field === "end_date" && viewEvent?.["date"]
                                                    ? getDateTime(viewEvent["date"])
                                                    : new Date(def.getTime())
                                            }
                                            maxDate={
                                                field === "end_date" && viewEvent?.["date"]
                                                    ? new Date(getDateTime(viewEvent["date"]))?.setUTCHours?.(
                                                          23,
                                                          59,
                                                          59,
                                                      )
                                                    : new Date("2100-01-01")
                                            }
                                            name={field}
                                            label={field === "date" ? "Время" : "Время завершения"}
                                            value={val}
                                            onChange={e => handleOnChange(e, null)}
                                            error={textError(field)}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {timePerReport > 0 && <FormHelperText>На одного спикера: {timePerReport} минут</FormHelperText>}
                </Grid>
                {isAdmin && <Grid item xs={12}>
                    <TextField
                        disabled={isFormDisabled}
                        onChange={handleOnChange}
                        value={byLang(viewEvent.place) || ""}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        id="place"
                        label="Место"
                        name={`place.${selectedLang}`}
                        autoComplete="off"
                        error={!!textError("place")}
                        helperText={textError("place")}
                        className={classes.input}
                    />
                </Grid>}

                <Grid item xs={12}>
                    <BoxError name="description" condition={condition?.response as any} padding={0}>
                        {!condition.inProgress && <TinyMCEHtmlField
                            onChange={handleOnChange}
                            value={byLang(viewEvent.description) || ""}
                            label="Комментарий"
                            initHtml={false}
                            disabled={isFormDisabled}
                            name={`description.${selectedLang}`}
                        />}
                    </BoxError>
                </Grid>
                <Grid item xs={12}>
                    {isRu && !props.disabled && (
                        <>
                            <TranslatesByKeySelect
                                disabled={isFormDisabled}
                                onChange={(e) => handleOnChange(e)}
                                value={byLang(viewEvent.scene) || ""}
                                label="Сцена"
                                name={`scene.${selectedLang}`}
                                key_part="event_scene_"/>
                            {formMode === "edit" && (
                                <>
                                    {viewEvent?.moderators?.length > 0 && (
                                        <>
                                            <Typography variant={"h4"}>Модераторы</Typography>
                                            {viewEvent.moderators.map(v => (
                                                <Grid container>
                                                    <Grid item xs={12} style={{ paddingTop: 15 }}>
                                                        <Typography variant={"subtitle2"}>
                                                            {v.full_name +
                                                                " - " +
                                                                (v?.participation_data?.work_position || v?.work_position || "") +
                                                                " " +
                                                                (v?.participation_data?.place_of_work || v?.place_of_work || "")}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </>
                                    )}
                                    <EventReportsForm
                                        event={viewEvent}
                                        onCountChange={(reports: []) => setCountReports(reports.length)}
                                        onChange={e =>
                                            handleOnChange({
                                                target: {
                                                    name: e.target.name.replace(".", "[").concat("]"),
                                                    value: e.target.value,
                                                },
                                            })
                                        }
                                    />
                                </>
                            )}
                        </>
                    )}
                </Grid>
                {viewEvent?.id &&  isRu && isAdmin && <Grid item xs={12}>
                    <Paper variant="outlined">
                        <Typography variant="h4">
                            Фотографии
                        </Typography>
                        <Grid container>
                            {photos.map((item: any, index: Number) =>
                                !item ? null : (
                                    <BoxError
                                        name={`photos[${index}]`}
                                        condition={condition.response as any}
                                        key={index + ''}>
                                        <UDImageUploadButton
                                            onChange={(e: any) => {
                                                let file = e.target?.value?.file;
                                                if (file === null && item.id > 0) {
                                                    handleOnChange({
                                                        target: {
                                                            name: `photos_to_delete[${item.id}]`,
                                                            value: item.id,
                                                            id: item.id,
                                                        },
                                                    });
                                                    return;
                                                }
                                                e.target.index = index;
                                                handleOnChange(e);
                                            }}
                                            value={item?.photo?.file||item?.file}
                                            name={`photos[${index}]`}
                                        />
                                    </BoxError>
                                ),
                            )}
                        </Grid>
                    </Paper>
                </Grid>}
            </Grid>
        </>
    );
};

export default FormEvent;
