import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { Color } from "@material-ui/lab/Alert";
import { isFunction } from "lodash";
import { TYPE_Event } from "controllers/admin-event/interfaces/admin-event";
import { api_UserEvents_GET } from "controllers/event-controller/api/user-events-GET/user-events-GET";
import { slicerProfile } from "controllers/profile-route/redux-store/profile/profile";

export type Condition = {
    inProgress: boolean;
    errors: any;
    response: {
        result: boolean;
        data: any;
        error: {
            code: number;
            message: string;
        };
    };
    nativeResponse: {
        ok: boolean;
        redirected: boolean;
        status: number;
        statusText: string;
        type: string;
        url: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};

export const slicer_UserEvents_GET = createSlice({
    name: "user-events-GET",
    initialState: {
        list: [] as Array<TYPE_Event>,
        condition: {
            inProgress: false,
            notice: {
                visibility: false,
                severity: "error",
            },
            response: {
                result: false,
                data: null,
                error: {
                    code: 0,
                    message: "",
                },
            },
            nativeResponse: null,
        } as Condition,
    },
    reducers: {
        on_Clean_UserEvens_GET: state => {
            state.condition = {
                inProgress: false,
                errors: {},
                notice: {
                    visibility: false,
                    severity: "success",
                },
                response: {
                    result: false,
                    data: null,
                    error: {
                        code: 0,
                        message: "",
                    },
                },
                nativeResponse: null,
            };
        },
        on_CleanCondition_UserEvens_GET: (state, action: PayloadAction<{ eventIndex: number }>) => {
                state.condition =({
                    inProgress: false,
                    errors: {},
                    notice: {
                        visibility: false,
                        severity: "success",
                    },
                    response: {
                        result: false,
                        data: null,
                        error: {
                            code: 0,
                            message: "",
                        },
                    },
                    nativeResponse: null,
                });
        },
        on_Request_UserEvens_GET: (state, action: PayloadAction<{ eventIndex?: number }>) => {
            {
                state.condition = {
                    inProgress: true,
                    errors: {},
                    notice: {
                        visibility: false,
                        severity: "success",
                    },
                    response: {
                        result: false,
                        data: null,
                        error: {
                            code: 0,
                            message: "",
                        },
                    },
                    nativeResponse: null,
                };
            }
        },
        on_NoResponse_UserEvens_GET: (state, action: PayloadAction<{ eventIndex?: number }>) => {
            {
                state.condition = {
                    inProgress: false,
                    errors: {},
                    response: {
                        result: false,
                        data: null,
                        error: {
                            code: 0,
                            message: "",
                        },
                    },
                    notice: {
                        severity: "error",
                        visibility: true,
                    },
                    nativeResponse: null,
                };
            }
        },
        on_Response_Fail_UserEvens_GET: (state, action: PayloadAction<{ eventIndex?: number; response: any }>) => {
            state.condition.inProgress = false;
        },
        on_Response_Success_UserEvens_GET: (state, action: PayloadAction<{ eventIndex?: number; response: any }>) => {
            const { response } = action.payload;
                state.condition = {
                    inProgress: false,
                    nativeResponse: null,
                    errors: {},
                    response: {
                        result: response.result,
                        data: null,
                        error: {
                            code: 0,
                            message: "",
                        },
                    },
                    notice: {
                        severity: "success",
                        visibility: true,
                    },
                };
            state.list = action.payload.response.data.events || [];
        },
        on_Notice_Hide_UserEvens_GET: (state, action: PayloadAction<{ eventIndex?: number }>) => {
                state.condition.notice.visibility = false;
        },
    },
});

export const { on_Clean_UserEvens_GET, on_Notice_Hide_UserEvens_GET } = slicer_UserEvents_GET.actions;

export const async_UserEvents_GET = (callback?: (result: any) => void) => async (
    reduxDispanch: Dispatch,
    getState: () => RootState,
) => {
    const {
        on_Request_UserEvens_GET,
        on_Response_Success_UserEvens_GET,
        on_Response_Fail_UserEvens_GET,
        on_NoResponse_UserEvens_GET,
    } = slicer_UserEvents_GET.actions;

    reduxDispanch(on_Request_UserEvens_GET({}));

    const result = await api_UserEvents_GET().catch(() => {
        reduxDispanch(on_NoResponse_UserEvens_GET({}));
    });
    if (result) {
        if (result.result) {
            reduxDispanch(on_Response_Success_UserEvens_GET({ response: result }));
        } else {
            reduxDispanch(on_Response_Fail_UserEvens_GET({ response: result }));
        }
    } else {
        reduxDispanch(on_NoResponse_UserEvens_GET({}));
    }
    if (callback && isFunction(callback)) {
        callback(result);
    }
};

export const reselect_UserEvents_GET_condition = (state: RootState) => {
    return state.UserEvents_GET.condition;
};

export const reselect_UserEvents_GET_list = (state: RootState) => {
    return state.UserEvents_GET.list;
};

export default slicer_UserEvents_GET.reducer;
