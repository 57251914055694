import React from "react";
import {Grid, IconButton, Typography} from "@material-ui/core";
import OnlineSelect from "../Form/SelectFields/OnlineSelect";
import {Delete as DeleteIcon, KeyboardArrowDown, KeyboardArrowUp} from "@material-ui/icons";
import {useStyles} from "../../pages/KdwAdminPage";
import {useSelector} from "react-redux";
import {reselect_Profile_entity} from "../../controllers/profile-route/redux-store/profile/profile";

type Props = {
    row: any;
    onChange: (a: any) => void;
    onRemove: (a: any) => void;
    onMove?: (a: any, n: number) => void;
};
const ReportRow: React.FC<Props> = props => {
    const { row, onChange, onRemove } = props;
    const [endTime, setEndTime] = React.useState("");
    const classes = useStyles();
    const profile = useSelector(reselect_Profile_entity);
    const isAdmin = profile?.user_type === "admin";
    return (
        <>
            <Grid item md={6} xs={6}>
                <Typography variant={"subtitle2"}>{row.participant?.user?.full_name + " (" + row.title + ")"}</Typography>
            </Grid>
            <Grid item md={2} xs={3}>
                <OnlineSelect
                    disabled={!!row.partner && !isAdmin}
                    onChange={e => onChange({ ...row, online: e.target.value })}
                    value={row?.online || 0}
                    name={`reports.${row.id}.online`}
                />
            </Grid>
            <Grid item md={1} xs={1}>
                <IconButton
                    color="primary"
                    aria-label=""
                    disabled={!!row.partner && !isAdmin}
                    onClick={() => {
                        onRemove(row);
                    }}>
                    <DeleteIcon />
                </IconButton>
            </Grid>
            <Grid item md={1} xs={1}>
                <IconButton
                    color="primary"
                    aria-label=""
                    onClick={() => {
                        props.onMove(row, -1);
                    }}>
                    <KeyboardArrowUp />
                </IconButton>
            </Grid>
            <Grid item md={1} xs={1}>
                <IconButton
                    color="primary"
                    aria-label=""
                    onClick={() => {
                        props.onMove(row, 1);
                    }}>
                    <KeyboardArrowDown />
                </IconButton>
            </Grid>
        </>
    );
};

export default ReportRow;
