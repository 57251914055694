export const TERMS_OF_USE = {
    link: "/upload/doc/privacy_policy.pdf",
    key: "site.privacy_policy",
    desc: "Политика конфиденциальности",
};

export const OTHER_LINKS = [
    // {
    //     link: {
    //         ru: "/upload/doc/situation.pdf",
    //         en: "",
    //         cn: "",
    //     },
    //     key: "site.site_development",
    //     desc: "Положение о Форуме",
    // },
    {
        link: {
            ru: "/upload/doc/privacy_policy.pdf",
            en: "",
            cn: "",
        },
        key: "site.privacy_policy",
        desc: "Политика конфиденциальности",
    },
    // {
    //     link: {
    //         ru: "/upload/doc/REQUISITES.docx",
    //         en: "/upload/doc/REQUISITES_en.docx",
    //         cn: "",
    //     },
    //     key: "site.requisites",
    //     desc: "Реквизиты для оплаты",
    // },
    // TERMS_OF_USE,
    // {
    //     link: {
    //         ru: "/upload/doc/situation_IT_leader.pdf",
    //         en: "",
    //         cn: "",
    //     },
    //     key: "site.it_leader_information",
    //     desc: 'Положение о конкурсе "ИТ Лидер"',
    // },
    // {
    //     link: "site/payAndRefund/",
    //     key: "site.payment_and_refund",
    //     desc: "Оплата и возврат",
    // },
    // {
    //     link: "https://www.uniteller.ru/",
    //     key: "site.uniteller",
    //     desc: <img width={`140`} src={Payments} alt={"uniteller"} />,
    // },
    // {
    //     link: {
    //         ru: "/upload/doc/offer.pdf",
    //         en: "/upload/doc/offer_en.pdf",
    //         cn: "",
    //     },
    //     key: "site.offer_agreement",
    //     desc: "© 2023 Договор оферты",
    // },
];
