import { kyRequest, BACKEND_HOST, responseCallback } from "api/api";

/**
 * @route api/token-revoke
 * @post
 */
export const fetchRedirectUrl = async (social: any, accept_policy: boolean) => {
    const response = await kyRequest.post(`${BACKEND_HOST}/social/auth/link/${social}`, {
        json: {
            accept_policy,
        },
    });
    return responseCallback(response);
};
export const sendUserSocialCode = async (social: any, code: string, state?: string) => {
    const response = await kyRequest.get(`${BACKEND_HOST}/social/auth/${social}?code=${code}&state=${state}`);
    return responseCallback(response);
};
