import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { apiToken, TypeRequestToken } from "controllers/token/api/token/token";
import { Color } from "@material-ui/lab/Alert";
import { apiTokenAdmin } from "controllers/token/api/token-admin/token-admin";

type Response = {
    inProgress: boolean;
    response: {
        result: boolean;
        data: any;
        errors: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};

export const slicerTokenAdmin = createSlice({
    name: "token",
    initialState: {
        view: {
            email: "",
            password: "",
            rememberMe: false,
        } as TypeRequestToken,
        condition: {
            inProgress: false,
            notice: {
                visibility: false,
                severity: "error",
            },
            response: {
                result: false,
                data: null,
                errors: null,
                message: "",
            },
        } as Response,
    },
    reducers: {
        onRequest: state => {
            state.condition = {
                inProgress: true,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onConditionClear: state => {
            state.condition = {
                notice: {
                    visibility: false,
                    severity: "error",
                },
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onNoResponse: state => {
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        onResponseFail: (state, action: PayloadAction<{ message: string }>) => {
            const response = typeof action.payload === "string" ? JSON.parse(action.payload) : action.payload;
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: response ? response.message : "",
                },
                notice: {
                    visibility: true,
                    severity: "error",
                },
            };
        },
        onResponseSuccess: (
            state,
            action: PayloadAction<{
                message: string;
                result: boolean;
                data: {
                    access_token: string;
                    expires_at: string;
                    token_type: "Bearer";
                    type: string;
                };
            }>,
        ) => {
            state.view = {
                email: "",
                password: "",
                rememberMe: false,
            };
            state.condition = {
                inProgress: false,
                response: {
                    result: action.payload.result,
                    data: action.payload.data,
                    message: action.payload.message,
                    errors: null,
                },
                notice: {
                    visibility: true,
                    severity: "success",
                },
            };
            localStorage.setItem("access_token", action.payload.data.access_token);
            localStorage.setItem("expires_at", action.payload.data.expires_at);
            localStorage.setItem("token_type", action.payload.data.token_type);
            localStorage.setItem("type", action.payload.data.type);
        },
        onNoticeHide: state => {
            state.condition.notice.visibility = false;
        },
        onChange: (state, action) => {
            let ref;
            const nameRoute = action.payload.name.split(".");
            const fieldName = nameRoute.pop();
            ref = nameRoute.reduce((acc: any, item: string) => {
                // @ts-ignore
                return acc[item];
            }, state.view);
            if (action.payload.hasOwnProperty("checked")) {
                ref[fieldName] = action.payload.checked;
            } else {
                ref[fieldName] = action.payload.value;
            }
        },
    },
});

export const {
    onRequest,
    onResponseSuccess,
    onResponseFail,
    onChange,
    onNoticeHide,
    onConditionClear,
} = slicerTokenAdmin.actions;

export const async_TokenAdmin = (callback?: (result: any) => void) => async (
    dispatch: any,
    getState: () => RootState,
) => {
    dispatch(onRequest());
    const view = getState().token.view;
    let result = await apiTokenAdmin(view).catch(e => console.error(e));
    if (result) {
        if (result.result) {
            dispatch(onResponseSuccess(result));
        } else {
            dispatch(onResponseFail(result));
        }
    } else {
        dispatch(onResponseFail(result));
    }
    if (callback) {
        callback(result);
    }
};

export const reselectToken = (state: RootState) => {
    return state.tokenAdmin.view;
};
export const reselectTokenCondition = (state: RootState) => {
    return state.tokenAdmin.condition;
};

export default slicerTokenAdmin.reducer;
