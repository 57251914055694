import React, {FC} from "react";
import ButtonDialog from "../Buttons/ButtonDialog";
import {async_Speaker_GET_Articles} from "../../controllers/articles/redux";
import {useDispatch} from "react-redux";
import {STATE_APPROVED} from "../../controllers/articles/page/SpeakerReport/SpeakerReport";
import {Grid, TextField, Typography} from "@material-ui/core";
import {useStyles} from "../../pages/KdwAdminPage";
import ReportRow from "./ReportRow";
import {format} from "date-fns";
import FormHelperText from "@material-ui/core/FormHelperText";
import {localeField} from "../TypographyLocale/TypographyLocale";

type Props = {
    condition?: any;
    disabled?: boolean;
    onChange: (event: any) => void;
    onCountChange?: (reports: any) => void;
    name?: string;
    label?: string;
    multiple?: boolean;
    event?: any;
};
export const eventIntercept = (event: any, event2: any) => {
    return Math.max(0, Math.min(event2.end_date, event.end_date) - Math.max(event.date, event2.date) + 1) > 0;
};
const EventReportsForm: FC<Props> = ({
    condition = "",
    disabled = false,
    multiple = true,
    onChange,
    onCountChange = (reports: any) => {},
    name = "reports",
    label = "Доклады",
    event = null,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [freeReports, setFreeReports] = React.useState([] as any[]);
    const [allReports, setAllReports] = React.useState([] as any[]);
    const [search, setSearch] = React.useState("");
    const [reports, setReports] = React.useState<any[]>(
        event?.reports?.map((v: any, i: number) => ({
            ...v,
            date: v?.event_report?.date ? format(new Date(v?.event_report?.date * 1000), "HH:mm") : "",
            end_date: v?.event_report?.end_date ? format(new Date(v?.event_report?.end_date * 1000), "HH:mm") : "",
            online: v?.event_report?.online || 0 ? 1 : 0,
            position: i,
        })) || [],
    );
    const [load, setLoad] = React.useState(false);
    const fetchUserReports = (filter: any) => {
        setLoad(true);
        dispatch(
            async_Speaker_GET_Articles(null, { per_page: -1, statuses: [STATE_APPROVED], self:1}, result => {
                setLoad(false);
                if (!result.result) return;
                setAllReports(result?.data || []);
                setFreeReports(
                    (result?.data?.map((v: any, i: number) => ({ ...v, position: i })) || [])?.filter(
                        (v: any) => v.events.length === 0,
                    ),
                );
            }),
        );
    };
    React.useEffect(() => {
        fetchUserReports({});
        reports.forEach(v => reportChange(v));
    }, []);
    React.useEffect(() => onCountChange(reports), [reports.length]);
    const addReport = (v: any) => {
        setFreeReports(freeReports.filter(v2 => v2.id !== v.id));
        const value = {
            ...v,
            online: v?.online || 0,
            date: v?.date || null,
            end_date: v?.end_date || null,
            position: reports.length,
        };
        setReports(reports.concat([value]));
        onChange({
            target: {
                name: "reports." + v.id,
                value,
            },
        });
    };
    const removeReport = (v: any) => {
        setReports(reports.filter(v2 => v2.id !== v.id));
        if (freeReports.findIndex(v2 => v2.id === v.id) === -1) setFreeReports(freeReports.concat([v]));
        onChange({
            target: {
                name: "reports." + v.id,
                value: null,
            },
        });
    };
    const changePosition = (row: any, dif: number) => {
        const current = reports.findIndex((v2: any) => row.id === v2.id);
        const pair = current + dif;
        const min = Math.min(current, pair);
        const max = Math.max(current, pair);
        if (max > reports.length - 1 || min < 0) return;
        const values = [reports[min], reports[max]].filter(v => !!v);
        values.reverse();
        let r = reports
            .slice(0, Math.max(0, min))
            .concat(values)
            .concat(reports.slice(max + 1));
        r = r.map((v: any, i: number) => {
            const value = { ...v, position: i };
            onChange({
                target: {
                    name: "reports." + v.id,
                    value,
                },
            });
            return value;
        });
        setReports(r);
    };
    const reportChange = (v: any) => {
        const valueI = reports.findIndex(o => o.id === v.id);
        const value = { ...reports.find(o => o.id === v.id), ...v };
        setReports([...reports].fill(value, valueI, valueI + 1));
        if (value) {
            onChange({
                target: {
                    name: "reports." + v.id,
                    value,
                },
            });
        }
    };
    const reportListToSelect = freeReports.filter(v => reports.findIndex(v2 => v2.id === v.id) === -1);
    return (
        <>
            <Grid container spacing={2}>
                <Grid xs={12} item style={{ paddingTop: 15 }}>
                    <Typography variant={"h4"}>Доклады</Typography>
                </Grid>
                {reports?.length > 0 &&
                    reports.map(row => {
                        return (
                            <ReportRow
                                row={row}
                                onChange={reportChange}
                                onRemove={removeReport}
                                onMove={changePosition}
                            />
                        );
                    })}
            </Grid>
            {(load || freeReports?.length > 0) && (
                <ButtonDialog
                    maxWidth={"lg"}
                    applyButtonProps={{ style: { display: "none" } }}
                    buttonProps={{
                        style: { marginTop: 15 },
                        color: "primary",
                        disabled: reportListToSelect.length === 0,
                    }}
                    cancelButtonChildren={"Закрыть"}
                    buttonChildren={<>Добавить доклад</>}>
                    <TextField value={search} label="Поиск по докладам" onChange={e => setSearch(e.target.value)} />
                    {reportListToSelect
                        .filter(
                            (v: any) =>
                                search === "" ||
                                v.participant?.user?.full_name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                                v.title.toLowerCase().indexOf(search.toLowerCase()) > -1,
                        )
                        .map((v, i) => {
                            let userIntercepts =
                                allReports
                                    .filter(
                                        (v2: any) => v2.user_id === v.user_id && v2.id !== v.id && v2.events.length > 0,
                                    )
                                    .filter((v2: any) => eventIntercept(v2.events[0], event))
                                    .map((v2: any) =>
                                        v2.events.map((v2e: any) => localeField(v2e, "name")).join(", "),
                                    ) || "";
                            return (
                                <Grid style={{ borderBottom: "1px solid gray", textAlign: "left", lineHeight: "30px" }}>
                                    <a
                                        className={classes.a}
                                        onClick={() => {
                                            addReport(v);
                                        }}>
                                        {v.participant?.user?.full_name +
                                            " (" +
                                            v.title.substr(0, 1).toUpperCase() +
                                            v.title.substr(1).toLowerCase() +
                                            ")"}
                                    </a>
                                    {userIntercepts.length > 0 && (
                                        <FormHelperText error style={{ display: "inline", marginLeft: 10 }}>
                                            Прикреплен к пересекающимся мероприятиям: {userIntercepts}
                                        </FormHelperText>
                                    )}
                                </Grid>
                            );
                        })}
                    {reportListToSelect.length === 0 && (
                        <Typography variant={"subtitle2"}>Список доступных докладов пуст</Typography>
                    )}
                </ButtonDialog>
            )}
        </>
    );
};
export default EventReportsForm;
