import React from "react";
import classes from "./FooterLinks.module.css";
import { OTHER_LINKS } from "../../../../constants/site/footerCommon";
import LinkWrapper from "../../../../components/Site/LinkWrapper/LinkWrapper";
import { selectByLang } from "../../../../components/FormEvent/FormEvent";
import { LangEnum } from "../../../../controllers/admin-expo/enums";
import clsx from "clsx";
import __translate from "../../../../helper/locale/locale";
import __locale from "../../../../helper/location/location";

const FooterLinks = () => {
    const lang = __locale();
    return (
        <div className={classes.footer__bottomRow}>
            {OTHER_LINKS.map(
                (element, index) =>
                    element.link[lang as LangEnum] && (
                        <LinkWrapper
                            link={
                                typeof element.link === "string"
                                    ? element.link
                                    : selectByLang(element.link, lang as LangEnum, "")
                            }
                            className={clsx(classes.footer__link)}
                            key={index}>
                            {typeof element.desc === "string" ? __translate(element.key, element.desc) : element.desc}
                        </LinkWrapper>
                    ),
            )}
        </div>
    );
};

export default FooterLinks;
