import { kyRequest, BACKEND_HOST, responseCallback } from "api/api";
import { LangEnum } from "../../../admin-expo/enums";
// import { response_Front_Contnet } from "api/data/api.front.content";

// const __Front_Content = () => {
//     return new Promise(resolve => {
//         resolve(response_Front_Contnet);
//     });
// };
/**
 * @route api/user
 * @post
 */
export const apiFrontContent = async (localeCode: string, page?: number, params?: any) => {
    params = { locale: localeCode, ...params, ...(page ? { page } : { for_view: 1 }) };
    const response = await kyRequest.get(`${BACKEND_HOST}/front/content?${new URLSearchParams(params).toString()}`, {
        headers: {
            "Accept-Language": "cn",
        },
    });
    return responseCallback(response);
};
export const apiFrontContentLocaleByKey = async (key: string) => {
    const response = await kyRequest.get(`${BACKEND_HOST}/front/contentByKey?key=${key}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Accept-Language": "ru",
        },
    });
    return responseCallback(response);
};
export const apiFrontContentLocaleCreate = async (key: string, value: string, localeCode: string = LangEnum.RU) => {
    return apiFrontContentLocaleUpdate({key, translates:value});
};
export const apiFrontContentLocaleUpdate = async (data: any, localeCode: string = LangEnum.RU) => {
    const body = new FormData();
    if (data.id > 0) {
        body.append("_method", "PUT");
        if (data.translates) body.append(`translates[${localeCode}]`, data.translates);
    } else  {
        body.append(`key`, data.key);
        body.append(`translates[en]`, data.translates);
        body.append(`translates[cn]`, data.translates);
        body.append(`translates[ru]`, data.translates);
    }
    const response = await kyRequest.post(`${BACKEND_HOST}/front/content/` +( data.id || ""), {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body,
    });
    return responseCallback(response);
};
