import React from "react";
import LinkWrapper from "../../../../LinkWrapper/LinkWrapper";
import classes from "./ExponentCardImgWrapper.module.css";

interface Props {
    imgSrc: string;
    imgAlt: string;
    link?: string;
    name?: string;
}

export default function ExponentCardImgWrapper({ link, imgSrc, imgAlt, name }: Props) {
    return (
        <LinkWrapper className={classes.exponentCard} link={link}>
            <div>
                <img className={classes.exponentCard__img} data-src={imgSrc} alt={imgAlt} src={imgSrc} loading="lazy" />
            </div>
            {name && <span className={classes.exponentCard__name}>{name}</span>}
        </LinkWrapper>
    );
}
