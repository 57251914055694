import styled from "styled-components";

export const PhotoForFileInput = styled.div`
    background-image: url(${(props: { url: string }) => props.url});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 170px;
    height: 170px;
    margin-right: 12px;
`;
export const PhotoForFileInputImg170 = styled.img`
    cursor: ${props => (props.onClick ? "pointer" : "")};
    position: relative;
    height: 170px;
    width: auto;
    min-width: 1px;
`;
export const PhotoForFileInputImg = styled.img`
    cursor: ${props => (props.onClick ? "pointer" : "")};
    position: relative;
    max-height: 400px !important;
    width: auto;
    min-width: 1px;
`;
