import { kyRequest, BACKEND_HOST, responseCallback, kyRequestBase } from "api/api";
import { format } from "date-fns";
import { getFileFromUrl } from "../profile-route-post/api/profile-post/profile-post";

/**
 * @route api/profile
 * @get
 */
export const getContactsList = async () => {
    const response = await kyRequest.get(`${BACKEND_HOST}/contact`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return responseCallback(response, false, true);
};

/**
 * @route api/profile
 * @get
 */
export const deleteContactById = async (id: any) => {
    const response = await kyRequest.delete(`${BACKEND_HOST}/contact/${id}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return responseCallback(response, false, true);
};
export const saveContact = async (contact: any, lang: string) => {
    const data = new FormData();
    Object.keys(contact).forEach(key => {
        data.append(key, contact[key]);
    });
    if (contact.id > 0) data.append("_method", "PUT");
    const response = await responseCallback(
        await kyRequestBase.post(`${BACKEND_HOST}/contact/${contact.id || ""}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                "Accept-Language": lang,
            },
            body: data,
        }),
        false,
        true,
    );

    return response;
};
