import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { Color } from "@material-ui/lab/Alert";
import { apiGeolocationCountries } from "controllers/geolocation-route/api/countries/countries";

export type Response = {
    inProgress: boolean;
    response: {
        result: boolean;
        data: any;
        errors: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};

export type TYPE_InitialState_Countries = {
    entity: {
        list: Array<any>;
        value: string;
        country: null | any;
    };
    condition: Response;
};

export const slicer_countries = createSlice({
    name: "reducer-countries",
    initialState: {
        entity: {
            list: [],
            value: "",
            country: null,
        },
        condition: {
            inProgress: false,
            notice: {
                visibility: false,
                severity: "error",
            },
            response: {
                result: false,
                data: null,
                errors: null,
                message: "",
            },
        } as Response,
    } as TYPE_InitialState_Countries,
    reducers: {
        on_Set_Country_Countries: (state, action: PayloadAction<string>) => {
            state.entity.value = action.payload;
        },
        on_Change_Countries: (state, action: PayloadAction<{ name: string; value: string }>) => {
            const { value, name } = action.payload as {
                name: string;
                value: string;
            };
            switch (name) {
                case "country": {
                    state.entity = {
                        ...state.entity,
                        value,
                    };
                    break;
                }
            }
        },
        on_Select_Countries: (
            state,
            action: PayloadAction<{ name: string; value?: { name: string; value: number } }>,
        ) => {
            const { value, name } = action.payload;
            console.log("action", action);
            switch (name) {
                case "country":
                    state.entity.value = value ? value.name : "";
                    state.entity.country = value;
                    break;
            }
        },
        on_CleanCondition: state => {
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        on_Request: state => {
            state.condition.inProgress = true;
        },
        on_NoResponse: state => {
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        on_ResponseFailure: (state, action) => {
            console.log("action", action);
            state.condition.inProgress = false;
        },
        on_ResponseSuccess: (state, action: PayloadAction<{ data: Array<any>; message: string; resutl: boolean }>) => {
            state.condition.inProgress = false;
            if (Array.isArray(action.payload.data)) {
                state.entity.list = action.payload.data.map(item => {
                    return {
                        name: item.title,
                        value: item.id,
                    };
                });
            }
            console.log("action", action);
        },
        on_NoticeHide_Countries: state => {
            state.condition.notice.visibility = false;
        },
    },
});

export const {
    on_CleanCondition,
    on_Request,
    on_NoResponse,
    on_ResponseSuccess,
    on_ResponseFailure,
    on_NoticeHide_Countries,
    on_Change_Countries,
    on_Select_Countries,
    on_Set_Country_Countries,
} = slicer_countries.actions;

export const async_Countries = (countryName?: string, callback?: (result: any) => void) => async (
    dispatch: any,
    getState: () => RootState,
) => {
    const searchString = countryName || getState().countries.entity.value;
    dispatch(on_Request());
    const result = await apiGeolocationCountries(searchString).catch(e => {
        dispatch(on_NoResponse());
    });
    console.log("result", result);
    if (result) {
        if (result.result) {
            dispatch(on_ResponseSuccess(result));
        } else {
            dispatch(on_ResponseFailure(result));
        }
    } else {
        dispatch(on_NoResponse());
    }
    if (callback) {
        callback(result);
    }
};

export const reselect_Countries_condition = (state: RootState) => {
    return state.countries.condition;
};
export const reselect_Countries_entity = (state: RootState) => {
    return state.countries.entity;
};

export default slicer_countries.reducer;
