import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import {
    visitsSearch,
    visitById,
    recordVisit,
    visitPayForm,
    recordVisitCancel,
    saveVisit,
    deleteVisitById,
    visitUsersById,
    changeVisitUserStatus,
    visitUsersExport,
} from "controllers/visits/api/visits/visits";
import { Color } from "@material-ui/lab/Alert";
import { Paginator } from "../../../admin-users/redux-store/admin-users/admin-users";

export type Response = {
    result: boolean;
    data: any;
    errors: any;
    error: { code: number; message: string };
    message: string;
    current_page: number;
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    next_page_url: string;
    path: string;
    per_page: number;
    prev_page_url: null;
    to: number;
    total: number;
};
export const VISIT_TYPE_HACKATHON = 1;
export const VISIT_TYPE_VISIT = 0;
export const VISIT_TYPE_EXCURSION = 2;
export const VISIT_TYPE_WEBINAR = 3;
export const VISIT_TYPE_STUDENT_CYBER_BATTLE = 4;
export type Notice = { visibility: boolean; severity: Color };
export const slicerVisits = createSlice({
    name: "visits",
    initialState: {
        paginator: {
            current_page: 1,
            first_page_url: "",
            from: 1,
            last_page: 1,
            last_page_url: "",
            next_page_url: "",
            path: "",
            per_page: 15,
            prev_page_url: null,
            to: 15,
            total: 0,
        } as Paginator,
        users: [null as any],
        items: [],
        items_anons: [],
        items_menu: {} as any,
        visit: null as any,
        condition: {
            inProgress: false,
            notice: {
                visibility: false,
                severity: "error",
            } as Notice,
            response: {
                result: false,
                data: null,
                errors: null,
                error: {
                    code: 0,
                    message: "empty",
                },
                message: "",
            },
        },
    },
    reducers: {
        onNoticeHide: state => {
            state.condition.notice.visibility = false;
        },
        onItem: (state, action) => {
            state.visit = action.payload;
        },
        onMenuItem:(state, action) => {
            state.items_menu = {...state.items_menu, [action.payload.id]: action.payload.name?.ru || action.payload.name || ''};
        },
        onRequest: state => {
            state.condition = {
                inProgress: true,
                notice: {
                    visibility: false,
                    severity: "success",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    error: {
                        code: 0,
                        message: "",
                    },
                    message: "",
                } as Response,
            };
        },
        onSearchSuccess: (state, action: { payload: Response }) => {
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "success",
                },
                response: {
                    message: action.payload.error.message,
                    ...action.payload,
                },
            };
            state.items = action.payload.data;
            state.visit = null;
        },
        onAnonsSearchSuccess: (state, action: { payload: Response }) => {
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "success",
                },
                response: {
                    message: action.payload.error.message,
                    ...action.payload,
                },
            };
            state.items_anons = action.payload.data;
        },
        onUsersSuccess: (state, action: any) => {
            state.paginator = {
                current_page: action.payload.body.current_page,
                first_page_url: action.payload.body.first_page_url,
                from: action.payload.body.from,
                last_page: action.payload.body.last_page,
                last_page_url: action.payload.body.last_page_url,
                next_page_url: action.payload.body.next_page_url,
                path: action.payload.body.path,
                per_page: action.payload.body.per_page,
                prev_page_url: action.payload.body.prev_page_url,
                to: action.payload.body.to,
                total: action.payload.body.total,
            };
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "success",
                },
                response: {
                    message: action.payload.error.message,
                    ...action.payload,
                },
            };
            state.users = action.payload.data;
        },
        onItemSuccess: (state, action: { payload: Response }) => {
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "success",
                },
                response: {
                    message: action.payload.error.message,
                    ...action.payload,
                },
            };
            state.items = [];
            state.visit = action.payload.data;
        },
        onRecordSuccess: (state, action: { payload: Response }) => {
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "success",
                },
                response: {
                    ...action.payload,
                    message: action.payload.error.message,
                },
            };
        },
        onRecordCancelSuccess: (state, action: { payload: Response }) => {
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "success",
                },
                response: {
                    ...action.payload,
                    message: action.payload.error.message,
                },
            };
        },
        onSaveSuccess: (state, action: { payload: Response }) => {
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "success",
                },
                response: {
                    ...action.payload,
                    message: action.payload?.error?.message,
                },
            };
        },
        onRequestFail: (state, action: { payload: Response }) => {
            state.condition = {
                notice: {
                    visibility: true,
                    severity: "error",
                },
                inProgress: false,
                response: {
                    ...action.payload,
                    result: false,
                    data: {},
                    message: action.payload.error.message,
                    errors: action.payload.error.code === 400 ? action.payload.data : null,
                },
            };
        },
        stopLoading: state => {
            state.condition.inProgress = false;
        },
    },
});

export const {
    onRequest,
    onSearchSuccess,
    onAnonsSearchSuccess,
    onRequestFail,
    onNoticeHide,
    onItem,
    onItemSuccess,
    onRecordSuccess,
    onRecordCancelSuccess,
    onSaveSuccess,
    stopLoading,
    onUsersSuccess,
    onMenuItem,
} = slicerVisits.actions;

export const asyncVisitsSearch = (
    props?: { q?: String; type?: Number; page?: Number; per_page?: Number, min_date?: string },
    callback?: (result: any) => void,
) => {
    return async (dispatch: any, getState: () => RootState) => {
        dispatch(onRequest());
        let error = null;
        const result = await visitsSearch(props).catch(e => {
            console.log(e);
            error = e;
        });
        if (error || !result) dispatch(stopLoading);
        else {
            if (result.result) dispatch(onSearchSuccess(result));
            else dispatch(onRequestFail(result));
        }
        if (callback) callback(result);
    };
};

export const asyncAnonsVisitsSearch = (
    props?: { q?: String; type?: Number; page?: Number; per_page?: Number, min_date?: string },
    callback?: (result: any) => void,
) => {
    return async (dispatch: any, getState: () => RootState) => {
        dispatch(onRequest());
        let error = null;
        const result = await visitsSearch(props).catch(e => {
            console.log(e);
            error = e;
        });
        if (error || !result) dispatch(stopLoading);
        else {
            if (result.result) dispatch(onAnonsSearchSuccess(result));
            else dispatch(onRequestFail(result));
        }
        if (callback) callback(result);
    };
};

export const asyncGetVisit = (id: Number, callback?: (result: any) => void) => {
    return async (dispatch: any, getState: () => RootState) => {
        dispatch(onRequest);
        let error = null;
        const result = await visitById(id).catch(e => {
            console.log(e);
            error = e;
        });
        if (!error && result) {
            if (result.result) dispatch(onItemSuccess(result));
            else dispatch(onRequestFail(result));
        }
        dispatch(stopLoading);
        if (callback) callback(result);
    };
};
export const asyncGetVisitUsers = (
    id: number,
    page: number,
    per_page?: number,
    callback?: (result: any) => void,
    exportXLS?: any,
) => {
    return async (dispatch: any, getState: () => RootState) => {
        dispatch(onRequest);
        let error = null;
        let result = null;
        if (!exportXLS) {
            result = await visitUsersById(id, page, per_page).catch((e: any) => {
                console.log(e);
                error = e;
            });
            if (error || !result) dispatch(stopLoading);
            else {
                if (result.result) dispatch(onUsersSuccess(result));
                else dispatch(onRequestFail(result));
            }
            if (callback) callback(result);
        } else
            result = visitUsersExport(id).catch((e: any) => {
                console.log(e);
                error = e;
            });
    };
};
export const asyncChangeUserStatus = (
    visit_id: Number,
    user_id: Number,
    state: Number,
    callback?: (result: any) => void,
) => {
    return async (dispatch: any, getState: () => RootState) => {
        dispatch(onRequest);
        let error = null;
        const result = await changeVisitUserStatus(visit_id, user_id, state).catch((e: any) => {
            console.log(e);
            error = e;
        });
        if (error || !result) dispatch(stopLoading);
        else {
            if (result.result) dispatch(stopLoading());
            else dispatch(onRequestFail(result));
        }
        if (callback) callback(result);
    };
};
export const asyncDeleteVisit = (id: Number, callback?: (result: any) => void) => {
    return async (dispatch: any, getState: () => RootState) => {
        dispatch(onRequest);
        let error = null;
        const result = await deleteVisitById(id).catch(e => {
            console.log(e);
            error = e;
        });
        if (error || !result) dispatch(stopLoading);
        else {
            if (result.result) dispatch(onItemSuccess(result));
            else dispatch(onRequestFail(result));
        }
        if (callback) callback(result);
    };
};
export const asyncRecordVisit = (id: Number, callback?: (result: any) => void) => {
    return async (dispatch: any, getState: () => RootState) => {
        dispatch(onNoticeHide);
        dispatch(onRequest);
        let error = null;
        const result = await recordVisit(id).catch(e => {
            error = e;
        });
        if (result && !error) {
            if (result.result) {
                dispatch(onRecordSuccess(result));
                dispatch(onRequest);
            } else dispatch(onRequestFail(result));
        }

        if (error || !result) dispatch(stopLoading);
        if (callback) callback(result);
    };
};
export const asyncVisitPayForm = (id: Number, callback?: (result: any) => void) => {
    return async (dispatch: any, getState: () => RootState) => {
        dispatch(onNoticeHide);
        dispatch(onRequest);
        let error = null;
        const result = await visitPayForm(id).catch(e => {
            error = e;
        });
        if (result && !error) {
            if (result.result) {
                dispatch(onRecordSuccess(result));
                dispatch(onRequest);
            } else dispatch(onRequestFail(result));
            if (callback) callback(result);
        }

        if (error || !result) dispatch(stopLoading);
    };
};
export const asyncVisitCancel = (id: Number, callback?: (result: any) => void) => {
    return async (dispatch: any, getState: () => RootState) => {
        dispatch(onNoticeHide);
        dispatch(onRequest);
        let error = null;
        const result = await recordVisitCancel(id).catch(e => {
            error = e;
        });
        if (result && !error) {
            if (result.result) {
                dispatch(onRecordCancelSuccess(result));
                dispatch(onRequest);
            } else dispatch(onRequestFail(result));
        }

        if (error || !result) dispatch(stopLoading);
        if (callback) callback(result);
    };
};

export const asyncVisitSave = (visit: any, lang: string, callback?: (result: any) => void) => {
    return async (dispatch: any, getState: () => RootState) => {
        dispatch(onNoticeHide);
        dispatch(onRequest);
        let error = null;
        const result = await saveVisit(visit, lang).catch(e => {
            error = e;
        });
        if (result && !error) {
            if (result.result) {
                dispatch(onSaveSuccess(result));
                dispatch(onRequest);
            } else dispatch(onRequestFail(result));
        }

        if (error || !result) dispatch(stopLoading);
        if (callback) callback(result);
    };
};
export const reselectVisits = (state: RootState) => {
    return state.visits.items;
};

export const reselectAnonsVisits = (state: RootState) => {
    return state.visits.items_anons;
};

export const reselectVisit = (state: RootState) => {
    return state.visits.visit;
};
export const reselectVisitUsers = (state: RootState) => {
    return state.visits.users;
};
export const reselectVisitUsersPaginator = (state: RootState) => {
    return state.visits.paginator;
};
export const reselectVisitsCondition = (state: RootState) => {
    return state.visits.condition;
};
export const reselectVisitsMenuItems = (state: RootState) => {
    return state.visits.items_menu;
};
export default slicerVisits.reducer;
