export const STRING_BACKSPACE: string = " ";
export const STRING_PLUS: string = "+";
export const STRING_LEFT_BRACKET: string = "(";
export const STRING_RIGHT_BRACKET: string = ")";
export const STRING_EMPTY: string = "";
export const STRING_COMMA: string = ",";
export const STRING_DOT: string = ",";

export const helpValidateNumber: (x1: string) => boolean = (inValue: string): boolean => {
    return !inValue ? false : /^(\+(\d){6,20})$/.test(inValue);
};

/**
 * -----------------------------------------------------------------------------
 * @description Mask phone number
 * -----------------------------------------------------------------------------
 * @helper
 * -----------------------------------------------------------------------------
 */
export const helpMaskPhoneNumber: (x1: any, update?: boolean) => string = (
    inValue: any,
    update: boolean = false,
): string => {
    let onlyNumber: string = inValue?.replace(/\D/g, "") || "";
    if (update) {
        return String.prototype.concat(
            STRING_PLUS,
            onlyNumber.slice(0, 1),
            STRING_BACKSPACE,
            STRING_LEFT_BRACKET,
            onlyNumber.slice(1, 4),
            STRING_RIGHT_BRACKET,
            STRING_BACKSPACE,
            onlyNumber.slice(4, 7),
            STRING_BACKSPACE,
            onlyNumber.slice(7, 9),
            STRING_BACKSPACE,
            onlyNumber.slice(9, 11),
        );
    }
    switch (onlyNumber.length) {
        case 0: {
            return STRING_EMPTY;
        }
        case 1: {
            return String.prototype.concat(STRING_PLUS, onlyNumber);
        }
        case 2: {
            return String.prototype.concat(
                STRING_PLUS,
                onlyNumber.slice(0, 1),
                STRING_BACKSPACE,
                STRING_LEFT_BRACKET,
                onlyNumber.slice(1, 2),
            );
        }
        case 4: {
            return String.prototype.concat(
                STRING_PLUS,
                onlyNumber.slice(0, 1),
                STRING_BACKSPACE,
                STRING_LEFT_BRACKET,
                onlyNumber.slice(1, 4),
            );
        }
        case 5: {
            return String.prototype.concat(
                STRING_PLUS,
                onlyNumber.slice(0, 1),
                STRING_BACKSPACE,
                STRING_LEFT_BRACKET,
                onlyNumber.slice(1, 4),
                STRING_RIGHT_BRACKET,
                STRING_BACKSPACE,
                onlyNumber.slice(4, 5),
            );
        }
        case 7: {
            return String.prototype.concat(
                STRING_PLUS,
                onlyNumber.slice(0, 1),
                STRING_BACKSPACE,
                STRING_LEFT_BRACKET,
                onlyNumber.slice(1, 4),
                STRING_RIGHT_BRACKET,
                STRING_BACKSPACE,
                onlyNumber.slice(4, 7),
            );
        }
        case 8: {
            return String.prototype.concat(
                STRING_PLUS,
                onlyNumber.slice(0, 1),
                STRING_BACKSPACE,
                STRING_LEFT_BRACKET,
                onlyNumber.slice(1, 4),
                STRING_RIGHT_BRACKET,
                STRING_BACKSPACE,
                onlyNumber.slice(4, 7),
                STRING_BACKSPACE,
                onlyNumber.slice(7, 8),
            );
        }
        case 9: {
            return String.prototype.concat(
                STRING_PLUS,
                onlyNumber.slice(0, 1),
                STRING_BACKSPACE,
                STRING_LEFT_BRACKET,
                onlyNumber.slice(1, 4),
                STRING_RIGHT_BRACKET,
                STRING_BACKSPACE,
                onlyNumber.slice(4, 7),
                STRING_BACKSPACE,
                onlyNumber.slice(7, 9),
            );
        }
        case 10: {
            return String.prototype.concat(
                STRING_PLUS,
                onlyNumber.slice(0, 1),
                STRING_BACKSPACE,
                STRING_LEFT_BRACKET,
                onlyNumber.slice(1, 4),
                STRING_RIGHT_BRACKET,
                STRING_BACKSPACE,
                onlyNumber.slice(4, 7),
                STRING_BACKSPACE,
                onlyNumber.slice(7, 9),
                STRING_BACKSPACE,
                onlyNumber.slice(9, 10),
            );
        }
        default: {
            return inValue;
        }
    }
};

export const helpRemoveMaskPhoneNumber: (phone: string) => string = (phone: string): string => {
    return phone.replace("+", "").replace("(", "").replace(")", "").replace(/\s/g, "");
};
