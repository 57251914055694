import { kyRequest, BACKEND_HOST, responseCallback } from "api/api";

export type ChangeReportState = {
    report_id: number;
    state: number;
    amount?: number;
};

/**
 * @route api/reset-password
 * @post
 * @param {TypeRequestRegistration} params
 * @deprecated
 */
export const apiRouteChangeReportState = async (params: ChangeReportState) => {
    let formData = new FormData();
    Object.keys({ ...params }).forEach(key => {
        // if(key.indexOf('_file') > 0)
        formData.append(key, params[key as keyof ChangeReportState] as any);
    });
    formData.append('_method', 'put')
    const response = await kyRequest.post(`${BACKEND_HOST}/speaker/report/${params.report_id}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: formData,
    });
    return responseCallback(response, false, true);
};
