import React, {useState} from "react";
import FormToken from "controllers/token/form/FormToken/FormToken";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {authBySocialCode, reselectSocialCondition} from "controllers/social-auth";
import {onChange, reselectTokenCondition} from "controllers/token/redux-store/token/token";
import {__localeStorage} from "helper/location/location";


export const toLastPage = (history: any, to:string, hard = false) => {
    const lastAction = localStorage.getItem("from") || to;
    localStorage.removeItem("from");
    history.push(hard ? to : lastAction, { from: "form_token" });
};

const PageToken: React.FC = () => {
    const search = useLocation().search;
    const [code, setCode] = useState(new URLSearchParams(search).get("code"));
    const state = new URLSearchParams(search).get("state");
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams<{ ru: string }>();
    const socialCondition = useSelector(reselectSocialCondition);
    const tokenCondition = useSelector(reselectTokenCondition);
    const social = localStorage.getItem("social");
    const from = (history.location?.state as any)?.from;
    if (from) localStorage.setItem("from", from);

    if (code && !socialCondition.inProgress) {
        dispatch(
            authBySocialCode(social, code, state, result => {
                if (!result.result) {
                    if (result.data?.status === "ASK_PASSWORD" && result.data?.email) {
                        dispatch(onChange({ name: "email", value: result.data.email }));
                    }
                    return history.push(`/${__localeStorage()}/login`);
                }
                setCode(null);
            }),
        );
    } else {

    }
    if (!code && tokenCondition.response.result) {
        toLastPage(history, `/${params.ru}/user/personal-data`);
        return <></>;
    }
    return <FormToken />;
};

export default PageToken;
