import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { Color } from "@material-ui/lab/Alert";
import { apiUserEvent } from "controllers/event-controller/api/user-event/user-event";
import { isFunction } from "lodash";
import {KdwCondition} from "../../../../helper/redux";

type Condition = KdwCondition

export const slicer_Post_UserEvent = createSlice({
    name: "user-event",
    initialState: {
        condition: {
            inProgress: false,
            notice: {
                visibility: false,
                severity: "error",
            },
            response: {
                result: false,
                data: null,
                error: {
                    code: 0,
                    message: "",
                },
            },
            nativeResponse: null,
        } as Condition,
        conditions: {} as any,
    },
    reducers: {
        on_Clean_PostUserEvent: state => {
            state.conditions = {};
            state.condition = {
                inProgress: false,
                errors: {},
                notice: {
                    visibility: false,
                    severity: "success",
                    message: ""
                },
                response: {
                    result: false,
                    data: null,
                    error: {
                        code: 0,
                        message: "",
                    },
                },
                nativeResponse: null,
            };
        },
        on_CleanCondition_PostUserEvent: (state, action: PayloadAction<{ eventIndex: number }>) => {
            const { eventIndex } = action.payload;
            if (state.conditions.hasOwnProperty(eventIndex)) {
                state.conditions[eventIndex] = ({
                    inProgress: false,
                    errors: {},
                    notice: {
                        visibility: false,
                        severity: "success",
                        message: ""
                    },
                    response: {
                        result: false,
                        data: null,
                        error: {
                            code: 0,
                            message: "",
                        },
                    },
                    nativeResponse: null,
                });
            }
        },
        on_Request_PostUserEvent: (state, action: PayloadAction<{ eventIndex: number }>) => {
            const { eventIndex } = action.payload;
            if (eventIndex) {
                state.conditions[eventIndex] = ({
                    inProgress: true,
                    errors: {},
                    notice: {
                        visibility: false,
                        severity: "success",
                        message: ""
                    },
                    response: {
                        result: false,
                        data: null,
                        error: {
                            code: 0,
                            message: "",
                        },
                    },
                    nativeResponse: null,
                });
            } else {
                state.condition = {
                    inProgress: true,
                    errors: {},
                    notice: {
                        visibility: false,
                        severity: "success",
                        message: ""
                    },
                    response: {
                        result: false,
                        data: null,
                        error: {
                            code: 0,
                            message: "",
                        },
                    },
                    nativeResponse: null,
                };
            }
        },
        on_NoResponse_PostUserEvent: (state, action: PayloadAction<{ eventIndex: number }>) => {
            const { eventIndex } = action.payload;
            if (eventIndex) {
                state.conditions[eventIndex] = ({
                    inProgress: false,
                    errors: {},
                    response: {
                        result: false,
                        data: null,
                        error: {
                            code: 0,
                            message: "",
                        },
                    },
                    notice: {
                        severity: "error",
                        visibility: true,
                        message: ""
                    },
                    nativeResponse: null,
                });
            } else {
                state.condition = {
                    inProgress: false,
                    errors: {},
                    response: {
                        result: false,
                        data: null,
                        error: {
                            code: 0,
                            message: "",
                        },
                    },
                    notice: {
                        severity: "error",
                        visibility: true,
                        message: ""
                    },
                    nativeResponse: null,
                };
            }
        },
        on_Response_Fail_PostUserEvent: (state, action: PayloadAction<{ eventIndex: number; response: any }>) => {
            const { eventIndex, response } = action.payload;
            if (eventIndex) {
                state.conditions[eventIndex] = ({
                    inProgress: false,
                    nativeResponse: null,
                    errors: {},
                    response: {
                        result: response,
                        data: null,
                        error: {
                            code: 0,
                            message: "",
                        },
                    },
                    notice: {
                        severity: "success",
                        visibility: true,
                        message: ""
                    },
                });
            } else {
                state.condition.inProgress = false;
            }
        },
        on_Response_Success_PostUserEvent: (state, action: PayloadAction<{ eventIndex: number; response: any }>) => {
            const { eventIndex, response } = action.payload;
            if (eventIndex) {
                state.conditions[eventIndex] = ({
                    inProgress: false,
                    nativeResponse: null,
                    errors: {},
                    response: {
                        result: response,
                        data: null,
                        error: {
                            code: 0,
                            message: "",
                        },
                    },
                    notice: {
                        severity: "success",
                        message: "",
                        visibility: true,
                    },
                });
            } else {
                state.condition = {
                    inProgress: false,
                    nativeResponse: null,
                    errors: {},
                    response: {
                        result: response,
                        data: null,
                        error: {
                            code: 0,
                            message: "",
                        },
                    },
                    notice: {
                        severity: "success",
                        visibility: true,
                        message: ""
                    },
                };
            }
        },
        on_Notice_Hide_PostUserEvent: (state, action: PayloadAction<{ eventIndex: number }>) => {
            const { eventIndex } = action.payload;
            if (eventIndex && state.conditions.hasOwnProperty(eventIndex)) {
                const condition = state.conditions.get(eventIndex);
                state.conditions[eventIndex] = ({
                    ...condition,
                    notice: {
                        ...condition.notice,
                        visibility: false,
                    },
                });
            } else {
                state.condition.notice.visibility = false;
            }
        },
    },
});

export const { on_Clean_PostUserEvent, on_Notice_Hide_PostUserEvent } = slicer_Post_UserEvent.actions;

export const async_Post_UserEvents = (eventIndex: number, callback?: (result: any) => void) => async (
    reduxDispanch: Dispatch,
    getState: () => RootState,
) => {
    const {
        on_Request_PostUserEvent,
        on_Response_Success_PostUserEvent,
        on_Response_Fail_PostUserEvent,
        on_NoResponse_PostUserEvent,
    } = slicer_Post_UserEvent.actions;
    //
    reduxDispanch(on_Request_PostUserEvent({ eventIndex }));
    const result = await apiUserEvent(eventIndex).catch(e => {
        reduxDispanch(on_NoResponse_PostUserEvent({ eventIndex }));
    });
    if (result) {
        reduxDispanch(
            result.result
                ? on_Response_Success_PostUserEvent({ eventIndex, response: result })
                : on_Response_Fail_PostUserEvent({ eventIndex, response: result }),
        );
    } else {
        reduxDispanch(on_NoResponse_PostUserEvent({ eventIndex }));
    }
    if (callback && isFunction(callback)) {
        callback(result);
    }
};

export const reselect_Post_UserEvents_condition = (state: RootState) => {
    return state.postUserEvents.condition;
};

export const reselect_Post_UserEvents_conditions = (state: RootState) => {
    return state.postUserEvents.conditions;
};

export default slicer_Post_UserEvent.reducer;
