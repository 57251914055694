import React from "react";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import KDWSnackbar from "components/KDWSnackbar/KDWSnackbar";
import Loader from "components/Loader/Loader";
import {
    async_Profile,
    reselect_Profile_condition,
    reselect_Profile_entity,
} from "controllers/profile-route/redux-store/profile/profile";
import {Alert} from "@material-ui/lab";
import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import {useStyles} from "controllers/token/form/FormToken/FormToken";
import __translate from "helper/locale/locale";
import {asyncVisitPayForm, onNoticeHide, reselectVisitsCondition,} from "controllers/visits/redux-store/visits/visits";
import {reselectConstants} from "../../../../controllers/constants-route/redux-store/constants/constants";
import {UserRoutePageParams} from "../../AccountLayout";
import {ReactComponent as LogoSVG} from "../../../../styles/results/icon/logo.svg";

const VisitPayPage: React.FC = (props:UserRoutePageParams) => {
    props?.conditionState?.[1]();
    const classes = useStyles();
    const visitCondition = useSelector(reselectVisitsCondition);
    const profileCondition = useSelector(reselect_Profile_condition);
    const reduxDispatch = useDispatch();
    const dataProfile = useSelector(reselect_Profile_entity);
    const params = useParams<{ ru: string; visit: any }>();
    const history = useHistory();
    const {
        user: { visit_user_statuses },
    } = useSelector(reselectConstants);

    React.useEffect(() => {
        if (!dataProfile) reduxDispatch(async_Profile());
        reduxDispatch(asyncVisitPayForm(params.visit));
    }, []);

    if (!dataProfile || profileCondition.inProgress || visitCondition.inProgress) return <Loader />;
    const profileVisit = dataProfile?.visits ? dataProfile.visits.find(v => v.id == params.visit) : null;
    if (!profileVisit) history.push(`${params.ru}/user/visits/${params.visit}/show`);
    return (
        <React.Fragment>
            <KDWSnackbar
                isOpen={visitCondition.notice.visibility}
                severity={visitCondition.notice.severity}
                handleOpen={() => {
                    reduxDispatch(onNoticeHide());
                }}
                message={visitCondition.response.error.message}
            />
            <Grid container className={classes.bg} alignItems="center" justify="center">
                <Grid item md={12} lg={6}>
                    <CssBaseline />
                    <Grid container alignItems="center" justify="center">
                        <Grid item md={7}>
                            <div className={classes.paper}>
                                <Grid item xs={12} md={12}>
                                    <Link href="https://kazandigitalweek.com" target="_blank" rel="noopener">
                                        <LogoSVG
                                            style={{
                                                color:"#008a71",
                                                display: "block",
                                                margin: "auto",
                                                height: 80,
                                            }}
                                            className={classes.logo}
                                        />
                                    </Link>
                                </Grid>
                                {!profileVisit && (
                                    <Container>
                                        <Typography>
                                            {__translate(
                                                "site.request_error",
                                                "Произошла ошибка при обращении к серверу",
                                            )}
                                        </Typography>
                                    </Container>
                                )}
                                {profileVisit && (
                                    <Container>
                                        <Grid item direction={"column"}>
                                            <Typography>
                                                {__translate("visits.visit_title", "Мероприятие")}:{" "}
                                                {profileVisit.name[params.ru]}.
                                            </Typography>
                                            <Typography>
                                                {__translate("profile.status_title", "Статус")}:{" "}
                                                {visit_user_statuses[profileVisit.visit_status.state]}
                                            </Typography>
                                        </Grid>
                                    </Container>
                                )}

                                {visitCondition?.response?.error.code >= 400 && (
                                    <Container>
                                        <Typography>{__translate("site.error", "Ошибка")}:</Typography>
                                        <Box maxWidth={300}>
                                            <Alert severity="error">{visitCondition.response.error.message}</Alert>
                                        </Box>
                                    </Container>
                                )}
                                {visitCondition?.response?.error?.code === 200 && (
                                    <Container>
                                        <Typography>
                                            {__translate(
                                                "profile.need_pay_visit",
                                                "Вам необходимо оплатить участие в мероприятии",
                                            )}
                                        </Typography>
                                    </Container>
                                )}
                                {visitCondition?.response?.data?.html ? (
                                    <div dangerouslySetInnerHTML={{ __html: visitCondition?.response?.data?.html }} />
                                ) : (
                                    visitCondition.inProgress && <Loader />
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default VisitPayPage;
