import {BACKEND_HOST, kyRequest, prepareResponse} from "api/api";
import {getFileFromUrl} from "../profile-route-post/api/profile-post/profile-post";
import {RegistrationRole} from "../../constants/constants";


export const getStartupApi = async (params?: any) => {
    let url = `${BACKEND_HOST}/startup/${params?.id || ''}`;
    const response = await kyRequest.post(url, {
        json: {...params, ['_method']:"GET"},
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return prepareResponse(response);
}

export const postStartupApi = async (data: any,) => {
    let formdata = new FormData()
    Object.keys({ ...data }).forEach(key => {
        let v = undefined;
        switch (key) {
            case 'logo':
            case 'ogrn':
            case 'business_file':
            case 'message_file':
            case 'pdf_file':
            case 'has_own':
            case 'acceleration':
            case 'has_investors':
            case 'expo_place': break
            case "acceleration_result":
            case "team": {
                (data[key]).forEach((user: any, i:number) => {
                    Object.keys(user).forEach((field) => {
                        if (user[field]){
                            formdata.append(key+`[${i}][${field}]`, user[field]);
                        }
                    })
                });
                break;
            }
            default:
                v = data[key];
        }
        if (v !== undefined) formdata.append(key, v as any);
    });

    ['has_own', 'acceleration', 'has_investors'].forEach((v) => {
        formdata.append(v, data[v] ? '1' : '0')
    })
    let f = data.pdf_file;
    f = await getFileFromUrl(f?.file, f?.name);
    f && formdata.append("pdf_file", f);
    f = data.business_file;
    f = await getFileFromUrl(f?.file, f?.name);
    f && formdata.append("business_file", f);
    f = data.message_file;
    f = await getFileFromUrl(f?.file, f?.name);
    f && formdata.append("message_file", f);
    if (data?.id) formdata.append('_method', 'PUT')
    const response = await kyRequest.post(`${BACKEND_HOST}/startup/${data.id || ''}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body:formdata
    });
    return prepareResponse(response);
};
