import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { apiGeolocationCities } from "controllers/geolocation-route/api/cities/cities";

export const slicerGeonamesCities = createSlice({
    name: "geonames-cities",
    initialState: {
        list: [] as Array<any>,
        condition: {
            inProgress: false,
            index: 0,
            response: {
                result: false,
                data: null,
                errors: null,
            },
        },
    },
    reducers: {
        onRequest: (state, action) => {
            state.list = [];
            state.condition.inProgress = true;
            state.condition.index = action.payload;
        },
        onResponseFail: state => {
            state.condition.inProgress = false;
            state.list = [];
        },
        onResponseSuccess: (state, action: PayloadAction<any>) => {
            state.condition.inProgress = false;
            state.list = Array.isArray(action.payload)
                ? action.payload.map((item: any) => {
                      return {
                          name: item.title,
                          value: item.id,
                          region: item.region,
                      };
                  })
                : [];
        },
    },
});

export const { onRequest, onResponseSuccess, onResponseFail } = slicerGeonamesCities.actions;

export const asyncGeolocationCities = (callback?: (result: any) => void) => async (
    dispatch: any,
    getState: () => RootState,
) => {
    let index = Date.now();
    dispatch(onRequest(index));
    const result = await apiGeolocationCities(
        getState().registration.view.city,
        getState().registration.middleware.country?.value || "",
        getState().registration.middleware.region?.value || "0",
    ).catch(e => console.error(e));
    if (result) {
        if (result && result.result) {
            dispatch(onResponseSuccess(result.data));
        } else {
            dispatch(onResponseFail());
        }
    } else {
        dispatch(onResponseFail());
    }
    if (callback) {
        callback(result);
    }
};

export const asyncGeolocationCitiesBase = (data: any, callback?: (result: any) => void) => async (dispatch: any) => {
    let index = Date.now();
    dispatch(onRequest(index));
    const result = await apiGeolocationCities(data.city, data.country_id || "0", "0").catch(e => console.error(e));
    if (result) {
        if (result && result.result) {
            dispatch(onResponseSuccess(result.data));
        } else {
            dispatch(onResponseFail());
        }
    } else {
        dispatch(onResponseFail());
    }
    if (callback) {
        callback(result);
    }
};

export const reselectGeonamesCitiesList = (state: RootState) => {
    return state.geonames.cities.list;
};

export const reselectGeonamesCitiesCondition = (state: RootState) => {
    return state.geonames.cities.condition;
};

export default slicerGeonamesCities.reducer;
