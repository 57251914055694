import { kyRequest, BACKEND_HOST, responseCallback } from "api/api";

/**
 * @route api/token
 * @post
 * @param {Speaker} formData
 */
export const apiSpeakerDelete = async (id: number) => {
    const response = await kyRequest
        .delete(`${BACKEND_HOST}/speaker/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
        .catch(e => {
            console.log("speaker", `${e.name}: ${e.message}`);
            return e;
        });
    return responseCallback(response);
};
