import React from "react";
import { NavLink, useParams, useHistory } from "react-router-dom";
import { AppBar, IconButton, Toolbar, Typography } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import { asyncTokenRevoke } from "../../controllers/token-revoke/redux-store/token-revoke/token-revoke";
import {__localeStorage, default as __locale} from "../../helper/location/location";
import { useDispatch, useSelector } from "react-redux";
import { reselect_Profile_entity } from "../../controllers/profile-route/redux-store/profile/profile";
import { MANAGER_ROLES } from "../../constants/constants";
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    admintitle: {
        fontSize: "18px",
        fontWeight: 600,
        flexGrow: 1,
    },
    navLink: {
        marginRight: "1.5em",
        color: "#ffffff",
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    activeLink: {
        textDecoration: "underline",
        marginRight: "1.5em",
        color: "#ffffff",
    },
    logo: {
        marginBottom: "1em",
        marginTop: "1em",
        marginRight: "2em",
        width: "125px",
        [theme.breakpoints.down(1200)]: {
            width: "200px",
            marginLeft: "1em !important",
        },
        [theme.breakpoints.down(958)]: {
            marginTop: "2em !important",
        },
    },
}));

const AdminMenu = () => {
    const classes = useStyles();
    const params = useParams<{ ru: string }>();
    const reduxDispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(reselect_Profile_entity);
    return (
        <React.Fragment>
            <AppBar position="static">
                <Toolbar>
                    <img alt="logo" src="/static/logo.png" className={classes.logo} />
                    <Typography variant="h6" className={classes.admintitle}>
                        Панель администратора
                    </Typography>
                    <NavLink className={classes.navLink} to={`/${params.ru}/user/personal-data`}>
                        Личный кабинет
                    </NavLink>
                    <NavLink className={classes.navLink} to={`/${params.ru}/admin`}>
                        Рабочий стол
                    </NavLink>
                    <NavLink className={classes.navLink} to={`/${params.ru}/admin/users/1`}>
                        Участники
                    </NavLink>
                    {(user?.user_type === "admin" || 0 == (user?.role & MANAGER_ROLES.USER_ROLE_MANAGER)) && (
                        <NavLink className={classes.navLink} to={`/${params.ru}/admin/program`}>
                            Программа
                        </NavLink>
                    )}
                    {user?.user_type === "admin" && (
                        <>
                            <NavLink className={classes.navLink} to={`/${params.ru}/admin/tariff`}>
                                Тарифы
                            </NavLink>
                            <NavLink className={classes.navLink} to={`/${params.ru}/admin/expo/1`}>
                                Каталог
                            </NavLink>
                            <NavLink className={classes.navLink} to={`/${params.ru}/admin/parts/1`}>
                                Разделы
                            </NavLink>
                        </>
                    )}
                    <NavLink
                        className={classes.navLink}
                        to="#"
                        onClick={() => {
                            reduxDispatch(
                                asyncTokenRevoke(result => {
                                    history.push(`/${__localeStorage()}/login`);
                                }),
                            );
                        }}>
                        Выйти
                    </NavLink>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
};
export default AdminMenu;
