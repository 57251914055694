import {BACKEND_HOST, kyRequest, responseCallback} from "api/api";
import {User} from "controllers/admin-users/api/admin-users/admin-users";
import {
    FILE_STORAGE_ADMIN_GET_User,
    Type_Proxy_User,
} from "controllers/admin-user-get/redux-store/admin-user-get/admin-user-get";
import {format} from "date-fns";
import {RegistrationAcademicDegree, RegistrationRole} from "constants/constants";
import {getFileFromUrl} from "../../../profile-route-post/api/profile-post/profile-post";
export const prepare_FormData_User_CU = async (
    entity_User: User,
    proxyEntity_User: Type_Proxy_User,
    storage_Files: Record<string, any>,
    dirty?: any[],
) => {
    const formUser = new FormData();
    const user = {...entity_User} as any;
    // boolean
    Object.keys(dirty).forEach(key => {
        if (user[key] === undefined) return;
        if ((key == 'eat_type' || key == 'souvenir') && user[key] == null) return formUser.append(key, '');
        formUser.append(key, user[key]);
        typeof user[key] === "boolean" &&
            formUser.set(key, user[key] ? "1" : "0");
        Array.isArray(user[key]) &&
            user[key].forEach((v: any, i: number) => {
                formUser.set(key + "[" + i + "]", v + "");
            });
    });
    formUser.append("birthday", user.birthday ? format(new Date(user.birthday), "yyyy-MM-dd") : "");
    if (user.academic_degree !== null) {
        if (Number(user.academic_degree) === RegistrationAcademicDegree.OTHER) {
            formUser.append("academic_degree_other", user.academic_degree_other || "");
        }
    }
    if (storage_Files.photo) {
        let fromUrl;
        if (typeof storage_Files.photo.file == "string") {
            fromUrl = await getFileFromUrl(storage_Files.photo.file, storage_Files.photo.name);
        } else {
            fromUrl = storage_Files.photo;
        }
        formUser.append("photo", fromUrl as any);
    }
    if (Object.keys(dirty).findIndex(v => v === "amount") > -1) {
        formUser.append("need_pay", proxyEntity_User.need_pay ? "1" : "0");
        formUser.append("amount", proxyEntity_User.amount || "0");
    }
    // ~~~~~~~
    if (proxyEntity_User.forum_themes) {
        const keys = Object.values(proxyEntity_User.forum_themes);
        keys.forEach(theme => {
            formUser.append("forum_themes[]", theme);
        });
    }
    // ~~~~~~~ ROLE: SPEAKER ~~~~~~~ TODO: не используетс больше в редактировании пользователя
    if (Number(user.role) === RegistrationRole.SPEAKER) {
        if (proxyEntity_User.report_forum_theme !== null) {
            formUser.append("report[forum_theme]", proxyEntity_User.report_forum_theme);
        }
        if (proxyEntity_User.report_event_id > 0) {
            formUser.append("report[event_id]", proxyEntity_User.report_event_id);
        }
        if (storage_Files.annotation_file && storage_Files.annotation_file[0]) {
            formUser.append("report[annotation_file]", storage_Files.annotation_file[0], "report.pdf");
        }
        if (proxyEntity_User.report_title) {
            formUser.append("report[title]", proxyEntity_User.report_title);
        }
    }
    return formUser;
};

export const api_Admin_User_Put = async (
    userIndex: User["id"],
    data: User,
    proxyUser: any,
    adminRole?: string | null,
    dirty?: any[],
) => {
    const formdata_User = await prepare_FormData_User_CU(data, proxyUser, FILE_STORAGE_ADMIN_GET_User, dirty);
    formdata_User.append("_method", "PUT");
    if (adminRole) formdata_User.append("admin_role", adminRole);
    const response = await kyRequest.post(`${BACKEND_HOST}/user/${userIndex}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: formdata_User,
    });
    return responseCallback(response, false, true);
};
