import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "redux-store/index";
import {Color} from "@material-ui/lab/Alert";
import {User} from "controllers/admin-users/api/admin-users/admin-users";

export type Response = {
    inProgress: boolean;
    response: {
        result: boolean;
        data: any;
        errors: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};

export type TYPE_InitialState_FilterUsers = {
    filter: {
        role?: any;
        state?: any;
        doc_state?: any;
        surname: User["surname"];
        name: User["name"];
        limit?: any;
        email: User["email"];
        lastname: User["lastname"];
        min_date: string;
        max_date: string;
        forum_themes: any[];
        region: User["region"];
        city: User["city"];
        work_position: User["participation_data"]["work_position"];
        place_of_work: User["participation_data"]["place_of_work"];
        sector: User["participation_data"]["sector"];
        country: User["country"];
        is_vip: User["options"]["is_vip"];
        is_draft: boolean;
        with_request: boolean;
        admin_role: any;
    };
    condition: Response;
};

export const slicer_filter_users = createSlice({
    name: "filter-users",
    initialState: {
        filter: {
            surname: "",
            name: "",
            email: "",
            lastname: "",
            min_date: null,
            max_date: null,
            region: "",
            city: "",
            work_position: "",
            place_of_work: "",
            sector: null as number,
            country: "",
            state: "",
            is_vip: null,
            limit: null,
            is_draft: null,
            with_request: null,
            admin_role: null,
        },
        condition: {
            inProgress: false,
            notice: {
                visibility: false,
                severity: "error",
            },
            response: {
                result: false,
                data: null,
                errors: null,
                message: "",
            },
        } as Response,
    } as TYPE_InitialState_FilterUsers,
    reducers: {
        on_Change_FilterUsers: (state,
                                action: PayloadAction<{ name: string; value: any; checked?: boolean }>) => {
            let { value, name, checked } = action.payload;
            switch (name) {
                case "with_request":
                case "is_draft":
                case "is_vip": {
                    //@ts-ignore
                    state.filter[name] = checked;
                    break;
                }
                default: {
                    //@ts-ignore
                    state.filter[name] = value;
                    break;
                }
            }
        },
        on_Select_FilterUsers: (
            state,
            action: PayloadAction<{ name: keyof TYPE_InitialState_FilterUsers["filter"]; value: string }>,
        ) => {
            const { value, name } = action.payload;
            switch (name) {
                case "country":
                case "city":
                case "region": {
                    state.filter[name] = value;
                    break;
                }
            }
        },
        on_CleanCondition: state => {
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        on_Request: state => {
            state.condition.inProgress = true;
        },
        on_NoResponse: state => {
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        on_ResponseFailure: (state, action) => {},
        on_ResponseSuccess: (state, action) => {},
        on_NoticeHide: state => {
            state.condition.notice.visibility = false;
        },
    },
});

export const {
    on_CleanCondition,
    on_Request,
    on_NoResponse,
    on_ResponseSuccess,
    on_ResponseFailure,
    on_NoticeHide,
    on_Change_FilterUsers,
    on_Select_FilterUsers,
} = slicer_filter_users.actions;


export const reselect_FilterUsers_condition = (state: RootState) => {
    return state.filterUsers.condition;
};
export const reselect_FilterUsers_filter = (state: RootState): TYPE_InitialState_FilterUsers["filter"] => {
    return state.filterUsers.filter;
};

export default slicer_filter_users.reducer;
