import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";

import { Response } from "../admin-users/redux-store/admin-users/admin-users";
import { deletePost, getPost, getPosts, savePost } from "./api";
import { initialCondition } from "../admin-speaker/admin-speaker-post/redux-store/admin-speaker-post/admin-speaker-post";
import { LangEnum } from "../admin-expo/enums";
import {makeBaseReduxAction} from "../../helper/redux";

export const slicerPosts = createSlice({
    name: "posts",
    initialState: {
        items: [] as any[],
        current: null as any,
        condition: { ...initialCondition },
    },
    reducers: {
        onNoticeHide: state => {
            state.condition.notice.visibility = false;
        },
        onItem: (state, action) => {
            state.current = action.payload;
        },
        onRequest: state => {
            state.condition = {
                ...initialCondition,
                inProgress: true,
            } as Response;
        },
        setItems: (state, action) => {
            const items = action.payload as any[];
            state.items = [...items];
        },
        onSuccess: (state, action: { payload: any }) => {
            state.condition = {
                ...initialCondition,
                notice: {
                    visibility: true,
                    severity: "success",
                },
                response: {
                    ...action.payload,
                },
            };
        },
        onFail: (state, action: { payload: any }) => {
            state.condition = {
                ...initialCondition,
                notice: {
                    visibility: true,
                    severity: "error",
                },
                response: {
                    ...action.payload,
                    result: false,
                    data: {},
                    message: action.payload.error.message,
                    errors: action.payload.error.code === 400 ? action.payload.data : null,
                },
            };
        },
        stopLoading: state => {
            state.condition.inProgress = false;
        },
    },
});

export const { onRequest, onFail, onNoticeHide, onItem, setItems, onSuccess, stopLoading } = slicerPosts.actions;

const baseStateAsync = makeBaseReduxAction((dispatch:any) => {
    dispatch(onNoticeHide());
    dispatch(onRequest());
}, (dispatch, r) => {
    if (r) {
        dispatch(onFail(r));
    }
}, (dispatch) => {
    dispatch(stopLoading());
});

export const asyncGetPosts = (params?: any, callback?: (result: any) => void) => {
    return baseStateAsync(getPosts, params, callback, (dispatch: any, result: any) => {
        dispatch(setItems(result.data));
    });
};
export const asyncGetPost = (params?: any, callback?: (result: any) => void) => {
    return baseStateAsync(getPost, params, callback, (dispatch: any, result: any) => {
        dispatch(onItem(result.data));
    });
};
export const asyncSavePost = (post?: any, lang?: LangEnum, callback?: (result: any) => void) => {
    return baseStateAsync(savePost, { ...post, lang }, callback, (dispatch: any, result: any) => {
        dispatch(onItem(result.data));
    });
};
export const asyncDeletePost = (post?: any, callback?: (result: any) => void) => {
    return baseStateAsync(deletePost, post, callback, (dispatch: any, result: any) => {
        dispatch(stopLoading());
    });
};
export const reselectPosts = (state: RootState) => {
    return state.posts.items;
};
export const reselectCurrentPost = (state: RootState) => {
    return state.posts.current;
};
export const reselectPostsCondition = (state: RootState) => {
    return state.posts.condition;
};
export default slicerPosts.reducer;
