import {LangEnum} from "controllers/admin-expo/enums";

const __locale = () => {
    let uri = window.location.pathname.split("/")[1];
    if ([LangEnum.RU, LangEnum.EN, LangEnum.CN].findIndex(v => v === uri) == -1) uri = null;
    return uri || __localeStorage();
};
export const __localeStorage = () => {
    return localStorage.getItem("locale") || "ru";
};

export default __locale;
