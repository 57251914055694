import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { Color } from "@material-ui/lab/Alert";
import { ExpoWithoutLang } from "controllers/admin-expo/interfaces/admin-expo";
import { apiExpoDelete } from "../api/admin-expo-delete";

type Response = {
    inProgress: boolean;
    response: {
        result: boolean;
        data: any;
        errors: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};

type InitialState = {
    view: ExpoWithoutLang;
    condition: Response;
};

const initialExpo: ExpoWithoutLang = {
    name: "",
    logo: null,
    forum_themes: [],
    info: "",
    video: "",
    stand: "",
    address: "",
    email: "",
    phone: "",
    site: "",
    state: "",
    priority: "",
    presentation: null,
};

const initialState: InitialState = {
    view: initialExpo,
    condition: {
        inProgress: false,
        notice: {
            visibility: false,
            severity: "error",
        },
        response: {
            result: false,
            data: null,
            errors: null,
            message: "",
        },
    },
};

export const slicerDeleteExpo = createSlice({
    name: "deleteExpo",
    initialState,
    reducers: {
        onRequest: state => {
            console.log("onRequest");
            state.condition = {
                inProgress: true,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onConditionClear: state => {
            state.condition = {
                notice: {
                    visibility: false,
                    severity: "error",
                },
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onNoResponse: state => {
            console.log("onNoResponse");
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        onResponseFail: (state, action: PayloadAction<{ message: string }>) => {
            console.log("onResponseFail", action);
            console.log("onResponseFail", typeof action);
            const response = typeof action.payload === "string" ? JSON.parse(action.payload) : action.payload;
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: response ? response.message : "",
                },
                notice: {
                    visibility: true,
                    severity: "error",
                },
            };
        },
        onResponseSuccess: (
            state,
            action: PayloadAction<{
                message: string;
                result: boolean;
                data: {
                    access_token: string;
                    expires_at: string;
                    token_type: "Bearer";
                    type: string;
                };
            }>,
        ) => {
            state.view = initialExpo;
            state.condition = {
                inProgress: false,
                response: {
                    result: action.payload.result,
                    data: action.payload.data,
                    message: action.payload.message,
                    errors: null,
                },
                notice: {
                    visibility: true,
                    severity: "success",
                },
            };
        },
        onNoticeHide: state => {
            state.condition.notice.visibility = false;
        },
        onSetDeleteExpo: (state, action: PayloadAction<ExpoWithoutLang>) => {
            state.view = action.payload;
        },
    },
});

export const {
    onRequest,
    onResponseSuccess,
    onResponseFail,
    onNoticeHide,
    onConditionClear,
    onSetDeleteExpo,
} = slicerDeleteExpo.actions;

export const asyncDeleteExpo = (callback?: (result: any) => void) => async (
    dispatch: any,
    getState: () => RootState,
) => {
    dispatch(onRequest());
    const view = getState().adminExpo.delete.view;
    let result = await apiExpoDelete(view?.id).catch(e => console.error(e));
    if (result) {
        if (result.result) {
            dispatch(onResponseSuccess(result));
        } else {
            dispatch(onResponseFail(result));
        }
    } else {
        dispatch(onResponseFail(result));
    }
    if (callback) {
        callback(result);
    }
};

export const reselectExpoDelete = (state: RootState) => {
    return state.adminExpo.delete.view;
};
export const reselectExpoDeleteCondition = (state: RootState) => {
    return state.adminExpo.delete.condition;
};

export default slicerDeleteExpo.reducer;
