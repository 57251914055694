import {kyRequest, BACKEND_HOST, responseCallback, prepareResponse} from "api/api";
import { downloadBlob } from "helper/blob/blob";
import { serializeObjectToQueryParams } from "helper/query";
import { ExpoFilter } from "../redux-store/admin-expo-get-list";

export const apiAdminExpoGet = async (
    id?: any,
) => {
    let url = `${BACKEND_HOST}/exponent/${id}`;
    const response = await kyRequest.get(url, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return prepareResponse(response);
}
export const apiAdminExpoIndex = async (
    page: number,
    filterParams?: ExpoFilter,
    exportXLS?: boolean,
    per_page?: number,
) => {
    let url = `${BACKEND_HOST}/exponent?page=${page}&per_page=${per_page || 16}`;
    if (filterParams) {
        let filter: any = {};
        if (filterParams.name) filter["name"] = filterParams.name;
        if (filterParams.forum_themes)
            for (let i = 0; i < filterParams.forum_themes.length; i++) {
                filter[`forum_themes[${i}]`] = filterParams.forum_themes[i];
            }
        if (filterParams.types)
            for (let i = 0; i < filterParams.types?.length; i++) {
                filter[`types[${i}]`] = filterParams.types[i];
            }
        if (filterParams.priority_desc) filter["priority_desc"] = filterParams.priority_desc;
        if (filterParams.itleader) filter["itleader"] = '1';
        const data = serializeObjectToQueryParams(filter);
        url = url + "&" + data;
        if (filterParams.state >= 0) url += "&state=" + filterParams.state;
    }

    if (exportXLS) {
        url = url + "&export=1";
    }
    const response = await kyRequest.get(url, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    if (exportXLS) {
        response.blob().then(blob => downloadBlob(blob, "kdw_expo.xls"));
    }
    return responseCallback(response);
};
