import { kyRequest, BACKEND_HOST, responseCallback } from "api/api";

/**
 * @route api/cost/cost_id
 * @get
 * @post
 */

export const apiPriceEdit = async (data: any) => {
    const formData = new FormData();
    formData.append("cost", data.cost);
    formData.append("_method", "PUT");
    formData.append("online", Number(data.online).toString());
    const response = await kyRequest.post(`${BACKEND_HOST}/cost/${data.id}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: formData,
    });
    return responseCallback(response);
};

export const apiPriceCreate = async (data: any) => {
    const formData = new FormData();
    formData.append("cost", data.cost);
    formData.append("role", data.role);
    formData.append("online", Number(data.online).toString());
    const response = await kyRequest.post(`${BACKEND_HOST}/cost`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: formData,
    });
    return responseCallback(response);
};
