import { kyRequest, BACKEND_HOST, responseCallback, kyRequestBase } from "api/api";
import { format } from "date-fns";
import { getFileFromUrl } from "../../../profile-route-post/api/profile-post/profile-post";
import { downloadBlob } from "../../../../helper/blob/blob";

/**
 * @route api/profile
 * @get
 */
export const visitsSearch = async (params: any) => {
    const data = { ...params };
    if (!Number.isInteger(params.type) || params.type < 0) delete data.type;
    const response = await kyRequest.get(`${BACKEND_HOST}/visit?` + new URLSearchParams(data).toString(), {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return responseCallback(response, false, true);
};
/**
 * @route api/profile
 * @get
 */
export const visitById = async (id: any) => {
    const response = await kyRequest.get(`${BACKEND_HOST}/visit/${id}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return responseCallback(response, false, true);
};
/**
 * @route api/profile
 * @get
 */
export const visitUsersById = async (id: any, page: number, per_page?: number, state?: any) => {
    const response = await kyRequest.get(
        `${BACKEND_HOST}/visit/${id}/user?page=${page}${per_page ? "&per_page=" + per_page : ""}${
            state ? "&state=" + state : ""
        }`,
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        },
    );
    return responseCallback(response, false, true);
};

export const visitUsersExport = async (id?: number) => {
    const response = await kyRequest.get(`${BACKEND_HOST}/visit/${id || '0'}/user?export=1`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        timeout: 300000,
    });
    response.blob().then(blob => downloadBlob(blob, "visit_users.xlsx"));
};

/**
 * @route api/profile
 * @get
 */
export const changeVisitUserStatus = async (visit_id: any, user_id: any, state: any) => {
    let data = new FormData();
    data.append("_method", "PUT");
    data.append("state", state);
    const response = await kyRequest.post(`${BACKEND_HOST}/visit/${visit_id}/user/${user_id}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: data,
    });
    return responseCallback(response, false, true);
};
/**
 * @route api/profile
 * @get
 */
export const deleteVisitById = async (id: any) => {
    const response = await kyRequest.delete(`${BACKEND_HOST}/visit/${id}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return responseCallback(response, false, true);
};
export const saveVisit = async (visit: any, lang: string) => {
    const data = new FormData();
    Object.keys(visit).forEach(key => {
        if (key === "date" || key === "end_date") {
            let date = null;
            try {
                date = format(new Date(visit[key] * 1000), "dd.MM.yyyy HH:mm");
            } catch (e) {}
            data.append(key, date ? date : "xx.xx.xxxx xx:xx");
        } else if (key === "photos_to_delete") {
            visit[key].forEach((v: any, index: Number) => {
                data.append(`${key}[${index}]`, v);
            });
        } else if (key !== "photos") data.append(key, visit[key]);
    });
    if (visit.id > 0) data.append("_method", "PUT");
    const response = await responseCallback(
        await kyRequestBase.post(`${BACKEND_HOST}/visit/${visit.id || ""}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                "Accept-Language": lang,
            },
            body: data,
        }),
        false,
        true,
    );
    if (visit?.photos?.length > 0) {
        const id = visit.id || response.data.id;
        data.set("_method", "PUT");
        let promises = [] as any;
        await visit.photos.forEach((v: any, index: Number) => {
            v &&
                promises.push(
                    getFileFromUrl(v.photo.file, v.photo.name).then(r => {
                        r && data.append(`photos[${index}]`, r);
                    }),
                );
        });

        await Promise.all(promises).finally(() => {
            console.log("appended");
            const response2 = kyRequestBase.post(`${BACKEND_HOST}/visit/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
                body: data,
            });
        });
    }

    return response;
};
/**
 * @route api/profile
 * @get
 */
export const recordVisit = async (id: any) => {
    const response = await kyRequest.post(`${BACKEND_HOST}/user/visit/${id}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return responseCallback(response, false, true);
};

/**
 * @route api/profile
 * @get
 */
export const recordVisitCancel = async (id: any) => {
    const response = await kyRequest.delete(`${BACKEND_HOST}/user/visit/${id}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return responseCallback(response, false, true);
};
/**
 * @route api/profile
 * @get
 */
export const visitPayForm = async (id: any) => {
    const response = await kyRequest.get(`${BACKEND_HOST}/user/visit/${id}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return responseCallback(response, false, true);
};
