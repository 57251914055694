import { kyRequest, BACKEND_HOST, responseCallback } from "api/api";



/**
 * @route api/confirmation
 * @post
 * @param  formData
 */
export const apiRouteConfirmation = async (formData: any) => {
    const response = await kyRequest.post(`${BACKEND_HOST}/confirmation`, {
        json: formData,
    });
    return responseCallback(response);
};
export const apiSendConfirmationLink = async () => {
    const response = await kyRequest.get(`${BACKEND_HOST}/confirmation?path=`+window.location.pathname, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        }
    });
    return responseCallback(response);
};
