import { kyRequest, BACKEND_HOST, responseCallback } from "api/api";
import { User } from "controllers/admin-users/api/admin-users/admin-users";
import {
    FILE_STORAGE_ADMIN_GET_User,
    Type_Proxy_User,
} from "controllers/admin-user-get/redux-store/admin-user-get/admin-user-get";
import { prepare_FormData_User_CU } from "controllers/admin-user-put/api/admin-user-put/admin-user-put";

/**
 * @route api/user
 * @post
 * @post
 */
export const apiUserPost = async (userIndex: User["id"], data: User) => {
    const formData = new FormData();
    data.doc_state && formData.append("doc_state", data.doc_state +'');
    data.hasOwnProperty("accept") && formData.append("accept", data.accept ? "1" : "0");
    data.hasOwnProperty("is_blocked") && formData.append("is_blocked", data.is_blocked ? "1" : "0");
    formData.append("covid_state", data.covid_state ? "1" : "0");
    formData.append("_method", "PUT");
    const response = await kyRequest.post(`${BACKEND_HOST}/user/${userIndex}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: formData,
    });
    return responseCallback(response);
};

/**
 * @route api/user
 * @post
 * @post
 */
export const apiUserPost_create = async (
    data: User,
    proxyUser: Type_Proxy_User,
    adminRole?: string | null,
    dirty?: any[],
) => {
    const formData = await prepare_FormData_User_CU(data, proxyUser, FILE_STORAGE_ADMIN_GET_User, dirty);
    if (adminRole) formData.append("admin_role", adminRole);

    const response = await kyRequest.post(`${BACKEND_HOST}/user`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: formData,
    });
    return responseCallback(response, false, true);
};
export const apiUserImport = async (
    file:File, message:string
) => {
    const formData =  new FormData();
    formData.append('file' , file);
    formData.append('message' , message);


    const response = await kyRequest.post(`${BACKEND_HOST}/user/import`, {
        timeout: 300000,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: formData,
    });
    return responseCallback(response, false, true);
};
