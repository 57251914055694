import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { TypeRequestToken } from "controllers/token/api/token/token";
import { apiTokenRevoke } from "controllers/token-revoke/api/token-revoke/token-revoke";
import { Color } from "@material-ui/lab/Alert";

type Response = {
    inProgress: boolean;
    response: {
        result: boolean;
        data: any;
        errors: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};
export const slicerTokenRevoke = createSlice({
    name: "token-revoke",
    initialState: {
        view: {
            email: "",
            password: "",
            rememberMe: false,
        } as TypeRequestToken,
        condition: {
            inProgress: false,
            notice: {
                visibility: false,
                severity: "error",
            },
            response: {
                result: false,
                data: null,
                errors: null,
                message: "",
            },
        } as Response,
    },
    reducers: {
        onConditionClear: state => {
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onRequest: state => {
            state.condition.inProgress = true;
        },
        onNoResponse: state => {
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        onResponseFail: (state, action: PayloadAction<string | any>) => {
            state.condition.inProgress = false;
            if (action.payload.error && action.payload.error.code === 401) {
                localStorage.removeItem("access_token");
                localStorage.removeItem("expires_at");
                localStorage.removeItem("type");
                localStorage.removeItem("token_type");
            }
            if (typeof action.payload === "string") {
                state.condition.response = {
                    result: false,
                    data: null,
                    errors: null,
                    message: action.payload,
                };
                state.condition.notice = {
                    severity: "error",
                    visibility: true,
                };
            }
        },
        onResponseSuccess: (
            state,
            action: PayloadAction<{
                data: boolean;
                message: string;
                result: boolean;
            }>,
        ) => {
            state.condition = {
                inProgress: false,
                response: {
                    result: action.payload.result,
                    data: null,
                    message: action.payload.message,
                    errors: null,
                },
                notice: {
                    severity: "success",
                    visibility: true,
                },
            };
            localStorage.removeItem("access_token");
            localStorage.removeItem("expires_at");
            localStorage.removeItem("type");
            localStorage.removeItem("token_type");
        },
        onNoticeHide: state => {
            state.condition.notice.visibility = false;
        },
        onChange: (state, action) => {
            let ref;
            const nameRoute = action.payload.name.split(".");
            const fieldName = nameRoute.pop();
            ref = nameRoute.reduce((acc: any, item: string) => {
                // @ts-ignore
                return acc[item];
            }, state.view);
            if (action.payload.hasOwnProperty("checked")) {
                ref[fieldName] = action.payload.checked;
            } else {
                ref[fieldName] = action.payload.value;
            }
        },
    },
});

export const {
    onConditionClear,
    onRequest,
    onNoResponse,
    onResponseSuccess,
    onResponseFail,
    onChange,
    onNoticeHide,
} = slicerTokenRevoke.actions;

export const asyncTokenRevoke = (callback?: (result: any) => void) => async (dispatch: any) => {
    dispatch(onRequest());
    const result = await apiTokenRevoke().catch(e => {
        // dispatch(onResponseCatch(`${e.name}: ${e.message}`));
    });
    if (result) {
        await dispatch(result.result ? onResponseSuccess(result) : onResponseFail(result));
    } else {
        dispatch(onNoResponse());
    }
    if (callback) {
        callback(result);
    }
};

export const reselectTokenRevoke = (state: RootState) => {
    return state.tokenRevoke.view;
};
export const reselectTokenRevokeCondition = (state: RootState): Response => {
    return state.tokenRevoke.condition;
};

export default slicerTokenRevoke.reducer;
