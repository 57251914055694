import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "redux-store/index";
import {
    KdwCondition,
    KdwCondition as Condition,
    makeBaseReduxAction,
    makeClearCondition,
    makeConditionReducers
} from "helper/redux"
import expoRegApi from "./api";


export type TypeExpoRegistration = {
    inn: string;
    egrul_date: any;
    exponent: any;
    contact_name: string;
    contact_phone: string;
    title: string;
    description: string;
};

export const DataExpoRegistration: TypeExpoRegistration = {
    inn: "",
    egrul_date: null as any,
    exponent: null as any,
    contact_name: "",
    contact_phone: "",
    title: "",
    description: "",
};
export const slicerRegistration = createSlice({
    name: "expo_reg",
    initialState: {
        form: {
            ...DataExpoRegistration,
        } as TypeExpoRegistration,
        condition: makeClearCondition(),
    },
    reducers: {
        ...makeConditionReducers(),
        successResponse: (state, action) =>{
            state.condition = {
                ...state.condition,

            }
        },
        onChange: (s, a) => {
            s.form = {
                ...s.form,
                [a.payload.target.name as keyof TypeExpoRegistration] : a.payload.target.value,
            }
        }
    },
});

export const {
    onNoticeHide,
    onRequest,
    stopLoading,
    onFail,
    clearCondition,
    successResponse,
    onChange,
} = slicerRegistration.actions;

const baseStateAsync = makeBaseReduxAction((dispatch: any) => {
    dispatch(onNoticeHide());
    dispatch(onRequest());
}, (dispatch, r) => {
    if (r) {
        dispatch(onFail(r));
    }
}, (dispatch) => {
    dispatch(stopLoading());
});

export const asyncExpoFormChange= (formId: number, params: {state:number, nomination: string|number}, callback?: (result?:any) => void) => {
    return baseStateAsync(
        (params:any) => expoRegApi.expoFormChange(formId, params),
        params,
        callback,
        (dispatch, response) => {
            dispatch(successResponse(response));
        }
    );
}
export const asyncExpoRegistration = (params: TypeExpoRegistration, callback?: (result?:any) => void) => {
    return baseStateAsync(
        expoRegApi.expoRegistration,
        params,
        callback,
        (dispatch, response) => {
            dispatch(successResponse(response));
        }
    );
}

export const reselectExpoRegistrationForm = (state: RootState) => {
    return state.expo_reg.form;
};
export const reselectExpoRegistrationCondition = (state: RootState) => {
    return state.expo_reg.condition;
};

export default slicerRegistration.reducer;
