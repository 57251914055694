import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { Color } from "@material-ui/lab/Alert";
import { apiUserPost, apiUserPost_create } from "controllers/admin-user-post/api/admin-user-post/admin-user-post";
import { User } from "controllers/admin-users/api/admin-users/admin-users";

export type Response = {
    inProgress: boolean;
    index: number | string;
    errors: Record<string, Array<string>>;
    response: {
        result: boolean;
        data: any;
        errors: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};
export const slicerAdminUserPost = createSlice({
    name: "admin-user-post",
    initialState: {
        entity: {},
        condition: {
            errors: {},
            index: 0,
            inProgress: false,
            notice: {
                visibility: false,
                severity: "error",
            },
            response: {
                result: false,
                data: null,
                errors: null,
                message: "",
            },
        } as Response,
    },
    reducers: {
        onConditionClear: state => {
            state.condition = {
                errors: {},
                index: 0,
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onRequest: (state, action) => {
            state.condition = {
                errors: {},
                index: action.payload,
                inProgress: true,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onNoResponse: state => {
            state.condition = {
                index: state.condition.index,
                errors: {},
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        onResponseFail: (
            state,
            action: PayloadAction<string> | PayloadAction<{ data: any; errors: any; message: string; result: boolean }>,
        ) => {
            state.condition.inProgress = false;
            if (typeof action.payload === "string") {
                state.condition = {
                    errors: {},
                    index: state.condition.index,
                    inProgress: false,
                    response: {
                        result: false,
                        data: null,
                        errors: null,
                        message: action.payload,
                    },
                    notice: {
                        severity: "error",
                        visibility: true,
                    },
                };
            } else {
                state.condition = {
                    index: state.condition.index,
                    errors: {},
                    inProgress: false,
                    response: {
                        result: false,
                        data: null,
                        errors: null,
                        message: `User with id: ${state.condition.index} ${action.payload.message}`,
                    },
                    notice: {
                        severity: "error",
                        visibility: true,
                    },
                };
            }
        },
        // message: "responses.controllers.participant.update"
        // result: true
        onResponseSuccess: (
            state,
            action: PayloadAction<{
                data: User;
                message: string;
                result: boolean;
            }>,
        ) => {
            state.condition = {
                index: state.condition.index,
                errors: {},
                inProgress: false,
                response: {
                    result: action.payload.result,
                    data: action.payload.data,
                    message: `User ${action.payload.data.id}: ${(action.payload as any)?.error?.message}`,
                    errors: null,
                },
                notice: {
                    severity: "success",
                    visibility: true,
                },
            };
        },
        onNoticeHide: state => {
            state.condition.notice.visibility = false;
        },
        on_Response_400: (
            state,
            action: PayloadAction<{
                data: Record<string, Array<string>>;
                error: { message: string; code: number };
                nativeEvent: any;
            }>,
        ) => {
            state.condition = {
                errors: action.payload.data,
                index: 0,
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: action.payload.data,
                    message: `${action.payload.error.message}`,
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
    },
});

export const {
    onConditionClear,
    onRequest,
    onNoResponse,
    onResponseSuccess,
    onResponseFail,
    onNoticeHide,
    on_Response_400,
} = slicerAdminUserPost.actions;


export const asyncUserPost = (user: User, callback?: (result: any) => void) => async (
    dispatch: any,
) => {
    dispatch(onRequest(user.id));
    const result = await apiUserPost(user.id, user).catch(e => {
        console.log(e);
    });
    if (result) {
        if (result.result) {
            dispatch(onResponseSuccess(result));
        } else {
            switch (result.error?.code) {
                case 400: {
                    dispatch(onResponseFail(result));
                    break;
                }
            }
            dispatch(onResponseFail(result))
        }
    } else {
        dispatch(onNoResponse());
    }
    if (callback) {
        callback(result);
    }
};

export const asyncUserPost_create = (callback?: (result: any) => void, adminRole?: string | null) => async (
    dispatch: Dispatch,
    getState: () => RootState,
) => {
    dispatch(onRequest(0));
    const dirty = getState().userGet.dirty;
    const user = getState().userGet.view;
    const proxyUser = getState().userGet.proxyEntity;
    const result = await apiUserPost_create(user, proxyUser, adminRole, dirty).catch(e => {
        // dispatch(onResponseCatch(`${e.name}: ${e.message}`));
        console.log(e);
    });
    if (result) {
        if (result.result) {
            dispatch(onResponseSuccess(result));
        } else {
            switch (result.error.code) {
                case 400: {
                    dispatch(on_Response_400(result));
                    break;
                }
            }
        }
    } else {
        dispatch(onNoResponse());
    }
    if (callback) {
        callback(result);
    }
};

export const reselectUserPostCondition = (state: RootState): Response => {
    return state.userPost.condition;
};

export default slicerAdminUserPost.reducer;
