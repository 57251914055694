import React from "react";
import { useParams } from "react-router-dom";
import { Typography, TypographyProps } from "@material-ui/core";
import __locale from "../../helper/location/location";
import { LangEnum } from "../../controllers/admin-expo/enums";

export const localeField = (event: any, field: string) => {
    if (!event || !event[field]) return null;
    // if (event.field && Object.keys(event.field) === ['ru','en', 'cn'])
    return event[field][__locale()] || event[field][LangEnum.RU];
};

export const valueLocale = (value: any, locale: string) => {
    if (value && typeof value === "object") {
        if (value.hasOwnProperty(locale)) {
            return value[locale];
        }
    }
    return null;
};

const TypographyLocale: React.FC<{ value: any; props?: TypographyProps }> = ({ value, props = {} }) => {
    const params = useParams<{ ru: string }>();
    const v = valueLocale(value, params.ru);
    return v ? (
        <Typography component="span" {...props}>
            {v}
        </Typography>
    ) : (
        v
    );
};

export default TypographyLocale;
