import React from "react";
import __translate from "helper/locale/locale";
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import FormHelperText from "@material-ui/core/FormHelperText";

type Props = {
    error?: string;
    disabled?: boolean;
    required?: boolean;
    fullWidth?: boolean;
    onChange: (event: any) => void;
    value: any;
    validator?: (n:string) => string;
    name?: string;
    label?: string;
    variant?: "outlined" | "standard" | "filled";
};
const PasswordTextField: React.FC<Props> = ({
    error = "",
    disabled = false,
    required = false,
    validator = (n:string) => '',
    fullWidth = false,
    onChange,
    name = "date",
    variant = "outlined",
    label = __translate("login.pwd", "Пароль"),
    value,
}) => {
    const [type, setType] = React.useState("password");
    const handleClickShowPassword = () => {
        setType(type === "text" ? "password" : "text");
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    return (
        <FormControl variant={variant} fullWidth={fullWidth}>
            <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
            <OutlinedInput
                id={"password" + name}
                disabled={disabled}
                type={type}
                value={value}
                name={name}
                onChange={onChange}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end">
                            {type === "text" ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
                autoComplete="off"
                label={label}
            />
            {validator(name) && <FormHelperText error>{validator(name)}</FormHelperText>}
        </FormControl>
    );
};
export default PasswordTextField;
