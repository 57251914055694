import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { Color } from "@material-ui/lab/Alert";
import { apiGeolocationCountries } from "controllers/geolocation-route/api/countries/countries";
import { apiGeolocationCities } from "controllers/geolocation-route/api/cities/cities";

export type Response = {
    inProgress: boolean;
    response: {
        result: boolean;
        data: any;
        errors: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};

export type TYPE_InitialState_Cities = {
    entity: {
        list: Array<any>;
        value: string;
        city: null | any;
    };
    condition: Response;
};

export const slicer_cities = createSlice({
    name: "recuer-cities",
    initialState: {
        entity: {
            list: [],
            value: "",
            city: null,
        },
        condition: {
            inProgress: false,
            notice: {
                visibility: false,
                severity: "error",
            },
            response: {
                result: false,
                data: null,
                errors: null,
                message: "",
            },
        } as Response,
    } as TYPE_InitialState_Cities,
    reducers: {
        on_Set_City_Cities: (state, action: PayloadAction<string>) => {
            state.entity.value = action.payload;
        },
        on_Change_Cities: (state, action: PayloadAction<{ name: string; value: string }>) => {
            const { value, name } = action.payload as {
                name: string;
                value: string;
            };
            switch (name) {
                case "city": {
                    state.entity.value = value;
                    break;
                }
            }
        },
        on_Select_Cities: (state, action: PayloadAction<{ name: string; value?: { name: string; value: number } }>) => {
            const { value, name } = action.payload;
            switch (name) {
                case "сity":
                    state.entity.value = value ? value.name : "";
                    state.entity.city = value;
                    break;
            }
        },
        on_CleanCondition: state => {
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        on_Request: state => {
            state.condition.inProgress = true;
        },
        on_NoResponse: state => {
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        on_ResponseFailure: (state, action) => {
            console.log("action", action);
            state.condition.inProgress = false;
        },
        on_ResponseSuccess: (state, action: PayloadAction<{ data: Array<any>; message: string; resutl: boolean }>) => {
            state.condition.inProgress = false;
            if (Array.isArray(action.payload.data)) {
                state.entity.list = action.payload.data.map(item => {
                    return {
                        name: item.title,
                        value: item.id,
                    };
                });
            }
            console.log("action", action);
        },
        on_NoticeHide_Cities: state => {
            state.condition.notice.visibility = false;
        },
    },
});

export const {
    on_CleanCondition,
    on_Request,
    on_NoResponse,
    on_ResponseSuccess,
    on_ResponseFailure,
    on_NoticeHide_Cities,
    on_Change_Cities,
    on_Select_Cities,
    on_Set_City_Cities,
} = slicer_cities.actions;

export const async_Cities = (countryName?: string, callback?: (result: any) => void) => async (
    dispatch: any,
    getState: () => RootState,
) => {
    const __state = getState();
    const searchString = countryName || __state.cities.entity.value;
    const searchCountry = __state.countries.entity.country ? __state.countries.entity.country.value : 0;
    dispatch(on_Request());
    const result = await apiGeolocationCities(searchString, searchCountry).catch(e => {
        dispatch(on_NoResponse());
    });
    console.log("result", result);
    if (result) {
        if (result.result) {
            dispatch(on_ResponseSuccess(result));
        } else {
            dispatch(on_ResponseFailure(result));
        }
    } else {
        dispatch(on_NoResponse());
    }
    if (callback) {
        callback(result);
    }
};

export const reselect_Cities_condition = (state: RootState) => {
    return state.cities.condition;
};
export const reselect_Cities_entity = (state: RootState) => {
    return state.cities.entity;
};

export default slicer_cities.reducer;
