import { kyRequest, BACKEND_HOST, responseCallback } from "api/api";

export type TypeRequestToken = {
    email: string;
    password: string;
    rememberMe: boolean;
};

/**
 * @route api/token
 * @post
 * @param {TypeRequestToken} formData
 */
export const apiToken = async (formData: TypeRequestToken) => {
    const date = new FormData();
    date.append("email", formData.email);
    date.append("password", formData.password);
    date.append("remember_me", "" + (formData.rememberMe ? 1 : 0));
    const response = await kyRequest
        .post(`${BACKEND_HOST}/token`, {
            body: date,
        })
        .catch(e => {
            return e;
        });
    return responseCallback(response);
};
