import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { apiRouteResetPassword } from "controllers/reset-password-route/api/reset-password/reset-password";
import { Color } from "@material-ui/lab/Alert";

type Response = {
    inProgress: boolean;
    response: {
        result: boolean;
        data: any;
        errors: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};

export const slicerResetPassword = createSlice({
    name: "reset-password",
    initialState: {
        view: {
            email: "",
        },
        condition: {
            inProgress: false,
            response: {
                result: false,
                data: null,
                message: "",
                errors: null,
            },
            notice: {
                visibility: false,
                severity: "error",
            },
        } as Response,
    },
    reducers: {
        onConditionClear: state => {
            state.condition = {
                notice: {
                    visibility: false,
                    severity: "error",
                },
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onNoResponse: state => {
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        onRequest: state => {
            state.condition = {
                inProgress: true,
                response: {
                    result: false,
                    data: null,
                    message: "",
                    errors: null,
                },
                notice: {
                    visibility: false,
                    severity: "error",
                },
            };
        },
        onResponseFail: (state, action: PayloadAction<{ message: string; errors: Record<string, Array<string>> }>) => {
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    message: action.payload.message,
                    errors: action.payload.errors,
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        onResponseSuccess: (state, action: PayloadAction<{ data: boolean; message: string; result: boolean }>) => {
            state.condition = {
                inProgress: false,
                response: {
                    result: action.payload.result,
                    data: null,
                    message: action.payload.message,
                    errors: null,
                },
                notice: {
                    severity: "success",
                    visibility: true,
                },
            };
        },
        onChange: (state, action) => {
            let ref;
            const nameRoute = action.payload.name.split(".");
            const fieldName = nameRoute.pop();
            ref = nameRoute.reduce((acc: any, item: string) => {
                // @ts-ignore
                return acc[item];
            }, state.view);
            if (action.payload.hasOwnProperty("checked")) {
                ref[fieldName] = action.payload.checked;
            } else {
                ref[fieldName] = action.payload.value;
            }
            if (state.condition.response?.errors?.[fieldName]) {
                delete state.condition.response?.errors?.[fieldName];
            }
        },
    },
});

export const {
    onRequest,
    onNoResponse,
    onResponseSuccess,
    onResponseFail,
    onChange,
    onConditionClear,
} = slicerResetPassword.actions;

export const asyncResetPassword = (callback?: (result: any) => void) => async (
    dispatch: any,
    getState: () => RootState,
) => {
    dispatch(onRequest());
    const view = getState().resetPassword.view;
    const result = await apiRouteResetPassword(view).catch(e => console.error(e));
    if (result) {
        if (result.result) {
            dispatch(onResponseSuccess(result));
        } else {
            dispatch(onResponseFail(result));
        }
    } else {
        dispatch(onNoResponse());
    }
    if (callback) {
        callback(result);
    }
};

export const reselectResetPassword = (state: RootState) => {
    return state.resetPassword.view;
};
export const reselectResetPasswordCondition = (state: RootState) => {
    return state.resetPassword.condition;
};

export default slicerResetPassword.reducer;
