import React from "react";
import {__localeStorage} from "../../../helper/location/location";
import {useHistory} from "react-router";
import {useStyles} from "../../KdwUserPage";
import {ReactComponent as LogoSVG} from "../../../styles/results/icon/logo.svg";

const GreenLogo = (props: any) => {
    const classes = useStyles();
    const history = useHistory();
    return (
        <a href="#" onClick={() => (props.link ? props.link : history.push("/" + __localeStorage() + "/site/"))} className={props.className}>
            <LogoSVG className={classes.navlogo} color={props.color || "#008a71"}/>
        </a>
    );
};

export default GreenLogo;
