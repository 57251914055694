import React from "react";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

const YoutubeButton = ({ ...props }) => {
    return (
        <img
            src="/static/social/Youtube.png"
            className={props.classes.socialIcon}
            onClick={() => {
                props.onClick("vk");
            }}
        />
    );
};

export default YoutubeButton;
