import { combineReducers } from "@reduxjs/toolkit";
import adminEventNewReducer from "./admin-event-post/redux-store/admin-event-post/admin-event-post";
import adminEventIndexReducer from "./admin-event-get-list/redux-store/admin-event-get-list";
import adminEventDeleteReducer from "./admin-event-delete/redux-store/admin-event-delete";

export const AdminEventStore = combineReducers({
    new: adminEventNewReducer,
    index: adminEventIndexReducer,
    delete: adminEventDeleteReducer,
});
