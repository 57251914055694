import React from "react";
import __translate from "../../../../../../helper/locale/locale";
import ExponentCardImgWrapper from "../../../../Exponents/ExponentsList/ExponentCard/ExponentCardImgWrapper/ExponentCardImgWrapper";
import { selectByLang } from "../../../../../FormEvent/FormEvent";
import { LangEnum } from "../../../../../../controllers/admin-speaker/enums";
import { useSelector } from "react-redux";
import { reselectPartnersList } from "../../../../../../controllers/admin-expo/admin-expo-get-list/redux-store/admin-expo-get-list";
import __locale from "../../../../../../helper/location/location";
import classes from "./OtherPartners.module.css";

const OtherPartners = () => {
    const lang = __locale();
    const partnersList = useSelector(reselectPartnersList);

    return (
        <div className={classes.otherPartners}>
            <div className={classes.otherPartners__inner}>
                <div>
                    {partnersList.map(
                        (partners, index) =>
                            partners?.cards?.length !== 0 && (
                                <div className={classes.otherPartners__partner} key={index}>
                                    <div className={classes.otherPartners__partnerHeader}>
                                        <span>{__translate(partners.key, partners.heading)}</span>
                                    </div>
                                    <div>
                                        {partners?.cards?.map((partner: any) => (
                                            <ExponentCardImgWrapper
                                                key={partner.id}
                                                link={selectByLang(partner.site, lang as LangEnum, "")}
                                                imgAlt={selectByLang(partner.logo, LangEnum.RU, {})?.name || "image"}
                                                imgSrc={selectByLang(partner.logo, LangEnum.RU, {})?.file || "image"}
                                                name={selectByLang(partner.name, lang as LangEnum, "")}
                                            />
                                        ))}
                                    </div>
                                </div>
                            ),
                    )}
                </div>
            </div>
        </div>
    );
};

export default OtherPartners;
