import React, { ReactElement } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, ButtonProps } from "@material-ui/core";

export type Props_ButtonDialog = {
    buttonProps?: ButtonProps;
    applyButtonProps?: ButtonProps;
    cancelButtonProps?: ButtonProps;
    applyButtonChildren?: string | ReactElement<any, any>;
    cancelButtonChildren?: string | ReactElement<any, any>;
    buttonChildren?: ReactElement<any, any>;
    title?: string;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
    show?: boolean;
    updateShow?: (show: boolean) => void;
};

const ButtonDialog: React.FunctionComponent<Props_ButtonDialog> = ({
    children,
    title,
    buttonProps,
    buttonChildren = null,
    applyButtonChildren = "Удалить",
    cancelButtonChildren = "Отмена",
    maxWidth = "xs",
    applyButtonProps = {},
    cancelButtonProps = {},
    show = false,
    updateShow = () => {},
}) => {
    const [open, updateOpen] = React.useState(show);

    const __buttonProps: ButtonProps = {
        color: "secondary",
        variant: "contained",
        disabled: false,
        className: "p-button-outlined p-button-sm",
        ...buttonProps,
    };
    const __applyButtonProps: ButtonProps = {
        color: "secondary",
        variant: "outlined",
        ...applyButtonProps,
    };
    const __cancelButtonProps: ButtonProps = {
        color: "default",
        variant: "outlined",
        ...cancelButtonProps,
    };
    const handleClickOpen = () => {
        updateOpen(true);
        updateShow(true);
    };
    const handleClose = () => {
        updateOpen(false);
        updateShow(false);
    };
    React.useEffect(() => {
        updateOpen(show);
    }, [show]);
    return (
        <React.Fragment>
            <Button {...__buttonProps} onClick={handleClickOpen}>
                {buttonChildren}
            </Button>
            <Dialog
                style={{ textAlign: "center" }}
                open={open}
                keepMounted
                maxWidth={maxWidth}
                fullWidth
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description">
                {!!title && <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>}
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">{children}</DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: "center" }}>
                    <Button
                        {...__applyButtonProps}
                        onClick={e => {
                            if (__applyButtonProps.onClick) {
                                __applyButtonProps.onClick(e);
                            }
                            handleClose();
                        }}>
                        {applyButtonChildren}
                    </Button>
                    <Button
                        {...__cancelButtonProps}
                        onClick={e => {
                            if (__cancelButtonProps.onClick) {
                                __cancelButtonProps.onClick(e);
                            }
                            handleClose();
                        }}>
                        {cancelButtonChildren}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default ButtonDialog;
