import React from "react";
import { InputLabel } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Editor } from "@tinymce/tinymce-react/lib/cjs/main/ts";
import SwitchField from "components/Form/Inputs/SwitchField";
import TextField from "@material-ui/core/TextField";
import { TINYMCE_KEY } from "../../../api/api";

type Props = {
    error?: string;
    disabled?: boolean;
    initHtml?: boolean;
    onChange: (event: any) => void;
    value: any;
    name?: string;
    label: string;
    showSwitch?: boolean;
    options?: {
        disabled: boolean;
        textOptions: { [key: string]: string | number | boolean };
    };
};
const TinyMCEHtmlField: React.FC<Props> = ({
    error = "",
    disabled = false,
    initHtml = true,
    onChange,
    name = "switch",
    label,
    value,
    showSwitch = true,
    options,
}) => {
    const [tickValue, setTickValue] = React.useState(null as any);
    const editor = React.useRef(null);
    const [html, setHtml] = React.useState(initHtml && disabled);
    React.useEffect(() => {
        if (tickValue === null) {
            const time = setTimeout(() => {
                setTickValue(value);
                if (editor?.current) {
                    editor.current.value = value;
                }
                clearTimeout(time);
            }, 1000);
        }
    }, [value, html]);
    return (
        <Box marginBottom={4} marginTop={4}>
            <InputLabel>{label}</InputLabel>
            {showSwitch && (
                <SwitchField
                    disabled={disabled}
                    onChange={e => setHtml(e.target.checked)}
                    value={html}
                    label="HTML"
                    closeIcon={null}
                />
            )}

            {html ? (
                <Editor
                    apiKey={TINYMCE_KEY}
                    initialValue={tickValue || ""}
                    key={tickValue}
                    plugins={[
                        "a11ychecker",
                        "advlist",
                        "advcode",
                        "advtable",
                        "autolink",
                        "checklist",
                        "export",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "powerpaste",
                        "fullscreen",
                        "formatpainter",
                        "insertdatetime",
                        "media",
                        "table",
                        "help",
                        "wordcount",
                    ]}
                    // value={value}
                    ref={editor}
                    onEditorChange={(newValue, editor) => {
                        onChange({ target: { name, value: newValue } });
                    }}
                    init={{
                        width: "100%",
                        height: 200,
                        menubar: false,
                    }}
                />
            ) : (
                <TextField
                    name={name}
                    disabled={options?.disabled}
                    fullWidth={true}
                    variant="outlined"
                    multiline={true}
                    rows={6}
                    onChange={onChange}
                    value={value}
                    inputProps={options?.textOptions}
                />
            )}
        </Box>
    );
};
export default TinyMCEHtmlField;
