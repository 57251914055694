import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { Color } from "@material-ui/lab/Alert";
import { EventWithoutLang } from "controllers/admin-event/interfaces/admin-event";
import { apiEventDelete } from "controllers/admin-event/admin-event-delete/api/admin-event-delete";

type Response = {
    inProgress: boolean;
    response: {
        result: boolean;
        data: any;
        errors: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};

type InitialState = {
    view: EventWithoutLang;
    condition: Response;
};

const initialEvent: EventWithoutLang = {
    name: "",
    description: "",
    forum_themes: [],
    date: "",
    curator: "",
    has_chat: false,
    scene: "",
    speakers: null,
    stream_link: "",
    type: "",
    place: "",
};

const initialState: InitialState = {
    view: initialEvent,
    condition: {
        inProgress: false,
        notice: {
            visibility: false,
            severity: "error",
        },
        response: {
            result: false,
            data: null,
            errors: null,
            message: "",
        },
    },
};

export const slicerDeleteEvent = createSlice({
    name: "deleteEvent",
    initialState,
    reducers: {
        onRequest: state => {
            console.log("onRequest");
            state.condition = {
                inProgress: true,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onConditionClear: state => {
            state.condition = {
                notice: {
                    visibility: false,
                    severity: "error",
                },
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onNoResponse: state => {
            console.log("onNoResponse");
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        onResponseFail: (state, action: PayloadAction<{ message: string }>) => {
            console.log("onResponseFail", action);
            console.log("onResponseFail", typeof action);
            const response = typeof action.payload === "string" ? JSON.parse(action.payload) : action.payload;
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: response ? response.message || response.error : "",
                },
                notice: {
                    visibility: true,
                    severity: "error",
                },
            };
        },
        onResponseSuccess: (
            state,
            action: PayloadAction<{
                message: string;
                result: boolean;
                data: {
                    access_token: string;
                    expires_at: string;
                    token_type: "Bearer";
                    type: string;
                };
            }>,
        ) => {
            state.view = initialEvent;
            state.condition = {
                inProgress: false,
                response: {
                    result: action.payload.result,
                    data: action.payload.data,
                    message: action.payload.message,
                    errors: null,
                },
                notice: {
                    visibility: true,
                    severity: "success",
                },
            };
        },
        onNoticeHide: state => {
            state.condition.notice.visibility = false;
        },
        onSetDeleteEvent: (state, action: PayloadAction<EventWithoutLang>) => {
            state.view = action.payload;
        },
    },
});

export const {
    onRequest,
    onResponseSuccess,
    onResponseFail,
    onNoticeHide,
    onConditionClear,
    onSetDeleteEvent,
} = slicerDeleteEvent.actions;

export const asyncDeleteEvent = (callback?: (result: any) => void) => async (
    dispatch: any,
    getState: () => RootState,
) => {
    dispatch(onRequest());
    const view = getState().adminEvent.delete.view;
    let result = await apiEventDelete(view?.id).catch(e => console.error(e));
    if (result) {
        if (result.result) {
            dispatch(onResponseSuccess(result));
        } else {
            dispatch(onResponseFail(result));
        }
    } else {
        dispatch(onResponseFail(result));
    }
    if (callback) {
        callback(result);
    }
};

export const reselectEventDelete = (state: RootState) => {
    return state.adminEvent.delete.view;
};
export const reselectEventDeleteCondition = (state: RootState) => {
    return state.adminEvent.delete.condition;
};

export default slicerDeleteEvent.reducer;
