import React from "react";
import CommonContainer from "../wrappers/CommonContainer/CommonContainer";
import __translate from "../../../helper/locale/locale";
import LinkWrapper from "../../../components/Site/LinkWrapper/LinkWrapper";
import ExponentCardImgWrapper from "../../../components/Site/Exponents/ExponentsList/ExponentCard/ExponentCardImgWrapper/ExponentCardImgWrapper";
import { SOCIALS } from "../../../constants/results/socialLinks";
import FooterLinks from "./FooterLinks/FooterLinks";
import classes from "./Footer.module.css";
import LabCTIcon from "../../../styles/img/partner/labCT.svg";
import PravIcon from "../../../styles/img/partner/rt_gov.svg";
import DigGovIcon from "../../../styles/img/partner/dig_gov.svg";

export const SUPPORT_ARR = [
    {
        organizer_path_to_img: DigGovIcon,
        alt: "Минцифры России",
        link: "https://digital.gov.ru/ru/",
    },
    {
        organizer_path_to_img: PravIcon,
        alt: "Правительство Республики Татарстан",
        link: "https://prav.tatarstan.ru/",
    },
    // {
    //     organizer_path_to_img: LabCTIcon,
    //     alt: "Лаборатория Цифровой Трансформации",
    //     link:
    //         "https://www.tadviser.ru/index.php/%D0%9A%D0%BE%D0%BC%D0%BF%D0%B0%D0%BD%D0%B8%D1%8F:%D0%9B%D0%B0%D0%B1%D0%BE%D1%80%D0%B0%D1%82%D0%BE%D1%80%D0%B8%D1%8F_%D0%A6%D0%B8%D1%84%D1%80%D0%BE%D0%B2%D0%BE%D0%B9_%D0%A2%D1%80%D0%B0%D0%BD%D1%81%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%B8?cache=no&ptype=project",
    // },
];

const Footer = () => {
    return (
        <footer className={classes.footer}>
            <div className={classes.footer__darkBg}>
                <CommonContainer className={classes.footer__inner}>
                    <div>
                        <span>{__translate("site.with_help", "При поддержке")}:</span>
                        <div className={classes.footer__row}>
                            {SUPPORT_ARR.map((img, index) => (
                                <ExponentCardImgWrapper
                                    key={index}
                                    link={img.link}
                                    imgAlt={img.alt}
                                    imgSrc={img.organizer_path_to_img}
                                />
                            ))}
                        </div>
                    </div>
                    <div className={classes.footer__social}>
                        <span>{__translate("site.follow_social", "Читайте нас в социальных сетях")}:</span>
                        <div>
                            {SOCIALS.map((social, index) => (
                                <LinkWrapper key={index} link={social.link}>
                                    {social.icon}
                                </LinkWrapper>
                            ))}
                        </div>
                    </div>
                </CommonContainer>
                <div className={classes.footer__group_links}>
                    <CommonContainer>
                        <FooterLinks />
                    </CommonContainer>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
