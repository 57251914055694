import React from "react";
import { IconButton, InputLabel } from "@material-ui/core";
import { isNull, isUndefined } from "lodash";
import Switch from "@material-ui/core/Switch";
import { Close } from "@material-ui/icons";
import Box from "@material-ui/core/Box";

type Props = {
    error?: string;
    disabled?: boolean;
    onChange: (event: any) => void;
    value: any;
    closeIcon?: any;
    name?: string;
    label: string;
};
const SwitchField: React.FC<Props> = ({
    error = "",
    disabled = false,
    onChange,
    closeIcon = undefined,
    name = "switch",
    label,
    value,
}) => {
    return (
        <Box display="flex" alignItems="center">
            <InputLabel style={{ opacity: isNull(value) || isUndefined(value) ? ".3" : "1" }} htmlFor={name}>
                {label || name}
            </InputLabel>
            <Switch
                value={value}
                checked={!!value}
                name={name}
                disabled={disabled}
                id={name}
                onChange={onChange}
                color="primary"
                inputProps={{ "aria-label": "primary checkbox" }}
            />
            {closeIcon !== undefined ? (
                closeIcon
            ) : (
                <IconButton
                    onClick={() => {
                        onChange({ target: { name, value: null } });
                    }}>
                    <Close />
                </IconButton>
            )}
        </Box>
    );
};
export default SwitchField;
