import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { Color } from "@material-ui/lab/Alert";
import {apiEmployeeGet, apiEmployeeAdd, apiEmployeeDelete} from "./employee-api";


export type Response = {
    inProgress: boolean;
    index: number | string;
    errors:any;
    response: {
        result: boolean;
        data: any;
        errors: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};

export type Paginator = {
    current_page: number;
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    next_page_url: string;
    path: string;
    per_page: number;
    prev_page_url: null;
    to: number;
    total: number;
};

export type EmployeeFilter = {
    status?: string;
};

const EmployeeFilterInitial: EmployeeFilter = {
    status: "",
};


export const slicer_Employees = createSlice({
    name:'employees',
    initialState:{
        paginator: {
            current_page: 1,
            first_page_url: "",
            from: 1,
            last_page: 1,
            last_page_url: "",
            next_page_url: "",
            path: "",
            per_page: 15,
            prev_page_url: null,
            to: 15,
            total: 0,
        } as Paginator,
        view: [],
        condition: {
            index: 0,
            errors: {},
            inProgress: false,
            notice: {
                visibility: false,
                severity: "error",
            },
            response: {
                result: false,
                data: null,
                errors: null,
                message: "",
            },
        } as Response,
        filter: EmployeeFilterInitial
    },
    reducers:{
        onRequest: (state, action) => {
            state.condition = {
                index: action.payload,
                inProgress: true,
                errors: null,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onNoResponse: state => {
            state.condition = {
                index: state.condition.index,
                inProgress: false,
                errors: null,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },

        onResponseFail: (
            state,
            action: PayloadAction<string> | PayloadAction<{ data: any;nativeResponse:any, errors: any; message: string; result: boolean }>,
        ) => {
            
            if (typeof action.payload === "string") {
                state.condition = {
                    index: state.condition.index,
                    inProgress: false,
                    errors: {},
                    response: {
                        result: false,
                        data: null,
                        errors: null,
                        message: action.payload,
                    },
                    notice: {
                        severity: "error",
                        visibility: true,
                    },
                };
            } else {
                state.condition = {
                    index: state.condition.index,
                    inProgress: false,
                    errors: {},
                    response: {
                        result: false,
                        data: null,
                        errors: null,
                        message: `${action.payload.nativeResponse.body.message}`,
                    },
                    notice: {
                        severity: "error",
                        visibility: true,
                    },
                };
            }
        },
        onResponseSuccess: (
            state,
            action: PayloadAction<{
                data: any;
                message: string;
                result: boolean;
                current_page: number;
                first_page_url: string;
                from: number;
                last_page: number;
                last_page_url: string;
                next_page_url: string;
                path: string;
                per_page: number;
                prev_page_url: null;
                to: number;
                total: number;
                nativeResponse:any,
                response:any
            }>,
        ) => {
            state.paginator = {
                current_page: action.payload.nativeResponse.body.current_page,
                first_page_url: action.payload.nativeResponse.body.first_page_url,
                from: action.payload.nativeResponse.body.from,
                last_page: action.payload.nativeResponse.body.last_page,
                last_page_url: action.payload.nativeResponse.body.last_page_url,
                next_page_url: action.payload.nativeResponse.body.next_page_url,
                path: action.payload.nativeResponse.body.path,
                per_page: action.payload.nativeResponse.body.per_page,
                prev_page_url: action.payload.nativeResponse.body.prev_page_url,
                to: action.payload.nativeResponse.body.to,
                total: action.payload.nativeResponse.body.total,
            };
            state.view = action.payload.response.data;
            state.condition = {
                index: state.condition.index,
                inProgress: false,
                errors: {},
                response: {
                    result: action.payload.result,
                    data: action.payload.data,
                    message: `${action.payload.nativeResponse.body.message}`,
                    errors: null,
                },
                notice: {
                    severity: "success",
                    visibility: true,
                },
            };
        },
        onAddResponseSucces:(state,action) => {
            
            state.condition = {
                index: state.condition.index,
                inProgress: false,
                errors: {},
                response: {
                    result: action.payload.result,
                    data: action.payload.data,
                    message: `${action.payload.nativeResponse.body.message}`,
                    errors: null,
                },
                notice: {
                    severity: "success",
                    visibility: true,
                },
            }
        },
        onAddResponseFail: (state,action) =>{
            
            state.condition = {
                index: state.condition.index,
                inProgress: false,
                errors: action.payload.errors,
                response: {
                    result: action.payload.result,
                    data: action.payload.data,
                    message: `${action.payload.nativeResponse.body.message}`,
                    errors: null,
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            } 
        },
        onConditionClear: state => {
            state.condition = {
                errors: {},
                index: 0,
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onChange: (state, action) => {
            let ref;
            const nameRoute = action.payload.name.split(".");
            const fieldName = nameRoute.pop();
            ref = nameRoute.reduce((acc: any, item: string) => {
                // @ts-ignore
                return acc[item];
            }, state.filter);
            if (action.payload.hasOwnProperty("checked")) {
                ref[fieldName] = action.payload.checked;
            } else {
                ref[fieldName] = action.payload.value;
            }
        },
        resetEmployeeFilter: state => {
            state.filter = EmployeeFilterInitial;
        },
        
    }
})

export const {
    onRequest,
    onNoResponse,
    onResponseSuccess,
    onResponseFail,
    onAddResponseFail,
    onAddResponseSucces,
    onConditionClear,
    resetEmployeeFilter,
    onChange
} = slicer_Employees.actions;

export const EmployeeGet = (company_id: number, params?: any, callback?: (result: any) => void) => async (dispatch: any) => {
    const result = await apiEmployeeGet({company_id, ...params}).catch(e => {
        // dispatch(onResponseCatch(`${e.name}: ${e.message}`));
    });
    if (result) {
        dispatch(result.response?.result ? onResponseSuccess(result) : onResponseFail(result));
    } else {
        dispatch(onNoResponse());
    }
    if (callback) {
        callback(result);
    }
};

export const employeeAdd = (data:any, callback?: (result: any) => void) => async (dispatch: any) => {
    const result = await apiEmployeeAdd(data).catch(e => {
        // dispatch(onResponseCatch(`${e.name}: ${e.message}`));
    });
    if (result) {
        dispatch(result.result ? onAddResponseSucces(result) : onAddResponseFail(result));
    } else {
        dispatch(onNoResponse());
    }
    if (callback) {
        callback(result);
    }
}
export const employeeRemove = (data:any, callback?: (result: any) => void) => async (dispatch: any) => {
    const result = await apiEmployeeDelete(data).catch(e => {
        // dispatch(onResponseCatch(`${e.name}: ${e.message}`));
    });
    if (result) {
        dispatch(result.result ? onResponseSuccess(result) : onResponseFail(result));
    } else {
        dispatch(onNoResponse());
    }
    if (callback) {
        callback(result);
    }
}

export const reselectEmployees = (state: any): any | null => {
    return state.employees.view;
};

export const reselectEmployeePaginator = (state: RootState): Paginator => {
    return state.employees.paginator;
};

export const reselectAdminPriceCondition = (state: RootState): Response => {
    return state.employees.condition;
};

export const reselectEmployeesCondition = (state: RootState): Response => {
    return state.employees.condition;
};

export const reselectEmployeeFilter = (state: RootState): EmployeeFilter => {
    return state.employees.filter;
};

export default slicer_Employees.reducer;
