import { kyRequest, BACKEND_HOST, responseCallback, STREAM_API_KEY } from "api/api";
import { downloadBlob } from "helper/blob/blob";
import { serializeObjectToQueryParams } from "helper/query";
import { EventFilter } from "controllers/admin-event/admin-event-get-list/redux-store/admin-event-get-list";
import { format } from "date-fns";
import { TYPE_USER_PROFILE } from "../../../profile-route/redux-store/profile/profile";
import { StreamChat } from "stream-chat";
import __translate from "../../../../helper/locale/locale";
import { serverDateFormat } from "../../admin-event-post/api/admin-event-post";

/**
 * @route api/user
 * @post
 */
export const apiAdminEventIndex = async (
    page: number,
    filterParams?: EventFilter,
    exportXLS?: boolean,
    exportTranslationXLS?: boolean,
    per_page?: number,
) => {
    let url = `${BACKEND_HOST}/event?page=${page}&per_page=${per_page || 10}`;
    // let url = `${BACKEND_HOST}/event`;

    if (filterParams) {
        let filter: any = {};
        filter["order_rule"] = filterParams.order_rule || "";
        filter["order_field"] = filterParams.order_field || "";
        filter["name"] = filterParams.name || "";
        filter["scene"] = filterParams.scene || null;
        filter["place"] = filterParams.place || null;
        filter["with_scene"] = filterParams.with_scene || null;
        filter["min_date"] = serverDateFormat(filterParams.min_date) || null;
        filter["max_date"] = serverDateFormat(filterParams.max_date) || null;
        if(filterParams.hasOwnProperty('cache')) filter['cache'] = filterParams.cache;
        if (typeof filterParams.with_stream_link === "boolean")
            filter["with_stream_link"] = filterParams.with_stream_link ? 1 : 0;
        if (filterParams.forum_themes)
            for (var i = 0; i < filterParams.forum_themes.length; i++) {
                filter[`forum_themes[${i}]`] = filterParams.forum_themes[i];
            }
        if (filterParams.types)
            for (var i = 0; i < filterParams.types.length; i++) {
                filter[`types[${i}]`] = filterParams.types[i];
            }

        const data = serializeObjectToQueryParams(filter);
        url = url + "&" + data;
    }

    if (exportXLS) {
        url = url + "&" + "export=1";
    }

    const response = await kyRequest.get(url, {
        ...(exportXLS ? {timeout: 180000} : {}),
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    if (exportXLS) {
        response.blob().then(blob => downloadBlob(blob, "kdw_event.xls"));
    }

    if (exportTranslationXLS) {
        url = url + "&" + "export=1";
    }

    const responseTranslation = await kyRequest.get(url, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    if (exportTranslationXLS) {
        const currentDate = format(new Date(), "dd.MM.yyyy");
        responseTranslation.blob().then(blob => downloadBlob(blob, `kdw_translation_${currentDate}.xls`));
    }

    return responseCallback(response);
};

export const apiAdminEventMessages = async (event: number, page: number, per_page?: number) => {
    let url = `${BACKEND_HOST}/event/${event}/message?page=${page}&per_page=${per_page || 20}`;

    const response = await kyRequest.get(url, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return responseCallback(response);
};
export const apiDeleteAdminEventMessage = async (event: number, msg_id: number) => {
    let url = `${BACKEND_HOST}/event/${event}/message/${msg_id}`;

    const response = await kyRequest.delete(url, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return responseCallback(response);
};

export const asyncInitializeClient = async (event: number, profile: TYPE_USER_PROFILE) => {
    const response = await responseCallback(
        await kyRequest.post(BACKEND_HOST + "/event/" + event + "/message/token", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
            json: { name: profile?.surname + " " + profile?.name },
        }),
    );
    const client = new StreamChat(
        __translate("keys.stream_api_key") === "keys.stream_api_key"
            ? STREAM_API_KEY
            : __translate("keys.stream_api_key", "Ключ api трансляции"),
        { timeout: 6000 },
    );
    await client.connectUser(response.data.user, response.data.token);
    return client;
};
export const asyncCreateChannel = async (event: number) => {
    try {
        const response = await kyRequest.get(BACKEND_HOST + "/event/" + event + "/message/channel", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
        });
        return responseCallback(response);
    } catch (e) {
        return Promise.reject(e);
    }
};
export const asyncSendMessage = async (event: number, message: string) => {
    try {
        const response = await kyRequest.post(BACKEND_HOST + "/event/" + event + "/message", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
            json: {
                message,
            },
        });
        return responseCallback(response);
    } catch (e) {
        return Promise.reject(e);
    }
};
