import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";

import { Response } from "../admin-users/redux-store/admin-users/admin-users";
import { deleteContactById, getContactsList, saveContact } from "./api";

export const slicerContacts = createSlice({
    name: "contacts",
    initialState: {
        items: [],
        contact: null as any,
        condition: {
            inProgress: false,
            notice: {
                visibility: false,
                severity: "error",
            },
            response: {
                result: false,
                data: null,
                errors: null,
                error: {
                    code: 0,
                    message: "empty",
                },
                message: "",
            },
        } as Response,
    },
    reducers: {
        onNoticeHide: state => {
            state.condition.notice.visibility = false;
        },
        onItem: (state, action) => {
            state.contact = action.payload;
        },
        onRequest: state => {
            state.condition = {
                inProgress: true,
                notice: {
                    visibility: false,
                    severity: "success",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    error: {
                        code: 0,
                        message: "",
                    },
                    message: "",
                },
            } as Response;
        },
        onItemSuccess: (state, action: { payload: any }) => {
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "success",
                },
                response: {
                    message: action.payload.error.message,
                    ...action.payload,
                },
            };
            state.items = action.payload.data;
        },
        onSaveSuccess: (state, action: { payload: any }) => {
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "success",
                },
                response: {
                    ...action.payload,
                    message: action.payload?.error?.message,
                },
            };
        },
        onRequestFail: (state, action: { payload: any }) => {
            state.condition = {
                notice: {
                    visibility: true,
                    severity: "error",
                },
                inProgress: false,
                response: {
                    ...action.payload,
                    result: false,
                    data: {},
                    message: action.payload.error.message,
                    errors: action.payload.error.code === 400 ? action.payload.data : null,
                },
            };
            {console.log(state.condition)}
        },
        stopLoading: state => {
            state.condition.inProgress = false;
        },
    },
});

export const {
    onRequest,
    onRequestFail,
    onNoticeHide,
    onItem,
    onItemSuccess,
    onSaveSuccess,
    stopLoading,
} = slicerContacts.actions;

export const asyncGetContacts = (callback?: (result: any) => void) => {
    return async (dispatch: any, getState: () => RootState) => {
        dispatch(onRequest);
        let error = null;
        const result = await getContactsList().catch(e => {
            console.log(e);
            error = e;
        });
        if (error || !result) dispatch(stopLoading);
        else {
            if (result.result) dispatch(onItemSuccess(result));
            else dispatch(onRequestFail(result));
        }
        if (callback) callback(result);
    };
};
export const asyncDeleteContact = (id: Number, callback?: (result: any) => void) => {
    return async (dispatch: any, getState: () => RootState) => {
        dispatch(onRequest);
        let error = null;
        const result = await deleteContactById(id).catch(e => {
            console.log(e);
            error = e;
        });
        if (error || !result) dispatch(stopLoading);
        else {
            if (result.result) dispatch(onItemSuccess(result));
            else dispatch(onRequestFail(result));
        }
        if (callback) callback(result);
    };
};

export const asyncContactSave = (contact: any, lang: string, callback?: (result: any) => void) => {
    return async (dispatch: any, getState: () => RootState) => {
        dispatch(onNoticeHide);
        dispatch(onRequest);
        let error = null;
        const result = await saveContact(contact, lang).catch(e => {
            error = e;
        });
        
        if (result && !error) {
            if (result.result) {
                dispatch(onSaveSuccess(result));
                dispatch(onRequest);
            } else dispatch(onRequestFail(result));
        }

        if (error || !result) dispatch(stopLoading);
        if (callback) callback(result);
    };
};
export const reselectContacts = (state: RootState) => {
    return state.contacts.items;
};
export const reselectContact = (state: RootState) => {
    return state.contacts.contact;
};
export const reselectContactsCondition = (state: RootState) => {
    return state.contacts.condition;
};
export default slicerContacts.reducer;
