import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { apiGeolocationCountries } from "controllers/geolocation-route/api/countries/countries";

export const slicerGeonamesCountries = createSlice({
    name: "geonames-countries",
    initialState: {
        list: [] as Array<any>,
        condition: {
            inProgress: false,
            index: 0,
            response: {
                result: false,
                data: null,
                errors: null,
            },
        },
    },
    reducers: {
        onRequest: (state, action) => {
            state.list = [];
            state.condition.inProgress = true;
            state.condition.index = action.payload;
        },
        onResponseFail: state => {
            state.condition.inProgress = false;
            state.list = [];
        },
        onResponseSuccess: (state, action: PayloadAction<any>) => {
            state.condition.inProgress = false;
            state.list = Array.isArray(action.payload)
                ? action.payload.map((item: any) => {
                      return {
                          name: item.title,
                          value: item.id,
                      };
                  })
                : [];
        },
    },
});

export const { onRequest, onResponseSuccess, onResponseFail } = slicerGeonamesCountries.actions;

export const asyncGeolocationCountries = (callback?: (result: any) => void) => async (
    dispatch: any,
    getState: () => RootState,
) => {
    let index = Date.now();
    dispatch(onRequest(index));
    const result = await apiGeolocationCountries(getState().registration.view.country).catch(e => console.error(e));
    if (result) {
        if (result && result.result) {
            dispatch(onResponseSuccess(result.data));
        } else {
            dispatch(onResponseFail());
        }
    } else {
        dispatch(onResponseFail());
    }
    if (callback) {
        callback(result);
    }
};

export const asyncGeolocationCountriesBase = (data: any, callback?: (result: any) => void) => async (dispatch: any) => {
    let index = Date.now();
    dispatch(onRequest(index));
    const result = await apiGeolocationCountries(data).catch(e => console.error(e));
    if (result) {
        if (result && result.result) {
            dispatch(onResponseSuccess(result.data));
        } else {
            dispatch(onResponseFail());
        }
    } else {
        dispatch(onResponseFail());
    }
    if (callback) {
        callback(result);
    }
};

export const reselectGeonamesCountriesList = (state: RootState) => {
    return state.geonames.countries.list;
};

export const reselectGeonamesCountriesCondition = (state: RootState) => {
    return state.geonames.countries.condition;
};

export default slicerGeonamesCountries.reducer;
