import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { __C, apiRouteConstants } from "controllers/constants-route/api/constants/constants";

export type Constants = {
    manager: {
        roles: Array<string>;
    };
    user: {
        eat_types: any;
        souvenir_types: any;
        badge_types: any;
        academic_degrees: Array<string>;
        event_types: Array<string>;
        exponent_states: Array<string>;
        exponent_types: Array<string>;
        forum_themes: Array<string>;
        report_states: Record<string, string>;
        startup_states: Record<string, string>;
        roles: Record<string, string>;
        roles2023: Array<string>;
        sectors: Array<string>;
        sources: Array<string>;
        visit_user_statuses: Array<string>;
        visit_types: Array<string>;
        covid_states: Array<string>;
        participations: any;
        doc_states: any;
    };
    countries: Array<any>;
    org_types: Array<string>;
};
export const slicerConstants = createSlice({
    name: "constants",
    initialState: {
        view: __C as Constants,
        condition: {
            inProgress: false,
            response: {
                success: false,
                data: null,
                errors: null,
            },
        },
    },
    reducers: {
        update: (state, action) => {
            state.view = action.payload;
        },
        onRequest: state => {
            state.condition.inProgress = true;
        },
        onResponse: (state, action) => {
            state.condition = {
                inProgress: false,
                response: action.payload,
            };
        },
        on_Response_Success: (state, action: PayloadAction<any>) => {
            state.condition.inProgress = false;
            state.view = action.payload;
            // state.view.user.sectors.sort();
        },
        on_Response_NotSuccess: state => {
            state.condition.inProgress = false;
        },
        onChange: (state, action) => {
            let ref;
            const nameRoute = action.payload.name.split(".");
            const fieldName = nameRoute.pop();
            ref = nameRoute.reduce((acc: any, item: string) => {
                // @ts-ignore
                return acc[item];
            }, state.view);
            if (action.payload.hasOwnProperty("checked")) {
                ref[fieldName] = action.payload.checked;
            } else {
                ref[fieldName] = action.payload.value;
            }
        },
    },
});

export const {
    update,
    onRequest,
    onResponse,
    on_Response_Success,
    on_Response_NotSuccess,
    onChange,
} = slicerConstants.actions;

export const asyncConstants = (callback?: (result: any) => void) => async (
    dispatch: any,
    getState: () => RootState,
) => {
    dispatch(onRequest());
    const result = await apiRouteConstants().catch(e => console.error(e));
    if (result) {
        if (result.result) {
            dispatch(on_Response_Success(result.data));
        } else {
            dispatch(on_Response_NotSuccess());
        }
    } else {
        dispatch(on_Response_NotSuccess());
    }
    if (callback) {
        callback(result);
    }
};

export const reselectConstants = (state: RootState): Constants => {
    return state.constants.view;
};

export const reselectConstants_condition = (state: RootState) => {
    return state.constants.condition;
};

export const reselect_Constants_ForumThemes = (state: RootState) => {
    return state.constants.view.user.forum_themes;
};
export const reselect_Constants_Roles = (state: RootState) => {
    return state.constants.view.user.roles;
};

export default slicerConstants.reducer;
