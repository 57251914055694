import { SpeakerFilter } from "controllers/admin-speaker/admin-speaker-get-list/redux-store/admin-speaker-get-list/admin-speaker-get-list";
import { kyRequest, BACKEND_HOST, responseCallback } from "api/api";
import { downloadBlob } from "helper/blob/blob";
import { serializeObjectToQueryParams } from "helper/query";
/**
 * @route api/user
 * @post
 */
export const apiAdminSpeakerIndex = async (page: number, filterParams?: SpeakerFilter, exportXLS?: boolean) => {
    let url = `${BACKEND_HOST}/speaker?page=${page}&per_page=16`;
    if (filterParams) {
        let filter: any = {};
        if (filterParams.per_page) filter["per_page"] = filterParams.per_page;
        if (filterParams.name) filter["name"] = filterParams.name;
        if (filterParams.name_en)filter["name_en"] = filterParams.name_en;
        if (filterParams.place_of_work)filter["place_of_work"] = filterParams.place_of_work;
        if (filterParams.place_of_work_en)filter["place_of_work_en"] = filterParams.place_of_work_en;
        if (filterParams.work_position)filter["work_position"] = filterParams.work_position;
        if (filterParams.work_position_en) filter["work_position_en"] = filterParams.work_position_en;
        for (var i = 0; i < filterParams.forum_themes?.length; i++) {
            filter[`forum_themes[${i}]`] = filterParams.forum_themes[i];
        }
        const data = serializeObjectToQueryParams(filter);
        url = url + "&" + data;
    }
    if (exportXLS) {
        url = url + "&export=1";
    }
    const response = await kyRequest.get(url, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    if (exportXLS) {
        response.blob().then(blob => downloadBlob(blob, "kdw_speaker.xls"));
    }
    return responseCallback(response);
};
