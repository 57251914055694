import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { apiRouteProfile } from "controllers/profile-route/api/profile/profile";

export const slicerGeonamesSearchAll = createSlice({
    name: "geonames-search-all",
    initialState: {
        list: {} as Record<string, string>,
        condition: {
            inProgress: false,
            response: {
                result: false,
                data: null,
                errors: null,
            },
        },
    },
    reducers: {
        // update: (state, action) => {
        //     state.view = action.payload;
        // },
        onRequest: state => {
            state.condition.inProgress = true;
        },
        onResponse: (state, action) => {
            state.condition = {
                inProgress: false,
                response: action.payload,
            };
        },
        onResponseSuccess: (state, action: PayloadAction<any>) => {
            state.condition.inProgress = false;
            // state.view = action.payload;
        },
    },
});

export const {
    // update,
    onRequest,
    onResponse,
    onResponseSuccess,
} = slicerGeonamesSearchAll.actions;

export const asyncProfile = (callback?: (result: any) => void) => async (dispatch: any, getState: () => RootState) => {
    dispatch(onRequest());
    const result = await apiRouteProfile().catch(e => console.error(e));
    if (result) {
        if (result.success) {
            dispatch(onResponseSuccess(result.data));
        }
    }
    if (callback) {
        callback(result);
    }
};

export const reselectGeoSearchView = (state: RootState) => {
    return state.profile.view;
};

export default slicerGeonamesSearchAll.reducer;
