import { createSlice } from "@reduxjs/toolkit";

export type TYPE_themeSlice = {
    currentTheme: number;
    visibility_UserLeftMenu: boolean;
    isCollapsed: boolean;
    isDev: boolean;
};

export const themeSlice = createSlice({
    name: "theme",
    initialState: {
        currentTheme: 0,
        visibility_UserLeftMenu: false,
        isCollapsed: false,
        isDev: !!localStorage.getItem("Bolt.isDev"),
    } as TYPE_themeSlice,
    reducers: {
        increment: state => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            // state.value += 1;
        },
        decrement: state => {
            // state.value -= 1;
        },
        incrementByAmount: (state, action) => {
            // state.value += action.payload;
        },
        onToggleCollapse: state => {
            state.isCollapsed = !state.isCollapsed;
        },
        on_Toggle_UserLeftMenu: state => {},
    },
});

export const { increment, decrement, onToggleCollapse } = themeSlice.actions;

// export const incrementAsync = (amount: any) => (dispatch: any) => {
//     setTimeout(() => {
//         dispatch(incrementByAmount(amount));
//     }, 1000);
// };

export const reselectThemeIsCollapsed = (state: any) => state.theme.isCollapsed;

export const reselectTheme = (state: any) => state.theme;

export default themeSlice.reducer;
