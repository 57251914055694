import React from "react";
import Box from "@material-ui/core/Box";
import { Response } from "controllers/admin-user-put/redux-store/admin-user-put/admin-user-put";
import FormHelperText from "@material-ui/core/FormHelperText";
import { KdwCondition } from "helper/redux";

type TYPE_BoxError = {
    name: string;
    condition: Response | KdwCondition;
    boxProps?: any;
    padding?: any;
};
const BoxError: React.FunctionComponent<TYPE_BoxError> = ({
    name,
    condition,
    children,
    boxProps = {},
    padding = 4,
}) => {
    return (
        <Box
            padding={padding}
            bgcolor={condition?.errors?.hasOwnProperty(name) ? "rgba(255, 0, 0, .1)" : "transparent"}
            {...boxProps}>
            {children}
            {condition?.errors?.hasOwnProperty(name) && (
                <FormHelperText style={{ color: "red" }}>{condition.errors[name].toString()}</FormHelperText>
            )}
        </Box>
    );
};

export default BoxError;
