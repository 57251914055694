import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import {
    apiRouteConfirmation, apiSendConfirmationLink,
} from "controllers/confirmation-route/api/confirmation/comfirmation";
import {makeClearCondition} from "../../../../helper/redux";

export const slicerConfirmation = createSlice({
    name: "confirmation",
    initialState: {
        view: null,
        condition: makeClearCondition(),
    },
    reducers: {
        update: (state, action) => {
            state.view = action.payload;
        },
        onRequest: state => {
            state.condition.inProgress = true;
        },
        onResponse: (state, action) => {
            state.condition = {...state.condition,
                inProgress: false,
                response: action.payload,
                errors: action.payload.errors || {}
            };
        },
        onResponseSuccess: (state, action: PayloadAction<any>) => {
            state.condition.inProgress = false;
            state.view = action.payload;
        },
        onChange: (state, action) => {
            let ref;
            const nameRoute = action.payload.name.split(".");
            const fieldName = nameRoute.pop();
            ref = nameRoute.reduce((acc: any, item: string) => {
                // @ts-ignore
                return acc[item];
            }, state.view);
            if (action.payload.hasOwnProperty("checked")) {
                ref[fieldName] = action.payload.checked;
            } else {
                ref[fieldName] = action.payload.value;
            }
        },
    },
});

export const { update, onRequest, onResponse, onResponseSuccess, onChange } = slicerConfirmation.actions;

export const asyncConfirmation = (view: any, callback?: (result: any) => void) => async (
    dispatch: any,
    getState: () => RootState,
) => {
    dispatch(onRequest());
    const result = await apiRouteConfirmation(view).catch(e => console.error(e));
    if (result) {
        if (result.success) {
            dispatch(onResponseSuccess(result.data));
        }
        dispatch(onResponse(result))
    }
    if (callback) {
        callback(result);
    }
};
export const asyncConfirmationLink = (callback?: (result: any) => void) => async (
    dispatch: any,
) => {
    dispatch(onRequest());
    const result = await apiSendConfirmationLink().catch(e => console.error(e));
    if (result) {
        if (result.success) {
            dispatch(onResponseSuccess({}));
        }
        dispatch(onResponse(result))
    }
    if (callback) {
        callback(result);
    }
};

export const reselectConfirmation = (state: RootState) => {
    return state.confirmation.view;
};
export const reselectConfirmationCond = (state: RootState) => {
    return state.confirmation.condition;
};

export default slicerConfirmation.reducer;
