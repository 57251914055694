import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {AppBar, FormControl} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import Box from "@material-ui/core/Box";
import PersonIcon from "@material-ui/icons/Person";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {reselect_Profile_entity, reselect_Profile_title} from "controllers/profile-route/redux-store/profile/profile";
import {useDispatch, useSelector} from "react-redux";
import __translate from "helper/locale/locale";
import {__localeStorage, default as __locale} from "helper/location/location";
import {asyncFrontContentByLocale} from "controllers/locale/redux-store/locale/locale";
import {asyncTokenRevoke} from "../../../controllers/token-revoke/redux-store/token-revoke/token-revoke";
import {
    asyncConstants,
    reselect_Constants_Roles
} from "../../../controllers/constants-route/redux-store/constants/constants";
import {LangEnum} from "../../../controllers/admin-expo/enums";
import GreenLogo from "./GreenLogo";
import Logout from "../../../components/appicons/Logout"
import Account from "../../../components/appicons/Account";
import Document from "../../../components/appicons/Document";
import Home from "../../../components/appicons/Home";
import {useStyles as userStyles} from "../../KdwUserPage"

const useStyles = makeStyles(theme => ({
    title: {
        flexGrow: 1,
    },
    navLink: {
        marginRight: "1.5em",
        color: "#ffffff",
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    appbar: {
        background: "linear-gradient(82.35deg, #008A71 0%, #00939C 33.21%, #0BA59D 66.43%, #11BD9E 99.64%)",
        backdropFilter: "blur(2px)",
        zIndex: 100,
        paddingLeft: 40,
        paddingRight: 40,
        position: "relative",
        [theme.breakpoints.down(958)]: {
            display: "block",
        },
    },
    toolbar: {
        width: "100%",
        [theme.breakpoints.down(800)]: {
            display: "flex",
            flexDirection: "column",
        },
        ["& button"] : {
            color: "white",
            fontSize:"18px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "20px",
            textTransform: "none",
            padding:0,
            ["&:hover"] : {
                background: "transparent"
            },
            ["& svg"] : {
                marginTop: 5,
            },
            ["& svg:first-child"] : {
                marginLeft: "0 !important",
                marginRight: "4px",
            },
            ["& svg:last-child"] : {
                marginLeft: "10px",
            }
        }
    },
    blocklang: {
        padding: "0 1em",
        margin: "0 1em",
        display: "flex",
        alignItems: "center",
        '& button' : {

            [theme.breakpoints.up(800)]: {
                minWidth: "300px",
                justifyContent: "start"
            },
        }
    },
    lang: {
        minWidth: "80px",
        color: "white",
        fontSize: 18,
        lineHeight: "20px",
        ["& fieldset"]: {
            border: "none !important"
        },
        ["& svg"] : {
            color: "white",
        },
    },
    nav_user: {
        width: "max-content"
    },
    nav_role: {
        position: "absolute",
        top: "20px",
        left:0,
        fontSize: "16px",
        lineHeight: "17px",
        color: "#D9D9D9",
        width: "max-content"
    }
}));
export const pageLangChange = (lang: LangEnum, history: any, dispatch: any) => {
    const newPathname = window.location.pathname.replace(__locale(), lang);
    history.replace(newPathname);
    dispatch(
        asyncFrontContentByLocale(lang, response => {
            if (response && response.result) {
                localStorage.setItem("locale", lang);
                let html = document.getElementsByTagName("html")[0];
                html.setAttribute("lang", lang);
                html.setAttribute("translate", "no");
                html.setAttribute("class", "notranslate");
                dispatch(asyncConstants());
            }
        }),
    );
};

const UserMenu = () => {
    const classes = useStyles();
    const allClasses = userStyles();
    const user = useSelector(reselect_Profile_entity);
    const reduxDispatch = useDispatch();
    const history = useHistory();

    const handleChangeLang = (e: any) => {
        pageLangChange(e.target.value, history, reduxDispatch);
    };

    const __lang__ = __locale();
    const title = useSelector(reselect_Profile_title);


    const logout = (e: any) => {
        e.preventDefault();
        reduxDispatch(
            asyncTokenRevoke(result => {
                window.location.href = `/${__localeStorage()}/login`;
            }),
        );
    };
    const name = `${user?.surname || ""} ${user?.name?.substr(0,1)?.concat('.') || ""}${user?.lastname?.substr(0,1).concat('.') || ""}`;

    const roles = useSelector(reselect_Constants_Roles);
    const role = user?.participation_data?.role;
    return (
        <React.Fragment>
            <AppBar className={classes.appbar} position="static">
                <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.toolbar}>
                    <GreenLogo color="#FFF"/>
                    <Button style={{minWidth: "300px", height:0}}/>
                    <Button style={{fontWeight: 700, minWidth: "300px"}} className={allClasses.hideMobile}>
                        <span>{title}</span>
                    </Button>
                    <Box className={classes.blocklang}>
                        <FormControl variant="outlined" className="notranslate">
                            <Select
                                className={classes.lang}
                                native
                                value={__lang__}
                                onChange={handleChangeLang}
                                name="lang">
                                <option style={{background: "#005E4D"}} value={LangEnum.RU}>
                                    RU
                                </option>
                                <option style={{background: "#005E4D"}} value={LangEnum.EN}>
                                    EN
                                </option>
                                {/*<option style={{ background: "#005E4D" }} value={LangEnum.CN}>*/}
                                {/*中文*/}
                                {/*</option>*/}
                            </Select>
                        </FormControl>
                        <Button style={{minWidth:"max-content"}}>
                            <Account/>
                            <span style={{position:"relative"}}>
                                <span className={classes.nav_user}>{name}</span>
                                <span className={classes.nav_role}>{Number.isInteger(role) && roles?.[role] || ""}</span>
                            </span>
                            <Button onClick={logout} className={allClasses.showMobile}><Logout/></Button>
                        </Button>
                    </Box>
                    <Button onClick={logout} className={allClasses.hideMobile}>
                        <span>{__translate("system.exit", "Выйти")}</span><Logout/>
                    </Button>
                </Box>
            </AppBar>
        </React.Fragment>
    );
};
export default UserMenu;
