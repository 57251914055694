import { combineReducers } from "@reduxjs/toolkit";
import adminExpoNewReducer from "./admin-expo-post/redux-store/admin-expo-post/admin-expo-post";
import adminExpoIndexReducer from "./admin-expo-get-list/redux-store/admin-expo-get-list";
import adminExpoByIdReducer from "./admin-expo-get/redux-store/admin-expo-get";
import adminExpoDeleteReducer from "./admin-expo-delete/redux-store/admin-expo-delete";

export const AdminExpoStore = combineReducers({
    new: adminExpoNewReducer,
    index: adminExpoIndexReducer,
    byId: adminExpoByIdReducer,
    delete: adminExpoDeleteReducer,
});
