import { Color } from "@material-ui/lab/Alert";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LangEnum } from "controllers/admin-expo/enums";
import { Expo } from "controllers/admin-expo/interfaces/admin-expo";
import { RootState } from "redux-store/index";
import { apiExpoSave } from "controllers/admin-expo/admin-expo-post/api/admin-expo-post";
import { initialCondition } from "controllers/admin-speaker/admin-speaker-post/redux-store/admin-speaker-post/admin-speaker-post";

type Response = {
    inProgress: boolean;
    response: {
        result: boolean;
        errors: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};

export type Condition = {
    ru: Response;
    en: Response;
    cn: Response;
};

type InitialState = {
    view: Expo;
    modalIsOpened: boolean;
    mode: "create" | "edit";
    condition: Condition;
};

const initialExpo: Expo = {
    name: {
        ru: "",
        en: "",
        cn: "",
    },
    logo: {
        ru: null,
        en: null,
        cn: null,
    },
    forum_themes: {
        ru: [],
        en: [],
        cn: [],
    },
    info: {
        ru: "",
        en: "",
        cn: "",
    },
    short: {
        ru: "",
        en: "",
        cn: "",
    },
    video: {
        ru: "",
        en: "",
        cn: "",
    },
    stand: {
        ru: "",
        en: "",
        cn: "",
    },
    address: {
        ru: "",
        en: "",
        cn: "",
    },
    email: {
        ru: "",
        en: "",
        cn: "",
    },
    phone: {
        ru: "",
        en: "",
        cn: "",
    },
    site: {
        ru: "",
        en: "",
        cn: "",
    },
    types: {
        ru: [],
        en: [],
        cn: [],
    },
    state: {
        ru: "",
        en: "",
        cn: "",
    },
    priority: {
        ru: "",
        en: "",
        cn: "",
    },
    presentation: {
        ru: null,
        en: null,
        cn: null,
    },
    leader_accepted: {} as any,
};

const initialState: InitialState = {
    view: initialExpo,
    modalIsOpened: false,
    mode: "create",
    condition: {
        ru: initialCondition,
        en: initialCondition,
        cn: initialCondition,
    },
};

export const slicerNewExpo = createSlice({
    name: "newExpo",
    initialState,
    reducers: {
        onConditionClear: (state, action: PayloadAction<{ lang: LangEnum }>) => {
            (state.condition as Condition)[action.payload.lang] = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    errors: null,
                    message: "",
                },
            };
        },
        onNoticeHide: (state, action: PayloadAction<{ lang: LangEnum }>) => {
            (state.condition as Condition)[action.payload.lang].notice.visibility = false;
        },
        onRequest: (state, action: PayloadAction<{ lang: LangEnum }>) => {
            console.log("onRequest");
            (state.condition as Condition)[action.payload.lang] = {
                inProgress: true,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    errors: null,
                    message: "",
                },
            };
        },
        onResponseFail: (state, action: PayloadAction<{ result: { message: string; errors: [] }; lang: LangEnum }>) => {
            console.log("onResponseFail", action);
            console.log("onResponseFail", typeof action);
            const response =
                typeof action.payload.result === "string" ? JSON.parse(action.payload.result) : action.payload.result;
            const lang = action.payload.lang;
            (state.condition as Condition)[lang] = {
                inProgress: false,
                response: {
                    result: false,
                    errors: action.payload.result?.errors,
                    message: response ? response.message : "",
                },
                notice: {
                    visibility: true,
                    severity: "error",
                },
            };
        },
        onResponseSuccess: (
            state,
            action: PayloadAction<{
                result: any;
                lang: LangEnum;
            }>,
        ) => {
            const response =
                typeof action.payload.result === "string" ? JSON.parse(action.payload.result) : action.payload.result;
            (state.condition as Condition)[action.payload.lang] = {
                inProgress: false,
                response: {
                    result: action.payload.result,
                    errors: null,
                    message: response ? response.message : "",
                },
                notice: {
                    visibility: true,
                    severity: "success",
                },
            };
            state.view.id = action.payload.result.data.id;
        },
        onChange: (state, action) => {
            let ref;
            const nameRoute = action.payload.name.split(".");
            const fieldName = nameRoute.pop();
            ref = nameRoute.reduce((acc: any, item: string) => {
                // @ts-ignore
                return acc[item];
            }, state.view);
            if (Object.keys(ref).indexOf(fieldName) === -1) ref = {};
            if (action.payload.hasOwnProperty("checked")) {
                ref[fieldName] = action.payload.checked;
            } else {
                ref[fieldName] = action.payload.value;
            }
        },
        onSetInitialExpo: (state, action: PayloadAction<Expo>) => {
            state.view = action.payload;
        },
        onResetExpoFormView: state => {
            state.view = initialExpo;
        },
        onSetNewExpoModalIsOpened: (state, action: PayloadAction<boolean>) => {
            state.modalIsOpened = action.payload;
        },
        onSetExpoFormMode: (state, action: PayloadAction<"create" | "edit">) => {
            state.mode = action.payload;
        },
        onFinish: (state, action: PayloadAction<Expo>) => {
            state.modalIsOpened = false;
            state.view = initialExpo;
        },
    },
});

export const {
    onNoticeHide,
    onConditionClear,
    onRequest,
    onResponseSuccess,
    onResponseFail,
    onChange,
    onSetInitialExpo,
    onSetNewExpoModalIsOpened,
    onSetExpoFormMode,
    onFinish,
    onResetExpoFormView,
} = slicerNewExpo.actions;

export const asyncExpoSave = (expo: Expo, lang: LangEnum, callback?: (result: any) => void) => async (
    dispatch: any,
    getState: () => RootState,
) => {
    dispatch(onRequest({ lang: lang || LangEnum.RU }));
    let result = await apiExpoSave(expo, lang).catch(e => console.error(e));
    if (result) {
        if (result.result) {
            dispatch(onResponseSuccess({ result: result, lang: lang || LangEnum.RU }));
        } else {
            dispatch(onResponseFail({ result: result, lang: lang || LangEnum.RU }));
        }
    } else {
        dispatch(onResponseFail({ result: result, lang: lang || LangEnum.RU }));
    }
    if (callback) {
        callback(result);
    }
};

export const reselectExpoNew = (state: RootState) => {
    return state.adminExpo.new.view;
};
export const reselectExpoNewCondition = (state: RootState) => {
    return state.adminExpo.new.condition;
};
export const reselectExpoNewModalIsOpened = (state: RootState) => {
    return state.adminExpo.new.modalIsOpened;
};
export const reselectExpoNewFormMode = (state: RootState) => {
    return state.adminExpo.new.mode;
};

export default slicerNewExpo.reducer;
